<template>
  <div class="animate__animated animate__fadeIn">
    <div
      class="d-flex align-items-center justify-content-between mt-8 mb-10"
      style="height: 0px"
    >
      <div>
        <h3 class="d-block">{{ detail.title }}</h3>
        <span class="text-muted">{{ detail.created_by_name + `(${detail.created_at_display})` }}</span>
      </div>
      <div>
        <!-- <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content
            ><b-icon-three-dots-vertical></b-icon-three-dots-vertical>
          </template>
          <b-dropdown-item>Edit</b-dropdown-item>
          <b-dropdown-item>Hapus</b-dropdown-item>
        </b-dropdown> -->
      </div>
    </div>
    <v-divider></v-divider>
    <div v-html="detail.description_material"></div>
    <v-divider v-if="detail.description_material"></v-divider>
    <h6>Lampiran</h6>
    <!-- <div class="row">
      <div
        v-for="(image, index) in image_list"
        :key="image"
        class="col-md-3 image-input-wrapper"
      >
        <a :href="image_list[index]" download="file" target="_blank">
          <img :src="image_list[index]" />
        </a>
      </div>
    </div> -->
    <div class="row">
      <div v-for="(image, index) in image_list" :key="image" class="col-md-4">
        <a :href="image_list[index]" download="file" target="_blank">
        <b-card no-body class="overflow-hidden card-attach-image" style="max-width: 540px">
          <b-row no-gutters :class="'align-items-center'">
            <b-col md="5">
              <b-card-img
                :src="image_list[index]"
                alt="Image"
                class="rounded-0"
              ></b-card-img>
            </b-col>
            <b-col md="7">
              <h5 class="ml-2 text-dark">
                {{ image_name[index] }}
              </h5>
              <span class="ml-2 text-muted">{{ image_extension[index] }}</span>
            </b-col>
          </b-row>
        </b-card>
        </a>
      </div>
    </div>
    <div class="row">
      <div v-for="(attachment, index) in attachment_list_display" :key="attachment" class="col-md-4">
        <a 
          download="file" 
          target="_blank"
          :href="attachment_list_display[index]"
          v-if="attachment_extension[index] !== 'pdf'"
        >
        <b-card no-body 
          class="overflow-hidden card-attach-image" 
          style="max-width: 540px"
        >
          <b-row no-gutters :class="'align-items-center'">
            <b-col md="2">
              <i class="fas fa-file-pdf fa-5x" v-if="attachment_extension[index] === 'pdf'"></i>
              <i class="fas fa-file-word fa-5x" v-if="attachment_extension[index] === 'docx'"></i>
            </b-col>
            <b-col md="10">
              <h5 class="ml-3 text-dark">
                {{ file_name[index] }}
                <!-- {{ attachment_list_display[index] }} -->
              </h5>
              <span class="ml-3 text-muted">{{ attachment_extension[index] }}</span>
            </b-col>
          </b-row>
        </b-card>
        </a>
        <a 
          href="#"
          v-if="attachment_extension[index] === 'pdf'"
        >
        <b-card no-body 
          class="overflow-hidden card-attach-image" 
          style="max-width: 540px"
          @click="attachment_extension[index] === 'pdf' ? viewPdf(attachment_list_display[index]) : ''"
        >
          <b-row no-gutters :class="'align-items-center'">
            <b-col md="2">
              <i class="fas fa-file-pdf fa-5x" v-if="attachment_extension[index] === 'pdf'"></i>
              <i class="fas fa-file-word fa-5x" v-if="attachment_extension[index] === 'docx'"></i>
            </b-col>
            <b-col md="10">
              <h5 class="ml-3 text-dark">
                {{ file_name[index] }}
                <!-- {{ attachment_list_display[index] }} -->
              </h5>
              <span class="ml-3 text-muted">{{ attachment_extension[index] }}</span>
            </b-col>
          </b-row>
        </b-card>
        </a>
      </div>
    </div>
    <b-button
      type="button"
      class="ml-2 mt-5"
      variant="primary"
      @click="handleContent"
    >
      Tutup
    </b-button>

    <!-- pdf preview -->
    <PdfPreview :pdfFile="pdfFile" @closePreview="closePreview" />
  </div>
</template>

<script>
import PdfPreview from "@/view/components/general/PdfPreview.vue"
export default {
  props: {
    detail: Object,
  },
  components: {
    PdfPreview
  },
  data() {
    return {
      attachment_list: [],
      attachment_list_display: [],
      attachment_extension: [],
      file_name: [],
      image_list: [],
      image_name: [],
      image_extension: [],
      // other
      pdfFile: null
    };
  },
  methods: {
    viewPdf(pdfFile){
      this.pdfFile = pdfFile
    },
    closePreview(){
      this.pdfFile = null
    },
    handleContent(){
      this.$emit("handleContent", "list")
      this.attachment_list = []
      this.attachment_list_display = []
      this.file_name = []
      this.image_list = []
      this.image_name = []
    },
    setAttachmentList() {
      // set date
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      let localdata = new Date(this.detail.created_at).toLocaleDateString("id-ID", options)
      this.detail.created_at_display = localdata.split(",")[1]

      this.attachment_list = this.detail.attachment_list.split(",");
      //("attach", this.attachment_list);
      for(let c = 0; c < this.attachment_list.length; c++){
        if(this.attachment_list[c] == ""){
          this.attachment_list.splice(c, 1)
        }
      }

      for (let a = 0; a < this.attachment_list.length; a++) {
        let splitExtension = this.attachment_list[a].split(".");
        let extension = splitExtension[splitExtension.length - 1];
        if (extension === "jpg" || extension == "png" || extension == "jpeg") {
          this.image_extension.push(splitExtension[splitExtension.length - 1]);
          //("image extens", this.image_extension);
          this.image_list.push(this.attachment_list[a]);
          //("image display", this.image_list);
          let image_name = this.attachment_list[a].split("____")[1];
          this.image_name.push(image_name.substring(0, 10) + "...");
          //("image_name", this.image_name);
        } else {
          this.attachment_extension.push(splitExtension[splitExtension.length - 1])
          //("attach extens", this.attachment_extension);
          this.attachment_list_display.push(this.attachment_list[a]);
          //("attach display", this.attachment_list_display);
          let file_name = this.attachment_list[a].split("____")[1]
          this.file_name.push(file_name.substring(0, 18) + "...");
          //("file_name", this.file_name);
        }
      }

      // let a;
      // for (a = 0; a < this.attachment_list.length; a++) {
      //   if (this.attachment_list[a]) {
      //     //("true");
      //     this.attachment_list_display.push(this.attachment_list[a]);
      //     //("attach display", this.attachment_list_display);
      //     this.file_name.push(this.attachment_list[a].split("____")[1]);
      //     //("fn", this.file_name);
      //   }
      // }
    },
  },
  mounted() {
    this.setAttachmentList();
  },
  watch: {
    detail: function (newVal, oldVal) {
      // watch it
      //("detail classwork changed: ", newVal, " | was: ", oldVal);
      this.setAttachmentList();
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 60px !important;
  height: 100px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper:hover {
  opacity: 0.8;
}

.card-attach-image:hover {
  opacity: 0.8;
  color: #1bc5bd !important;
}

.text-dark:hover{
  color: #1BC5BD !important;
}
</style>