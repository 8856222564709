<template>
  <div>
    <div class="container pt-0 px-10">
      <div class="row animate__animated animate__fadeIn">
        <div class="col-12">
          <template v-if="content == 'list'">
            <!-- Head -->
            <div class="row animate__animated animate__fadeIn">
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <div class="col-md-6" v-if="user_data.role_id == 1">
                    <b-dropdown
                      id="dropdown-1"
                      text="Buat Kegiatan"
                      variant="primary"
                      class="m-md-2 mt-1"
                      no-caret
                      v-if="kbmBtn"
                    >
                      <template #button-content>
                        <b-icon-plus></b-icon-plus> Kegiatan
                      </template>
                      <b-dropdown-item
                        v-for="classwork_type in classwork_types"
                        :key="classwork_type.id"
                        @click="handleContentClasswork(classwork_type.id)"
                        >{{ classwork_type.name }}</b-dropdown-item
                      >
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="handleSectionAdd"
                        >Seksi</b-dropdown-item
                      >
                    </b-dropdown>
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="showModalClassroomSchedule"
                      v-if="scheduleBtn"
                    >
                      Atur Jadwal Mingguan
                    </b-button>
                  </div>
                  <div class="col-md-6" v-if="user_data.role_id == 2">
                    <template v-for="schedules in classroomSchedule">
                      <span class="d-block mb-3" :key="schedules.id">
                        <i class="fas fa-clock text-warning"></i>
                        {{schedules.day_name}} {{schedules.start_time}} - {{schedules.end_time}} 
                        {{schedules.schedule_type ? 'Praktikum' : 'Reguler'}}
                      </span>
                    </template>
                  </div>
                  <div class="col-md-6">
                    <table class="table table-borderless">
                      <!-- <tr v-for="(teacher, index) in teachers" :key="teacher.id"> -->
                      <tr v-for="(teacher, index) in teachers" :key="index">
                        <td width="30%">Guru {{index + 1}}</td>
                        <td>{{ teacher }}</td>
                      </tr>
                      <tr>
                        <td>Jumlah Siswa</td>
                        <template v-if="totalStudents">
                          <td>{{ totalStudents }}</td>
                        </template>
                        <template v-else>
                          <td>-</td>
                        </template>
                      </tr>
                    </table>
                    <template v-if="user_data.role_id == 1">
                    <template v-for="schedules in classroomSchedule">
                      <span class="d-block mb-3" :key="schedules.id">
                        <i class="fas fa-clock text-warning"></i>
                        {{schedules.day_name}} {{schedules.start_time}} - {{schedules.end_time}} 
                        {{schedules.schedule_type ? 'Praktikum' : 'Reguler'}}
                      </span>
                    </template>
                    </template>
                  </div>
                </div>
              </div>
              <!-- List Classwork -->
              <div class="col-12 pt-0">
                <template v-for="classwork in classworksData">
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-between
                      mt-8
                    "
                    style="height: 0px"
                    :key="
                      classwork.section_id
                        ? classwork.section_id
                        : classwork.classwork_id
                    "
                  >
                    <h4>{{ classwork.section_name }}</h4>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      dropleft
                      v-if="kbmBtn"
                    >
                      <template #button-content>
                        <!-- <i class="fas fa-plus"></i> -->
                        <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                      </template>
                      <b-dropdown-item
                        @click="handleEditSection(classwork.section_id)"
                        >Edit</b-dropdown-item
                      >
                      <b-dropdown-item> Hapus </b-dropdown-item>
                      <b-dropdown-item
                        v-for="classwork_type in classwork_types"
                        :key="classwork_type.id"
                        @click="
                          handleAddClassworkBySection(
                            classwork.section_id,
                            classwork_type.id
                          )
                        "
                        >{{ classwork_type.name }}</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>

                  <v-divider
                    :key="
                      classwork.section_id
                        ? classwork.number
                        : classwork.classwork_id
                    "
                  ></v-divider>

                  <template v-for="child in classwork.children">
                    <v-hover v-slot="{ hover }">
                      <div
                        class="d-flex bd-highlight pl-5 pr-6"
                        :class="hover ? 'bg-light-kbm' : ''"
                        style="height: 60px"
                        :key="child.classwork_id"
                      >
                        <div class="p-2 w-100 bd-highlight classwork-title">
                          <template v-if="child.classwork_type_id == 1">
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35 bg-light mr-3">
                                <div class="symbol-label bg-light-success">
                                  <span class="svg-icon svg-icon-md svg-icon-info">
                                    <!--begin::Svg Icon-->
                                    <!-- <i class="fas fa-clipboard-check fa-lg text-info"></i> -->
                                    <!-- <inline-svg src="/media/svg/icons/Home/Book.svg" /> -->
                                    <!-- <inline-svg src="/media/svg/icons/Files/File-done.svg" /> -->
                                    <b-icon icon="file-earmark-fill" variant="success"></b-icon>
                                    <!--end::Svg Icon-->
                                  </span>
                                </div>
                              </div>
                            <div class="navi-text">
                            <div
                              class="font-weight-bold"
                              @click="
                                handleClassworkDetail(
                                  child.classwork_id,
                                  child.classwork_type_id
                                )
                              "
                              >{{child.children[0].title }}</div>
                              <div class="">
                                {{setDateSchedule(child.assignment_classwork[0].start_date)}} s/d 
                                {{setDateSchedule(child.assignment_classwork[0].due_date)}}
                              </div>
                            </div>
                            </div>
                          </template>
                          <template v-if="child.classwork_type_id == 2">
                            <div class="d-flex align-items-center">
                            <div>
                              <div class="symbol symbol-35 bg-light mr-3">
                                <div class="symbol-label bg-light-primary">
                                  <span class="svg-icon svg-icon-md svg-icon-primary">
                                    <!--begin::Svg Icon-->
                                    <!-- <inline-svg src="/media/svg/icons/Home/Book-open.svg" /> -->
                                    <!-- <inline-svg src="/media/svg/icons/Files/File.svg" /> -->
                                    <b-icon icon="file-earmark-text-fill" variant="primary"></b-icon>
                                    <!--end::Svg Icon-->
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="">
                            <div
                              class="font-weight-bold"
                              @click="
                                handleClassworkDetail(
                                  child.classwork_id,
                                  child.classwork_type_id,
                                  child.children[0].id
                                )
                              "
                              >{{child.children[0].title }}</div>
                              <div class="">{{setDateSchedule(child.classwork_quiz[0].start_date)}} s/d {{setDateSchedule(child.classwork_quiz[0].due_date)}}</div>
                            </div>
                            </div>
                          </template>
                          <template v-if="child.classwork_type_id == 3">
                            <div class="d-flex align-items-center">
                            <div>
                              <div class="symbol symbol-35 bg-light mr-3">
                                <div class="symbol-label bg-light-warning">
                                  <span class="svg-icon svg-icon-md svg-icon-warning">
                                    <!--begin::Svg Icon-->
                                    <!-- <i class="fas fa-clipboard-check fa-lg text-warning"></i> -->
                                    <!-- <inline-svg src="/media/svg/icons/Home/Book.svg" /> -->
                                    <!-- <inline-svg src="/media/svg/icons/Home/Book-open.svg" /> -->
                                    <b-icon icon="book-fill" variant="warning"></b-icon>
                                    <!--end::Svg Icon-->
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="navi-text">
                            <div
                              class="font-weight-bold"
                              @click="
                                handleClassworkDetail(
                                  child.classwork_id,
                                  child.classwork_type_id
                                )
                              "
                              >{{child.children[0].title }}</div>
                              <div class="">{{setDateSchedule(child.classwork_created_at)}}</div>
                            </div>
                            </div>
                          </template>
                          <template v-if="child.classwork_type_id == 4">
                            <div class="d-flex align-items-center">
                            <div>
                              <div class="symbol symbol-35 bg-light mr-3">
                              <div class="symbol-label bg-light-danger">
                                <span class="svg-icon svg-icon-md svg-icon-danger">
                                  <!--begin::Svg Icon-->
                                  <!-- <inline-svg src="/media/svg/icons/General/Bookmark.svg" /> -->
                                  <b-icon icon="book-half" variant="danger"></b-icon>
                                  <!--end::Svg Icon-->
                                </span>
                              </div>
                              </div>
                            </div>
                            <div class="navi-text">
                            <div
                              class="font-weight-bold"
                              @click="
                                handleClassworkDetail(
                                  child.classwork_id,
                                  child.classwork_type_id
                                )
                              "
                              >{{child.children[0].title }}</div>
                              <div class="">{{setDateSchedule(child.classwork_created_at)}}</div>
                            </div>
                            </div>
                          </template>
                          <template v-if="child.classwork_type_id == 5">
                            <div class="d-flex align-items-center">
                            <div>
                              <div class="symbol symbol-35 bg-light mr-3">
                                <div class="symbol-label bg-light-info">
                                  <span class="svg-icon svg-icon-md svg-icon-info">
                                    <!--begin::Svg Icon-->
                                    <!-- <inline-svg src="/media/svg/icons/Home/Book-open.svg" /> -->
                                    <!-- <inline-svg src="/media/svg/icons/Devices/Camera.svg" /> -->
                                    <!-- <b-icon icon="camera-fill" variant="info"></b-icon> -->
                                    <i class="fas fa-video text-info"></i>
                                    <!--end::Svg Icon-->
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="navi-text">
                            <div
                              class="font-weight-bold"
                              @click="
                                handleClassworkDetail(
                                  child.classwork_id,
                                  child.classwork_type_id
                                )
                              "
                              >{{child.children[0].agenda}}</div>
                              <div class="">{{setDateSchedule(child.children[0].start_date)}}</div>
                            </div>
                            </div>
                          </template>
                        </div>
                        <div
                          class="p-2 flex-shrink-1 bd-highlight"
                          v-if="hover"
                        >
                          <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            dropleft
                            v-if="kbmBtn"
                          >
                            <template #button-content
                              ><b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                            </template>
                            <b-dropdown-item
                              v-if="child.classwork_type_id == 3 || child.classwork_type_id == 4"
                              @click="
                                handleClassworkEdit(
                                  child.classwork_id,
                                  child.classwork_type_id
                                )
                              "
                              >Edit</b-dropdown-item
                            >
                            <b-dropdown-item
                              v-if="child.classwork_type_id == 1"
                              @click="
                                handleClassworkEdit(
                                  child.classwork_id,
                                  child.classwork_type_id
                                )
                              "
                              :disabled="today >= convertDate(child.assignment_classwork[0].start_date) ? true : false"
                              >Edit</b-dropdown-item
                            >
                            <b-dropdown-item
                              v-if="child.classwork_type_id == 2"
                              :disabled="today >= convertDate(child.classwork_quiz[0].start_date) ? true : false"
                              @click="
                                handleClassworkEdit(
                                  child.classwork_id,
                                  child.classwork_type_id
                                )
                              "
                              >Edit</b-dropdown-item
                            >
                            <b-dropdown-item
                              v-if="child.classwork_type_id == 5"
                              :disabled="today >= convertDate(child.children[0].start_date) ? true : false"
                              @click="
                                handleClassworkEdit(
                                  child.classwork_id,
                                  child.classwork_type_id
                                )
                              "
                              >Edit</b-dropdown-item
                            >
                            <b-dropdown-item
                            v-if="child.classwork_type_id == 1"
                            @click="handleClassworkDelete(child.assignment_classwork[0].id, child.classwork_id, child.classwork_type_id, child.assignment_classwork[0].assignment_id)"
                            :disabled="today >= convertDate(child.assignment_classwork[0].start_date) ? true : false"
                            > Hapus </b-dropdown-item>
                            <b-dropdown-item
                            v-if="child.classwork_type_id == 2"
                            @click="handleClassworkDelete(child.classwork_quiz[0].id, child.classwork_id, child.classwork_type_id)"
                            :disabled="today >= convertDate(child.classwork_quiz[0].start_date) ? true : false"
                            > Hapus</b-dropdown-item>
                            <b-dropdown-item
                            v-if="child.classwork_type_id == 3 || child.classwork_type_id == 4"
                            @click="handleClassworkDelete(child.children[0].id, child.classwork_id, child.classwork_type_id)"
                            > Hapus </b-dropdown-item>
                            <b-dropdown-item
                            v-if="child.classwork_type_id == 5"
                            :disabled="today >= convertDate(child.children[0].start_date) ? true : false"
                            @click="handleClassworkDelete(child.children[0].id, child.classwork_id, child.classwork_type_id)"
                            > Hapus </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </v-hover>
                  </template>
                </template>
              </div>
            </div>
          </template>

          <!-- Tambah Classwork Material -->
          <template v-if="content == 'material-add'">
            <MaterialAdd
              :postClasswork="postClasswork"
              :postMaterial="postMaterial"
              :classroom_id="classroom_id"
              @handleContent="handleContent"
            />
          </template>

          <!-- Tambah Classwork Lesson -->
          <template v-if="content == 'lesson-add'">
            <LessonAdd
              @handleContent="handleContent"
              :classroom_id="classroom_id"
              :postClasswork="postClasswork"
              :postLesson="postLesson"
            />
          </template>

          <!-- Tambah Classwork Online Class Schedule -->
          <template v-if="content == 'schedule-add'">
            <ScheduleAdd
              @handleContent="handleContent"
              :classroom_id="classroom_id"
              :postClasswork="postClasswork"
              :postSchedule="postSchedule"
            />
          </template>

          <template v-if="content == 'assignment-add'">
            <ClassworkAssignmentAdd 
              @handleContent="handleContent"
              :classroom_id="classroom_id"
              :postClasswork="postClasswork"
              :postClassworkAssignment="postClassworkAssignment"
            />
          </template>

          <!-- Tambah Classwork Quiz -->
          <template v-if="content == 'quiz-add'">
            <ClassworkQuizAdd
              @handleContent="handleContent"
              :classroom_id="classroom_id"
              :postClasswork="postClasswork"
              :postQuiz="postQuiz"
            />
          </template>

          <!-- Edit Classwork Material || Lesson -->
          <template v-if="content == 'classwork-material-lesson-edit'">
            <ClassworkEdit
              @handleContent="handleContent"
              :classroom_id="classroom_id"
              :form="updateClasswork"
              :classwork_to="classwork_to"
            />
          </template>

          <!-- Edit Classwork Material -->
          <template v-if="content == 'classwork-material-edit'">
            <ClassworkMaterialUpdate
              :postClasswork="postClasswork"
              :postMaterial="updateClasswork"
              :classroom_id="classroom_id"
              :classwork_to="classwork_to"
              @handleContent="handleContent"
            />
          </template>

          <!-- Edit Classwork Material -->
          <template v-if="content == 'classwork-lesson-edit'">
            <ClassworkLessonUpdate
              :postClasswork="postClasswork"
              :postLesson="updateClasswork"
              :classroom_id="classroom_id"
              :classwork_to="classwork_to"
              @handleContent="handleContent"
            />
          </template>

        <!-- Edit Classwork Quiz -->
          <template v-if="content == 'classwork-quiz-edit'">
            <ClassworkQuizEdit 
              @handleContent="handleContent"
              :classroom_id="classroom_id"
              :postQuiz="updateClasswork"
              :classwork_to="classwork_to"
              :postClasswork="postClasswork"
            />
          </template>

          <!-- Edit Classwork Schedule -->
          <template v-if="content == 'classwork-schedule-edit'">
            <ClassworkScheduleEdit 
              @handleContent="handleContent"
              :classroom_id="classroom_id"
              :postSchedule="updateClasswork"
              :classwork_to="classwork_to"
              :postClasswork="postClasswork"
            />
          </template>

          <!-- Edit Classwork Assignment -->
          <template v-if="content == 'classwork-assignment-edit'">
            <ClassworkAssignmentEdit 
              @handleContent="handleContent"
              :classroom_id="classroom_id"
              :postClassworkAssignment="updateClasswork"
              :classwork_to="classwork_to"
              :postClasswork="postClasswork"
            />
          </template>

          <!-- Detail Classwork Assignment -->
          <template v-if="content == 'classwork-assignment-detail'">
            <ClassworkAssignmentDetail
              @handleContent="handleContent"
              :detail="detailClasswork"
              :classroom_id="classroom_id"
            />
          </template>

          <!-- Detail Classwork Quiz -->
          <template v-if="content == 'detail-classwork-quiz'">
            <ClassworkQuizDetail
              @handleContent="handleContent"
              :detail="detailClasswork"
              :classroom_id="classroom_id"
              :available_answer="available_answer"
              :isFinishClassworkQuiz="is_finish_classwork_quiz"
              :userData="user_data"
            />
          </template>
          
          <!-- Detail Classwork Material -->
          <template v-if="content == 'classwork-material-detail'">
            <ClassworkMaterialDetail
              @handleContent="handleContent"
              :detail="detailClasswork"
            />
          </template>
          <!-- Detail Classwork Lesson -->
          <template v-if="content == 'classwork-lesson-detail'">
            <ClassworkLessonDetail
              @handleContent="handleContent"
              :detail="detailClasswork"
            />
          </template>
          <!-- Detail Classwork Schedule -->
          <template v-if="content == 'classwork-schedule-detail'">
            <ClassworkScheduleDetail
              @handleContent="handleContent"
              :detail="detailClasswork"
              :classroomSchedule="classroomSchedule"
            />
          </template>
        </div>
      </div>
    </div>
    <ModalSection
      :form="postSection"
      :route="routeSection"
      :classroom_id="classroom_id"
      @sectionAdd="sectionAdd"
    />
    <ModalClassroomSchedule 
      @cancelClassroomSchedule="cancelClassroomSchedule"
      :classroomId="classroom_id"
      :classroomSchedule="classroomSchedule"
    />
  </div>
</template>

<script>
import { getUser, setUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalClassroomSchedule from "@/view/components/general/ModalClassroomSchedule.vue";
import ModalSection from "@/view/components/general/ModalSection.vue";
import LessonAdd from "@/view/components/classroom/kbm/LessonAdd.vue";
import MaterialAdd from "@/view/components/classroom/kbm/MaterialAdd.vue";
import ScheduleAdd from "@/view/components/classroom/kbm/ScheduleAdd.vue";
import ClassworkAssignmentAdd from "@/view/components/classroom/kbm/ClassworkAssignmentAdd.vue";
import ClassworkQuizAdd from "@/view/components/classroom/kbm/ClassworkQuizAdd.vue";
import ClassworkQuizDetail from "@/view/components/classroom/kbm/ClassworkQuizDetail.vue";
import ClassworkMaterialDetail from "@/view/components/classroom/kbm/ClassworkMaterialDetail.vue";
import ClassworkLessonDetail from "@/view/components/classroom/kbm/ClassworkLessonDetail.vue";
import ClassworkScheduleDetail from "@/view/components/classroom/kbm/ClassworkScheduleDetail.vue";
import ClassworkAssignmentDetail from "@/view/components/classroom/kbm/ClassworkAssignmentDetail.vue";
import ClassworkEdit from "@/view/components/classroom/kbm/ClassworkEdit.vue";
import ClassworkScheduleEdit from "@/view/components/classroom/kbm/ClassworkScheduleEdit.vue";
import ClassworkQuizEdit from "@/view/components/classroom/kbm/ClassworkQuizEdit.vue";
import ClassworkAssignmentEdit from "@/view/components/classroom/kbm/ClassworkAssignmentEdit.vue";
import ClassworkMaterialUpdate from "@/view/components/classroom/kbm/ClassworkMaterialUpdate.vue";
import ClassworkLessonUpdate from "@/view/components/classroom/kbm/ClassworkLessonUpdate.vue";


export default {
  props: {
    totalStudents: Number,
    teachers: Array,
    classroom_name: String,
    classroom_id: String,
    classworks: Array,
    classworksData: Array,
    classroomSchedule: Array,
  },
  components: {
    ModalClassroomSchedule,
    ModalSection,
    ClassworkQuizAdd,
    MaterialAdd,
    LessonAdd,
    ClassworkQuizDetail,
    ClassworkMaterialDetail,
    ClassworkLessonDetail,
    ClassworkScheduleDetail,
    ClassworkEdit,
    ClassworkScheduleEdit,
    ClassworkQuizEdit,
    ScheduleAdd,
    ClassworkAssignmentAdd,
    ClassworkAssignmentDetail,
    ClassworkAssignmentEdit,
    ClassworkMaterialUpdate,
    ClassworkLessonUpdate,
  },
  data() {
    return {
      content: "list",
      classwork_types: [],
      // section
      postSection: {
        name: "",
        description: "",
        classroom_id: "",
      },
      updateSection: [],
      routeSection: "",
      // classwork
      postClasswork: {
        classwork_type_id: "",
        classwork_to: "",
        classroom_id: "",
        section_id: "",
      },
      updateClasswork: [],
      detailClasswork: [],
      classwork_to: [],
      // material
      postMaterial: {
        classwork_id: "",
        material_id: "",
      },
      // lesson
      postLesson: {
        classwork_id: "",
        lesson_id: "",
      },
      // schedule
      postSchedule: {
        classwork_id: null,
        agenda: "",
        start_date: new Date(),
        end_date: new Date(),
        meeting_platform_id: "",
        meeting_url: "",
        user_name: "",
        password: "",
        topic_id: '',
        section_id: null,
      },
      // quiz
      postQuiz: {
        classwork_id: "",
        point: "",
        topic_id: '',
        start_date: "",
        due_date: "",
      },
      is_finish_classwork_quiz: "",
      // assignment
      postAssignment: {
        title: "",
        instruction: "",
        attachment_list: "",
        topic_id: '',
      },
      postClassworkAssignment: {
        classwork_id: "",
        assignment_id: "",
        author_id: "",
        start_date: "",
        due_date: "",
        point: "",
        title: "",
        instruction: "",
        attachment_list: "",
        topic_id: '',
      },
      // classroom-schedules
      classroom_schedules: [],
      // other
      available_answer: null,
      classwork_quiz_scores: [],
      today: new Date(),
      user_data: [],
      // access
      kbmBtn: false,
      scheduleBtn: false
    };
  },
  methods: {
    showModalClassroomSchedule(){
      this.$bvModal.show('modal-classroom-schedules')
    },
    cancelClassroomSchedule(){
      this.$bvModal.hide('modal-classroom-schedules')
    },
    handleContent(content) {
      this.content = content;
      this.resetPostClassworkOnNew()
      this.resetPostClassworkAssignmentOnNew()
      this.resetPostClassworkScheduleOnNew()
      this.resetPostClassworkQuizOnNew()
      this.resetUpdateClassworkOnNew()
    },
    handleSectionAdd() {
      this.$bvModal.show("modal-section");
      this.routeSection = `api/classrooms/${this.classroom_id}/sections`;
      // //("route sections", this.routeSection);
    },
    async handleEditSection(section_id) {
      let result = await module.get(`api/sections/${section_id}`);

      if (result) {
        this.postSection = result;
        this.postSection["_method"] = "put";
        this.routeSection = `api/classrooms/${this.classroom_id}/sections/${this.postSection.id}`;
        // //("route sections", this.routeSection);
        this.$bvModal.show("modal-section");
      }
    },
    async classworkTypes() {
      let response = await module.paginate("api/classwork-types");

      this.classwork_types = response.data;
      // //("class types", this.classwork_types);
    },
    handleContentClasswork(id) {
      this.postClasswork.classwork_type_id = id;
      if (id == 1) {
        this.content = "assignment-add";
      } else if (id == 2) {
        this.content = "quiz-add";
      } else if (id == 3) {
        this.content = "lesson-add";
      } else if (id == 4) {
        this.content = "material-add";
      } else if (id == 5) {
        this.content = "schedule-add";
      }
    },
    handleAddClassworkBySection(section_id, classwork_type_id) {
      // //("section_id", section_id);
      // //("classwork_type_id", classwork_type_id);
      this.postClasswork.section_id = section_id;
      // //("postClasswork", this.postClasswork);
      this.handleContentClasswork(classwork_type_id);
    },
    async handleClassworkEdit(classwork_id, classwork_type_id) {
      let result = await module.get(
        `api/classworks/${classwork_id}/${classwork_type_id}`
      );

      // //(
      //   "classwork by id============================================================",
      //   result[0]
      // );

      if (result) {
        this.updateClasswork = result[0];
        this.updateClasswork.classwork_to = this.updateClasswork.classwork_to_display;
        this.classwork_to = this.updateClasswork.classwork_to_display;
        // //("class to ===", this.classwork_to);
        this.updateClasswork["_method"] = "put";
        if(this.updateClasswork.classwork_type_id == 1){
          this.postClasswork["_method"] = "put";
          this.postClasswork.classroom_id = this.updateClasswork.classroom_id
          this.postClasswork.classwork_type_id = this.updateClasswork.classwork_type_id
          this.postClasswork.section_id = this.updateClasswork.section_id
          this.postClasswork.classwork_to = this.updateClasswork.classwork_to_display
          this.postClasswork.classwork_id = this.updateClasswork.classwork_id
          this.content = "classwork-assignment-edit"
        }
        else if(this.updateClasswork.classwork_type_id == 2){
          this.postClasswork["_method"] = "put";
          this.postClasswork.classroom_id = this.updateClasswork.classroom_id
          this.postClasswork.classwork_type_id = this.updateClasswork.classwork_type_id
          this.postClasswork.section_id = this.updateClasswork.section_id
          this.postClasswork.classwork_to = this.updateClasswork.classwork_to_display
          this.postClasswork.classwork_id = this.updateClasswork.classwork_id
          this.content = "classwork-quiz-edit"
        } else if(this.updateClasswork.classwork_type_id == 3){
          this.content = "classwork-lesson-edit";
          this.postClasswork["_method"] = "put";
          this.postClasswork.classroom_id = this.updateClasswork.classroom_id
          this.postClasswork.classwork_type_id = this.updateClasswork.classwork_type_id
          this.postClasswork.section_id = this.updateClasswork.section_id
          this.postClasswork.classwork_to = this.updateClasswork.classwork_to_display
          this.postClasswork.classwork_id = this.updateClasswork.classwork_id
        } else if(this.updateClasswork.classwork_type_id == 4){
          this.postClasswork["_method"] = "put";
          this.postClasswork.classroom_id = this.updateClasswork.classroom_id
          this.postClasswork.classwork_type_id = this.updateClasswork.classwork_type_id
          this.postClasswork.section_id = this.updateClasswork.section_id
          this.postClasswork.classwork_to = this.updateClasswork.classwork_to_display
          this.postClasswork.classwork_id = this.updateClasswork.classwork_id
          this.content = "classwork-material-edit";
        } else if(this.updateClasswork.classwork_type_id == 5){
          this.postClasswork["_method"] = "put";
          this.postClasswork.classroom_id = this.updateClasswork.classroom_id
          this.postClasswork.classwork_type_id = this.updateClasswork.classwork_type_id
          this.postClasswork.section_id = this.updateClasswork.section_id
          this.postClasswork.classwork_to = this.updateClasswork.classwork_to_display
          this.postClasswork.classwork_id = this.updateClasswork.classwork_id
          this.content ="classwork-schedule-edit"
        }
      }
    },
    async handleClassworkDetail(classwork_id, classwork_type_id, quiz_id) {
      // //("content", this.content);
      // classworks/{classworkId}/{classworkTypeId}
      this.detailClasswork = await module.get(
        `api/classworks/${classwork_id}/${classwork_type_id}`
      );
      // If Data Not Found
      if (this.detailClasswork == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.detailClasswork = this.detailClasswork[0];
        if(this.detailClasswork.classwork_type_id == 1){
          // classwork assignment
          this.content = "classwork-assignment-detail";
          // //("detailClasswork", this.detailClasswork);
        } else if (this.detailClasswork.classwork_type_id == 2) {
          //('classwork_quiz', this.detailClasswork)
          // classwork quiz
          let today = new Date();
          let start_date = new Date(this.detailClasswork.start_date);
          let due_date = new Date(this.detailClasswork.due_date);

          if (today >= start_date && today <= due_date ) {
            // //("betul============================")

            let user_data = JSON.parse(window.localStorage.getItem("user"));
            this.classwork_quiz_scores = await module.classworkQuizScore(
            `api/classrooms/${this.classroom_id}/classworks/${classwork_id}/classwork-quiz-scores/${quiz_id}/${user_data.id}`
            );
          if(this.classwork_quiz_scores == null || this.classwork_quiz_scores.is_finish == 0){
            this.is_finish_classwork_quiz = 0
            for (let c = 0; c < this.detailClasswork.questions.length; c++) {
              this.detailClasswork.questions[c].number = c + 1;
              if (this.detailClasswork.questions[c].answer_choices != "") {
                this.detailClasswork.questions[c].answer_choices_display =
                  JSON.parse(this.detailClasswork.questions[c].answer_choices);
              }
            }
            
            // //("detailClasswork", this.detailClasswork)
            this.getClassworkQuiz(
              this.detailClasswork.classwork_id,
              this.detailClasswork.quiz_id
            );
          } else if(this.classwork_quiz_scores.is_finish == 1) {
            this.detailClasswork.due_date = this.classwork_quiz_scores.end_quiz
            // //("SUDAH MENGERJAKAN===========", this.is_finish_classwork_quiz)
            this.is_finish_classwork_quiz = this.classwork_quiz_scores.is_finish
            for (let c = 0; c < this.detailClasswork.questions.length; c++) {
              this.detailClasswork.questions[c].number = c + 1;
              if (this.detailClasswork.questions[c].answer_choices != "") {
                this.detailClasswork.questions[c].answer_choices_display =
                  JSON.parse(this.detailClasswork.questions[c].answer_choices);
              }
            }
            this.getClassworkQuiz(
              this.detailClasswork.classwork_id,
              this.detailClasswork.quiz_id
            );
           } else if(this.classwork_quiz_scores.is_finish == 2) {
            this.detailClasswork.due_date = this.classwork_quiz_scores.end_quiz
            // //("SUDAH MENGERJAKAN===========", this.is_finish_classwork_quiz)
            this.is_finish_classwork_quiz = this.classwork_quiz_scores.is_finish
            for (let c = 0; c < this.detailClasswork.questions.length; c++) {
              this.detailClasswork.questions[c].number = c + 1;
              if (this.detailClasswork.questions[c].answer_choices != "") {
                this.detailClasswork.questions[c].answer_choices_display =
                  JSON.parse(this.detailClasswork.questions[c].answer_choices);
              }
            }
            this.getClassworkQuiz(
              this.detailClasswork.classwork_id,
              this.detailClasswork.quiz_id
            );
           }
          } else if(today < start_date && this.user_data.role_id == 2) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Kuis baru bisa dikerjakan pada ${start_date.toLocaleString()}`,
            });
            // //('jalan Kuis baru bisa dikerjakan pada')
          } else if(today > due_date && this.user_data.role_id == 2) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Waktu mengerjakan kuis telah selesai`,
            });
            // //('jalan Waktu mengerjakan kuis telah selesai')
          } else if(this.user_data.role_id == 1){
              // this.content = "detail-classwork-quiz";
              // //('jalan sesungguhnya')
              this.getClassworkQuiz(
              this.detailClasswork.classwork_id,
              this.detailClasswork.quiz_id
            );
          }
        } else if (this.detailClasswork.classwork_type_id == 3) {
          // classwork lesson
          this.content = "classwork-lesson-detail";
          // //("detailClasswork", this.detailClasswork);
        } else if (this.detailClasswork.classwork_type_id == 4) {
          // classwork material
          this.content = "classwork-material-detail";
          // //("detailClasswork", this.detailClasswork);
        } else if (this.detailClasswork.classwork_type_id == 5) {
          // classwork material
          this.content = "classwork-schedule-detail";
          // //("detailClasswork", this.detailClasswork);
        }
      }
    },
    async handleClassworkDelete(id, classwork_id, classwork_type_id, otherId){
      if(classwork_type_id == 1){
        // otherId = assignmentId
        // classrooms/{classroomId}/classworks/{classworkId}/assignment-classworks/{assignmentId}/{assignmentClassworkId}
        this.deleteData(id, `api/classrooms/${this.classroom_id}/classworks/${classwork_id}/assignment-classworks/${id}/${otherId}`, "")
      }
      else if(classwork_type_id == 2){
        this.deleteData(id, `api/classrooms/${this.classroom_id}/classworks/${classwork_id}/classwork-quizzes/${id}`, "")
      }
      else if(classwork_type_id == 3){
        // classrooms/{classroomId}/classworks/{classworkId}/lessons/detach
        // //("classwork_id", classwork_id)
        this.deleteData(classwork_id, `api/classrooms/${this.classroom_id}/classworks/${classwork_id}/lessons/detach/${id}`)
      }
      else if(classwork_type_id == 4){
        // //("classwork_id", classwork_id)
        // classrooms/{classroomId}/classworks/{classworkId}/materials/detach
        this.deleteData(classwork_id, `api/classrooms/${this.classroom_id}/classworks/${classwork_id}/materials/detach/${id}`)
      }
      else if(classwork_type_id == 5){
        // //("classwork_id", classwork_id)
        // classrooms/{classroomId}/classworks/{classworkId}/classwork-schedules/{classworkScheduleId}
        this.deleteData(classwork_id, `api/classrooms/${this.classroom_id}/classworks/${classwork_id}/classwork-schedules/${id}`)
      }
    },
    async deleteData(id, route, request) {
      // Delete Data
      let result = await module.delete(route);
      // If Deleted
      if (result) {
        this.$root.$emit("refreshClasswork");
      }
    },
    async getClassworkQuiz(classworkId, quizId) {
      // classrooms/{classroomId}/classworks/{classworkId}/classwork-quiz-results/{quizId}
      let user_data = JSON.parse(window.localStorage.getItem("user"));
      let response = await module.paginate(
        `api/classrooms/${this.classroom_id}/classworks/${classworkId}/classwork-quiz-results/${quizId}/${user_data.id}`
      );
      this.available_answer = response.data;
      this.content = "detail-classwork-quiz";
    },
    sectionAdd() {
      // //("ok");
      this.$bvModal.hide("modal-section");
      this.resetPostSectionOnNew();
    },
    resetPostSectionOnNew() {
      let self = this;
      Object.keys(this.postSection).forEach(function (key, index) {
        self.postSection[key] = "";
      });
    },
    resetPostClassworkOnNew() {
      let self = this;
      Object.keys(this.postClasswork).forEach(function (key, index) {
        self.postClasswork[key] = "";
      });
    },
    resetPostClassworkAssignmentOnNew() {
      let self = this;
      Object.keys(this.postClassworkAssignment).forEach(function (key, index) {
        self.postClassworkAssignment[key] = "";
      });
    },
    resetPostClassworkScheduleOnNew() {
      let self = this;
      Object.keys(this.postSchedule).forEach(function (key, index) {
        self.postSchedule[key] = "";
      });
    },
    resetPostClassworkQuizOnNew() {
      let self = this;
      Object.keys(this.postQuiz).forEach(function (key, index) {
        self.postQuiz[key] = "";
      });
    },
    resetUpdateClassworkOnNew() {
      let self = this;
      Object.keys(this.updateClasswork).forEach(function (key, index) {
        self.updateClasswork[key] = "";
      });
    },
    setDate(time){
      time = new Date(time).toLocaleString()
      return time
    },
    setDateSchedule(time){
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      let localdata = new Date(time).toLocaleDateString("id-ID", options)
      let localtime = new Date(time).toTimeString().substr(0, 8)
      let localdate = new Date(time).toISOString().substr(0, 10)
      return localdate + " " + localtime
    },
    convertDate(time){
      return new Date(time)
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1005") {
          this.kbmBtn = true
        }
        if (access_right[a] == "1006") {
          this.scheduleBtn = true
        }
      }
    },
  },
  mounted() {
    this.checkAccess()

    this.classworkTypes();
    this.user_data = getUser()

    this.$root.$on("refreshClassroomSchedule", () => {
      this.$bvModal.hide('modal-classroom-schedules')
    });
  },
  watch: {
    classworksData: function (newVal, oldVal) {
      // watch it
      // //("Classwork natural changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>

<style scoped>
.classwork-title {
  cursor: pointer;
}

.bg-light-kbm{
  background-color: #C9F7F5;
}
/* .classwork-title:hover {
  font-weight: bolder;
}
.classwork-title .text-muted:hover {
  color: #a0a0a0 !important;
} */
</style>