<template>
<v-app>
  <div class="animate__animated animate__fadeIn">
    <div class="classwork-schedule-form">
      <div id="kt_classwork_schedule_form">
        <div
          class="wizard wizard-1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Body-->
          <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
            <div class="col-xl-12">
              <!--begin: Wizard Form-->
              <form class="form" @submit.stop.prevent="classworkScheduleAdd()">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h5
                    class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg"
                  >
                    Tambah Jadwal Belajar Online
                  </h5>

                  <b-form-group
                    id="input-group-agenda"
                    label="Agenda:"
                    label-for="input-agenda"
                  >
                    <b-form-input
                      id="input-agenda"
                      v-model="postSchedule.agenda"
                      placeholder="Agenda"
                    ></b-form-input>
                    <small class="text-danger">{{ error.agenda }}</small>
                  </b-form-group>

                  <b-form-group
                    id="input-group-meeting-platform-id"
                    label="Platform Pertemuan:"
                    label-for="input-meeting-platform-id"
                  >
                    <treeselect
                      v-model="postSchedule.meeting_platform_id"
                      :multiple="false"
                      placeholder="Pilih Platform Pertemuan"
                      :options="meeting_platforms"
                    />
                    <small class="text-danger">{{
                      error.meeting_platform_id
                    }}</small>
                  </b-form-group>

                  <b-form-group
                    id="input-group-meeting-url"
                    label="Tautan:"
                    label-for="input-meeting-url"
                  >
                    <b-form-input
                      id="input-meeting-url"
                      v-model="postSchedule.meeting_url"
                      placeholder="Tautan"
                    ></b-form-input>
                    <small class="text-danger">{{ error.meeting_url }}</small>
                  </b-form-group>

                  <b-form-group
                    id="input-group-user-name"
                    label="Username:"
                    label-for="input-user-name"
                  >
                    <b-form-input
                      id="input-user-name"
                      v-model="postSchedule.user_name"
                      placeholder="Username"
                    ></b-form-input>
                    <small class="text-danger">{{ error.user_name }}</small>
                  </b-form-group>

                  <b-form-group
                    id="input-group-password"
                    label="Password:"
                    label-for="input-password"
                  >
                    <b-form-input
                      id="input-password"
                      v-model="postSchedule.password"
                      placeholder="Password"
                    ></b-form-input>
                    <small class="text-danger">{{ error.password }}</small>
                  </b-form-group>

                  <!-- <div class="form-group">
                    <label>Mulai</label>
                    <input
                      type="text"
                      class="form-control form-control-md"
                      @click="$bvModal.show('modal-start-date')"
                      :value="date_start + ' ' + time_start"
                      readonly
                    />
                    <small class="text-danger">{{ error.start_date }}</small>
                    <b-modal id="modal-start-date" hide-footer size="lg">
                      <v-app>
                        <template #modal-title>
                          Awal Waktu Pertemuan Daring
                        </template>

                        <div class="row justify-content-center">
                          <div class="col-md-6 col-sm-12">
                            <v-date-picker v-model="date_start"></v-date-picker>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <v-time-picker
                              ampm-in-title
                              format="24hr"
                              scrollable
                              use-seconds
                              v-model="time_start"
                            ></v-time-picker>
                          </div>
                        </div>

                        <b-button
                          class="mt-3"
                          block
                          @click="$bvModal.hide('modal-start-date')"
                          >Selesai</b-button
                        >
                      </v-app>
                    </b-modal>
                  </div> -->

                  <!-- <div class="form-group">
                    <label>Selesai</label>
                    <input
                      type="text"
                      class="form-control form-control-md"
                      @click="$bvModal.show('modal-end-date')"
                      :value="date_end + ' ' + time_end"
                      readonly
                    />
                    <small class="text-danger">{{ error.end_date }}</small>
                    <b-modal id="modal-end-date" hide-footer size="lg">
                      <v-app>
                        <template #modal-title>
                          Akhir Waktu Pertemuan Daring
                        </template>

                        <div class="row justify-content-center">
                          <div class="col-md-6 col-sm-12">
                            <v-date-picker v-model="date_end"></v-date-picker>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <v-time-picker
                              ampm-in-title
                              format="24hr"
                              scrollable
                              use-seconds
                              v-model="time_end"
                            ></v-time-picker>
                          </div>
                        </div>

                        <b-button
                          class="mt-3"
                          block
                          @click="$bvModal.hide('modal-end-date')"
                          >Selesai</b-button
                        >
                      </v-app>
                    </b-modal>
                  </div> -->

                  <b-form-group
                    id="input-group-start-date"
                    label="Mulai:"
                    label-for="input-start-date"
                  >
                      <v-datetime-picker
                        v-model="date_start"
                        :time-picker-props="timeProps"
                        time-format="HH:mm:ss"
                      ></v-datetime-picker>
                    <small class="text-danger">{{ error.start_date }}</small>
                  </b-form-group>

                  <b-form-group
                    id="input-group-end-date"
                    label="Selesai:"
                    label-for="input-end-date"
                  >
                      <v-datetime-picker
                        v-model="date_end"
                        :time-picker-props="timeProps"
                        time-format="HH:mm:ss"
                      ></v-datetime-picker>
                    <small class="text-danger">{{ error.end_date }}</small>
                  </b-form-group>

                  <div class="form-group">
                    <label>Topik</label>
                    <treeselect
                      :options="topics"
                      v-model="postSchedule.topic_id"
                      :multiple="false"
                    >
                    </treeselect>
                    <small class="text-danger">{{ error.topic_id }}</small>
                  </div>

                  <b-form-group
                    id="input-group-section-id"
                    label="Siswa:"
                    label-for="input-section-id"
                  >
                    <treeselect
                      v-model="classwork_to"
                      :multiple="true"
                      placeholder="Pilih Siswa"
                      :options="students"
                    />
                    <small class="text-danger">{{ error.classwork_to }}</small>
                  </b-form-group>

                  <b-form-group
                    id="input-group-section-id"
                    label="Seksi:"
                    label-for="input-section-id"
                  >
                    <treeselect
                      v-model="postClasswork.section_id"
                      :multiple="false"
                      placeholder="Pilih Seksi"
                      :options="sections"
                    />
                    <small class="text-danger">{{ error.section_id }}</small>
                  </b-form-group>

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div>
                      <b-button type="submit" variant="primary"
                        >Simpan</b-button
                      >
                      <b-button
                        type="button"
                        class="ml-2"
                        variant="default"
                        @click="handleContent"
                      >
                        Batal
                      </b-button>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
      </div>
    </div>
  </div>
</v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ApiService from "@/core/services/api.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    postSchedule: Object,
    postClasswork: Object,
    classroom_id: String,
  },
  data() {
    return {
      datetime: new Date(),
      timeProps: {
        useSeconds: true,
        ampmInTitle: true
      },
      error: {
        section_id: "",
        classwork_to: "",
        classwork_id: "",
        agenda: "",
        start_date: "",
        end_date: "",
        meeting_platform_id: "",
        meeting_url: "",
        user_name: "",
        password: "",
        topic_id: "",
        section_id: "",
      },
      sections: [],
      participants: [],
      participantsData: [],
      fixedParticipants: [],
      students: [],
      meeting_platforms: [],
      topics: [],
      date_start: new Date(),
      time_start: "00:00:00",
      date_end: new Date(),
      time_end: "00:00:00",
      classwork_to: [0],
    };
  },
  methods: {
    showForm() {},
    handleContent() {
      this.$emit("handleContent", "list");
    },
    async getSectionOption() {
      let response = await module.setTreeSelect(
        `api/classrooms/${this.classroom_id}/sections`
      );

      // Success
      if (response.state == "success") {
        // Set Option Select
        this.sections = response.data;
        this.sections.unshift({
          label: "Pilih Seksi",
          id: "",
          isDisabled: true,
        });
      }
    },
    getStudentOption() {
      ApiService.get("api/classrooms/" + this.classroom_id + "/participants")
        .then((response) => {
          this.participants = response.data.data;
          this.participantsData = [];
          for (let a = 0; a < this.participants.length; a++) {
            this.fixedParticipants = response.data.data[a].children;
            if (response.data.data[a].role_id == 2) {
              for (let b = 0; b < this.fixedParticipants.length; b++) {
                this.participantsData.push(this.fixedParticipants[b]);
              }
            }
          }

          this.students = [
            {
              id: 0,
              label: "Semua siswa",
              children: this.participantsData.map((elem) => ({
                id: elem.participant_id,
                label: elem.user_name,
              })),
            },
          ];

          //("option student", this.students);
        })
        .catch((error) => {
          //(error);
        });
    },
    async getTopicOption() {
      ApiService.get(`api/topics/my-topics/${getUser().id}`)
        .then((response) => {
          this.topics = response.data.data;
          for(let a = 0; a < this.topics.length; a++){
            for(let b = 0; b < this.topics[a].children.length; b++){
              delete this.topics[a].children[b].children
            }
          }
          this.topics.unshift({
            label: "Pilih Topik",
            id: "",
            isDisabled: true,
          });
          //("topics ===================================", this.topics);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    async getMeetingPlatformOption() {
      let response = await module.setTreeSelect(`api/meeting-platforms`);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.meeting_platforms = response.data;
        this.meeting_platforms.unshift({
          label: "Pilih Platform",
          id: "",
          isDisabled: true,
        });
      }
    },
    async classworkScheduleAdd() {
      // jika tugas ditujukan untuk semua siswa
      //("classwork_to", this.classwork_to);
      //("classwork_to[0]", this.classwork_to[0]);
      if (this.classwork_to[0] == 0) {
        this.postClasswork.classroom_id = parseInt(this.classroom_id);
        this.postClasswork.classwork_to = [];
        for (let a = 0; a < this.participantsData.length; a++) {
          this.postClasswork.classwork_to.push(
            this.participantsData[a].participant_id
          );
        }
        this.postClasswork.classwork_to = JSON.stringify(
          this.postClasswork.classwork_to
        );
        //("classwork", this.postClasswork);
        //("schedule", this.postSchedule);

        // set classwork-schedule
        // //('date', new Date().toISOString().substr(0, 10))
        // //('time', new Date().toTimeString().substr(0, 8))

        let start_date = new Date(this.date_start).toISOString().substr(0, 10)
        let start_time = new Date(this.date_start).toTimeString().substr(0, 8)

        let end_date = new Date(this.date_end).toISOString().substr(0, 10)
        let end_time = new Date(this.date_end).toTimeString().substr(0, 8)

        this.postSchedule.start_date = start_date + " " + start_time
        this.postSchedule.end_date = end_date + " " + end_time
        this.postSchedule.section_id = this.postClasswork.section_id;

        // Make Request
        let response = await module.submit(
          this.postClasswork,
          "api/classrooms/" + this.classroom_id + "/classworks"
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
          //("this.error", this.error);
          //("response.data.errors", response.error.errors);
        } else {
          this.postSchedule.classwork_id = response.success;
          //("response woyy", response);
          let schedule = await module.submitWithoutAlert(
            this.postSchedule,
            `api/classrooms/${this.classroom_id}/classworks/${response.success}/classwork-schedules`
          );
          // classrooms/{classroomId}/classworks/{classworkId}/classwork-schedules
          // Check Response
          if (schedule.state == "error") {
            // Validation Error
            this.error = validation.setValidationError(
              this.error,
              schedule.error.errors
            );
          } else {
            // Success
            Swal.fire(
              schedule.success.title,
              schedule.success.message,
              "success"
            );

            this.$root.$emit("refreshClasswork");
            this.handleContent();
          }
        }
      }
      // jika tugas ditujukan untuk beberapa siswa
      else {
        //("classwork_to", this.classwork_to);
        //("classwork_to[0]", this.classwork_to[0]);
        this.postClasswork.classroom_id = parseInt(this.classroom_id);
        this.postClasswork.classwork_to = JSON.stringify(this.classwork_to);
        //("classwork", this.postClasswork);
        //("schedule", this.postSchedule);

        // set classwork-schedule
        let start_date = new Date(this.date_start).toISOString().substr(0, 10)
        let start_time = new Date(this.date_start).toTimeString().substr(0, 8)

        let end_date = new Date(this.date_end).toISOString().substr(0, 10)
        let end_time = new Date(this.date_end).toTimeString().substr(0, 8)

        this.postSchedule.start_date = start_date + " " + start_time
        this.postSchedule.end_date = end_date + " " + end_time
        this.postSchedule.section_id = this.postClasswork.section_id;

        // Make Request
        let response = await module.submit(
          this.postClasswork,
          "api/classrooms/" + this.classroom_id + "/classworks"
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
          //("this.error", this.error);
          //("response.data.errors", response.error.errors);
        } else {
          this.postSchedule.classwork_id = response.success;
          //("response woyy", response);
          // "api/classrooms/" +
          //     this.classroom_id +
          //     "/classworks/" +
          //     this.classwork_id +
          //     "/classwork-schedules",
          let schedule = await module.submitWithoutAlert(
            this.postSchedule,
            `api/classrooms/${this.classroom_id}/classworks/${response.success}/classwork-schedules`
          );

          // Check Response
          if (schedule.state == "error") {
            // Validation Error
            this.error = validation.setValidationError(
              this.error,
              schedule.error.errors
            );
          } else {
            // Success
            Swal.fire(
              schedule.success.title,
              schedule.success.message,
              "success"
            );
            this.$root.$emit("refreshClasswork");
            this.handleContent();
          }
        }
      }
    },
  },
  mounted() {
    this.getSectionOption();
    this.getTopicOption();
    this.getMeetingPlatformOption();
    this.getStudentOption();
  },
  watch: {},
};
</script>

<style>
</style>