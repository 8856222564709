<template>
  <div>
    <div class="col-12">
      <div class="card">
        <div
          class="card-body classroom-image"
          :style="'background-image: url(' + detail.image_header + ')'"
        >
        
        </div>
        <!-- <div class="card-body filter-image"></div> -->
        <div class="place-title">
          <div class="classroom-title shadow-sm card card-custom p-1 mt-1">
            <h2 class="text-primary">{{ detail.room }}</h2>
          </div>
          <div class="d-flex justify-content-end" v-if="!isMobile()">
            <div class="shadow p-2 d-inline card card-custom mt-2 mr-2">
            <b-button
              @click="classDelete(detail.id)"
              class="mr-1"
              variant="danger"
              v-b-tooltip.hover.top="'Hapus Kelas'"
              v-if="btn"
            >
              <i class="fas fa-trash px-0"></i>
            </b-button>
            <b-button
              @click="
              $router.push({
                path: '/classrooms/edit/' + detail.id,
              })
              "
              class=""
              variant="success"
              v-b-tooltip.hover.top="'Edit Kelas'"
              v-if="btn"
              ><i class="fas fa-edit px-0"></i>
            </b-button>
            </div>
          </div>
          <div class="" v-if="isMobile()">
            <b-dropdown
              no-caret
              variant="link"
              toggle-class="text-decoration-none"
              :style="'position:absolute; right:10px; text-shadow: 2px 2px #000000'"
              dropleft
              v-if="btn"
            >
              <template #button-content>
                <span style="color: white"
                  ><b-icon-three-dots-vertical></b-icon-three-dots-vertical
                ></span>
              </template>
              <b-dropdown-item>
                <router-link :to="'/classrooms/edit/' + detail.id"
                  >Edit</router-link
                >
              </b-dropdown-item>
              <b-dropdown-item>
                <router-link
                  :to="{ path: 'classrooms/edit/', params: { id: detail.id } }"
                  >hapus</router-link
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div v-if="dataLoaded">
          <b-tabs content-class="">
            <b-tab v-if="kbmView">
              <template #title>
                <span class="d-md-none d-block"
                  ><i class="fas fa-users"></i
                ></span>
                <span class="d-none d-md-block">Belajar Mengajar (KBM)</span>
              </template>
              <KBM
                :totalStudents="totalStudents"
                :teachers="teachers"
                :classroom_name="classroom_name"
                :classroom_id="classroom_id"
                :classworksData="classworks_data"
                :classroomSchedule="classroom_schedules"
              />
            </b-tab>
            <b-tab v-if="conversationView">
              <template #title>
                <span class="d-md-none d-block"
                  ><i class="fas fa-comments"></i
                ></span>
                <span class="d-none d-md-block">Diskusi</span>
              </template>
              <ClassroomConversation
                :classroomName="classroom_name"
                :classroomId="classroom_id"
                :classroomConversation="classroom_conversations"
              />
            </b-tab>
            <b-tab v-if="scoreView">
              <template #title>
                <span class="d-md-none d-block"
                  ><i class="fas fa-star"></i
                ></span>
                <span class="d-none d-md-block">Nilai</span>
              </template>
              <Score 
                :classworkQuizScore="classwork_quiz_scores"
                :classworkQuizScoreNoPagination="classwork_quiz_scores_no_pagination"
                :classworkAssignmentScore="classwork_assignment_scores" 
                :classworkAssignmentScoreNoPagination="classwork_assignment_scores_no_pagination" 
                :classroom_id="classroom_id" 
                :classroom_name="classroom_name"
              />
            </b-tab>
            <b-tab v-if="presenceView">
              <template #title>
                <span class="d-md-none d-block"
                  ><i class="fas fa-clipboard-list"></i
                ></span>
                <span class="d-none d-md-block">Kehadiran</span>
              </template>
              <Presence 
                :classroomId="classroom_id" 
                :classroomSchedule="classroom_schedules"
                :classroomScheduleToday="classroom_schedules_today"
                :participants="participants"
              />
            </b-tab>
            <b-tab v-if="participantView">
              <template #title>
                <span class="d-md-none d-block"
                  ><b-icon-person-fill></b-icon-person-fill
                ></span>
                <span class="d-none d-md-block">Peserta</span>
              </template>
              <Participants
                :teacherParticipants="teacherParticipants"
                :studentParticipants="studentParticipants"
                :classroom_id="classroom_id"
                :participants="participants"
            /></b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

import KBM from "../../components/classroom/KBM";
import ClassroomConversation from "../../components/classroom/ClassroomConversation";
import Score from "../../components/classroom/Score";
import Presence from "../../components/classroom/Presence";
import Participants from "../../components/classroom/Participants";

export default {
  name: "detailclass",
  data() {
    return {
      dataLoaded: false,
      classroom_id: "",
      // classroom detail
      detail: [],
      totalStudents: "",
      teachers: [],
      classroom_name: "",

      // participants
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      participants: [],
      teacherParticipants: [],
      studentParticipants: [],
      purpose: [],
      // classwork
      classworks: [],
      classworks_fixed: [],
      classworks_data: [],
      // classwork quiz scores
      classwork_quiz_scores: [],
      classwork_quiz_scores_no_pagination: [],
      classwork_quiz_scores_filter:{
        name: "",
        quiz_id: ""
      },
      // classwork assignment scores
      classwork_assignment_scores: [],
      classwork_assignment_scores_no_pagination: [],
      classwork_assignment_scores_filter:{
        name: "",
        assignment_id: ""
      },
      // classroom conversation
      classroom_conversations: [],
      // classroom schedule
      classroom_schedules: [],
      classroom_schedules_today: {},
      // access
      btn: false,
      kbmView: false,
      conversationView: false,
      scoreView: false,
      presenceView: false,
      participantView: false
    };
  },
  components: {
    KBM,
    ClassroomConversation,
    Score,
    Presence,
    Participants,
  },
  methods: {
    // mobile detection
    isMobile() {
        var checkIsMobile = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          checkIsMobile = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return checkIsMobile;
    },

    async getClassroomById(id) {
      let response = await module.getFullResponse("api/classrooms/" + id);
      // If Data Not Found
      if (response == null) {
        // Redirect To List
        // this.$router.push('/classrooms/detail/' + id)
      } else {
        this.detail = response.data.data;

        // set list teachers
        this.teachers = response.data.meta.teachers;

        // set classroom name
        this.classroom_name = response.data.data.name;

        // set total students
        this.totalStudents = response.data.meta.counts.Siswa;

        // this.getParticipants(id)

        this.dataLoaded = true;
      }
    },
    async classDelete(id) {
      // Delete Data
      let result = await module.delete("api/classrooms/" + id);
      // If Deleted
      if (result) {
        this.$router.push({ path: "/classrooms" });
      }
    },
    // get participants (Tab Peserta)
    async getParticipants(id) {
      let filterParams = ``;
      // classrooms/{classroomId}/participants
      let response = await module.paginate(
        `api/classrooms/${id}/participants`,
        `?page=1&page_size=40${filterParams}`
      );
      // let pagination = response.meta.pagination;
      // this.totalRows = pagination.total;

      let a, b;
      b = 40 * (1 - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.participants = response.data;
    },

    // get classwork (Tab KBM)
    async getClasswork(id) {
      let response = await module.paginate(`api/classrooms/${id}/classworks`);

      this.classworks_data = response.data;
    },

    // get Classwork Quiz Score (Tab Nilai)
    async getClassworkQuizScore(classroom_id) {
      // pagination
      let filterParams = `&name=${this.classwork_quiz_scores_filter.name}&quiz_id=${this.classwork_quiz_scores_filter.quiz_id}`;
      let response = await module.paginate(
        `api/classrooms/${classroom_id}/classworks/classwork-quiz-scores`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      // this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.classwork_quiz_scores = response.data;

      // no pagination
      let responseNoPagination = await module.paginate(
        `api/classrooms/${classroom_id}/classworks/classwork-quiz-scores-no-pagination`,
        `?${filterParams}`
      );
      this.classwork_quiz_scores_no_pagination = responseNoPagination.data
    },

    // get Classwork Assignment Score (Tab Nilai)
    async getClassworkAssignmentScore(classroom_id) {
      let filterParams = `&name=${this.classwork_assignment_scores_filter.name}&assignment_id=${this.classwork_assignment_scores_filter.assignment_id}`;
      // pagination
      let response = await module.paginate(
        `api/classrooms/${classroom_id}/classworks/classwork-assignment-scores`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      // this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.classwork_assignment_scores = response.data;

      // no pagination
      let responseNoPagination = await module.paginate(
        `api/classrooms/${classroom_id}/classworks/classwork-assignment-scores-no-pagination`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      this.classwork_assignment_scores_no_pagination = responseNoPagination.data
    },

    // get Classroom conversation
    async getClassroomConversation(classroom_id) {
      let filterParams = ``;
      let response = await module.paginate(
        `api/classrooms/${classroom_id}/classroom-conversations`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      // let pagination = response.meta.pagination;
      // // this.totalRows = pagination.total;

      // let a, b;
      // b = this.perPage * (this.currentPage - 1) + 1;
      // for (a = 0; a < response.data.length; a++) {
      //   response.data[a].number = b + a;
      // }

      this.classroom_conversations = response.data;
    },

    // classroom schedules
   async getClassroomSchedule(classroom_id){
      let response = await module.paginate(
        `api/classrooms/${classroom_id}/classroom-schedules`
      );

      this.classroom_schedules = response.data;
    },

   async getClassroomScheduleToday(classroom_id){
      let response = await module.get(
        `api/classrooms/${classroom_id}/classroom-schedules/today`
      );

      this.classroom_schedules_today = response;
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1002") {
          this.btn = true
        }
        if (access_right[a] == "1004" || access_right[a] == "1005") {
          this.kbmView = true
        }
        if (access_right[a] == "1007" || access_right[a] == "1008") {
          this.conversationView = true
        }
        if (access_right[a] == "1009" || access_right[a] == "1010") {
          this.scoreView = true
        }
        if (access_right[a] == "1011" || access_right[a] == "1012") {
          this.presenceView = true
        }
        if (access_right[a] == "1013" || access_right[a] == "1014") {
          this.participantView = true
        }
      }
    },

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ruang Kelas", route: "/classrooms" },
      { title: "Detail Kelas" },
    ]);

    this.checkAccess()

    // get
    this.getClassroomById(this.$route.params.id);
    this.getParticipants(this.$route.params.id);
    this.getClasswork(this.$route.params.id);
    this.getClassworkQuizScore(this.$route.params.id);
    this.getClassworkAssignmentScore(this.$route.params.id);
    this.getClassroomConversation(this.$route.params.id);
    this.getClassroomSchedule(this.$route.params.id);
    this.getClassroomScheduleToday(this.$route.params.id);
    this.classroom_id = this.$route.params.id;

    this.$root.$on("refreshParticipants", (id) => {
      this.getParticipants(id);
      this.getClassroomById(id);
    });

    this.$root.$on("refreshClasswork", () => {
      this.getClasswork(this.classroom_id)
      this.getClassworkQuizScore(this.classroom_id)
      this.getClassworkAssignmentScore(this.classroom_id)
    });

// ========== CLASSWORK QUIZ SCORE =============
    this.$root.$on("refreshClassworkQuizScore", () => {
      this.getClassworkQuizScore(this.classroom_id)
    });
    this.$root.$on("classworkQuizScoreFilter", (filter) => {
      this.classwork_quiz_scores_filter.name = filter.name
      this.classwork_quiz_scores_filter.quiz_id = filter.quiz_id
      this.getClassworkQuizScore(this.classroom_id)
    });

// ========== CLASSWORK ASSIGNMENT SCORE =============
    this.$root.$on("refreshClassworkAssignmentScore", () => {
      this.getClassworkAssignmentScore(this.classroom_id)
    });
    this.$root.$on("classworkAssignmentScoreFilter", (filter) => {
      this.classwork_assignment_scores_filter.name = filter.name
      this.classwork_assignment_scores_filter.assignment_id = filter.assignment_id
      this.getClassworkAssignmentScore(this.classroom_id)
    });

    this.$root.$on("refreshClassroomConversation", () => {
      this.getClassroomConversation(this.classroom_id)
    });

    this.$root.$on("refreshClassroomSchedule", () => {
      this.getClassroomSchedule(this.classroom_id)
      this.getClassroomScheduleToday(this.classroom_id)
    });
  },

  beforeRouteUpdate(to, from, next) {
    // this.getContent(to.params.uid);
    this.getClassroomById(to.params.id);
    this.getParticipants(to.params.id);
    this.getClasswork(to.params.id);
    this.getClassworkQuizScore(to.params.id);
    this.getClassworkAssignmentScore(to.params.id);
    this.getClassroomConversation(to.params.id);
    this.getClassroomSchedule(to.params.id);
    this.getClassroomScheduleToday(to.params.id);
    this.classroom_id = to.params.id;
    next();
  },
};
</script>

<style scoped>
.card-body {
  height: 150px;
}

.card h2 {
  color: white;
}

.filter-image {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 150px;
  opacity: 0.5;
}

.place-title {
  position: absolute;
  width: 100%;
  height: 150px;
}

/* .place-title .btn-action {
  position: absolute;
  right: 1%;
  top: 6%;
} */

.place-title .btn-edit {
  position: absolute;
  right: 1%;
  top: 6%;
}

.place-title .btn-delete {
  position: absolute;
  right: 7%;
  top: 6%;
}

.classroom-title {
  position: absolute;
  top: 30px;
  left: 25px;
  /* text-shadow: 2px 2px #244ca3; */
}
</style>