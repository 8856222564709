<template>
  <div>
    <b-modal id="modal-section" hide-footer hide-header>
      <form class="form" @submit.stop.prevent="formOnSubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            Tambah Seksi
          </h5>
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama Seksi"
            ></b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <b-form-group id="input-group-address">
            <label for="input-address"
              >Deskripsi Seksi: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-address"
              v-model="form.description"
              placeholder="Deskripsi Seksi"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>
          <!--begin: Wizard Actions -->
          <div class="d-flex justify-content-between border-top pt-10">
            <div>
              <b-button type="submit" variant="primary">Simpan</b-button>
              <b-button
                type="button"
                class="ml-2"
                variant="default"
                @click="$bvModal.hide('modal-section')"
              >
                Batal
              </b-button>
            </div>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    classroom_id: String,
    route: String
  },
  data() {
    return {
      error: {
        name: "",
        description: "",
        classroom_id: ""
      },
    };
  },
  methods: {
    async formOnSubmit() {
        this.form.classroom_id = this.classroom_id
      // Make Request
      let response = await module.submit(
        this.form,
        this.route
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$root.$emit("refreshClasswork")
        this.$emit("sectionAdd", "list");
        this.resetErrorSectionOnNew()
      }
    },
    resetErrorSectionOnNew() {
      let self = this;
      Object.keys(this.error).forEach(function (key, index) {
        self.error[key] = "";
      });
    },
  },
};
</script>

<style>
</style>