<template>
  <div>
    <b-modal
      id="modal-lesson"
      size="xl"
      title="Daftar Pelajaran"
      body-class="pt-0"
      hide-footer
    >
      <b-tabs>
        <b-tab title="Daftar Pelajaran Saya" active>
          <div class="row">
            <div class="col-md-12">
              <Table
                @chooseLesson="chooseLesson"
                purpose="modal-lesson"
                :userData="userData"
              />
            </div>
          </div>
        </b-tab>
        <b-tab title="Daftar Pelajaran Sekolah">
          <div class="row">
            <div class="col-md-3">
              <b-form-select
                v-model="selected"
                @input="handleCourseActv"
                :options="options"
                size="sm"
                class="mt-3"
              ></b-form-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <TableCurriculum
                purpose="modal-lesson"
                :userData="userData"
                :course="course_actv"
                @chooseLesson="chooseLesson"
              />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import Table from "@/view/components/materials/lessons/Table.vue";
import TableCurriculum from "@/view/components/courses/lessons/Table.vue";

export default {
  props: {
    userData: Object,
  },
  components: {
    Table,
    TableCurriculum,
  },
  data() {
    return {
      courses: [],
      course_actv: {},
      selected: "",
      options: [],
      options_form: {
        value: "",
        text: "",
      },
    };
  },
  methods: {
    chooseLesson(value) {
      this.$emit("chooseLesson", value);
    },
    async getCourse() {
      let currentPage = "";
      let perPage = "";
      // let filterParams = `&name=${this.filter.name}&category_id=${this.filter.category_id}&collection_id=${this.filter.collection_id}`
      let response = await module.paginate(
        `api/courses`,
        `?page=${currentPage}&page_size=${perPage}`
      );

      this.courses = response.data;

      for (let a = 0; a < this.courses.length; a++) {
        this.options_form.value = this.courses[a].id;
        this.options_form.text = this.courses[a].name;

        let clone = { ...this.options_form };
        this.options.push(clone);
      }

      //("course", this.courses);
      //("options", this.options);
      this.course_actv = await module.get('api/curriculums/' + this.courses[0].id)
      this.selected = this.courses[0].id
      //("course acth", this.course_actv);
    },
   async handleCourseActv(evt) {
      this.course_actv = await module.get('api/curriculums/' + evt)
      //("course actv", this.course_actv)
    },
  },
  mounted() {
    this.getCourse();
  },
};
</script>

<style>
</style>