<template>
  <div class="animate__animated animate__fadeIn">
    <div class="container ">
      <div class="row justify-content-between">
        <div class="col-md-6 pb-0">
          <h3 class="d-block">{{ detail.title }}</h3>
          <span class="text-muted">{{
            detail.created_by_name + `(${detail.created_at_display})`
          }}</span>
        </div>
        <div class="col-md-4 pb-0">
          <table>
            <tr>
              <td class="pr-1">
                <span class="font-weight-bold">Seksi</span>
              </td>
              <td class="pl-0 pr-0" width="10">
                <span class="font-weight-bold">:</span>
              </td>
              <td class="pl-2">
                <span>{{ detail.section_name }}</span>
              </td>
            </tr>
            <tr>
              <td class="pr-1">
                <span class="font-weight-bold">Waktu</span>
              </td>
              <td class="pl-0 pr-0" width="10">
                <span class="font-weight-bold">:</span>
              </td>
              <td class="pl-2">
                <span>{{
                  setDate(detail.start_date) + " - " + setDate(detail.due_date)
                }}</span>
              </td>
            </tr>
            <tr>
              <td class="pr-1">
                <span class="font-weight-bold">Point</span>
              </td>
              <td class="pl-0 pr-0" width="10">
                <span class="font-weight-bold">:</span>
              </td>
              <td class="pl-2">
                <span>{{ detail.point }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <p>{{ detail.instruction }}</p>
    <v-divider></v-divider>
    <h6 v-if="image_list.length > 0 || attachment_list_display.length > 0">Lampiran</h6>
    <div class="row mt-2">
      <div v-for="(image, index) in image_list" :key="image" class="col-md-4">
        <a :href="image_list[index]" download="file" target="_blank">
          <b-card
            no-body
            class="overflow-hidden card-attach-image"
            style="max-width: 540px"
          >
            <b-row no-gutters :class="'align-items-center'">
              <b-col md="5">
                <b-card-img
                  :src="image_list[index]"
                  alt="Image"
                  class="rounded-0"
                ></b-card-img>
              </b-col>
              <b-col md="7">
                <h5 class="ml-2 text-dark">
                  {{ image_name[index] }}
                </h5>
                <span class="ml-2 text-muted">{{
                  image_extension[index]
                }}</span>
              </b-col>
            </b-row>
          </b-card>
        </a>
      </div>
    </div>
    <div class="row mt-2">
      <div
        v-for="(attachment, index) in attachment_list_display"
        :key="attachment"
        class="col-md-4"
      >
      <a 
          download="file" 
          target="_blank"
          :href="attachment_list_display[index]"
          v-if="attachment_extension[index] !== 'pdf'"
        >
        <b-card no-body 
          class="overflow-hidden card-attach-image" 
          style="max-width: 540px"
        >
          <b-row no-gutters :class="'align-items-center'">
            <b-col md="2">
              <i class="fas fa-file-pdf fa-5x" v-if="attachment_extension[index] === 'pdf'"></i>
              <i class="fas fa-file-word fa-5x" v-if="attachment_extension[index] === 'docx'"></i>
            </b-col>
            <b-col md="10">
              <h5 class="ml-3 text-dark">
                {{ file_name[index] }}
                <!-- {{ attachment_list_display[index] }} -->
              </h5>
              <span class="ml-3 text-muted">{{ attachment_extension[index] }}</span>
            </b-col>
          </b-row>
        </b-card>
        </a>
        <a 
          href="#"
          v-if="attachment_extension[index] === 'pdf'"
        >
        <b-card no-body 
          class="overflow-hidden card-attach-image" 
          style="max-width: 540px"
          @click="attachment_extension[index] === 'pdf' ? viewPdf(attachment_list_display[index]) : ''"
        >
          <b-row no-gutters :class="'align-items-center'">
            <b-col md="2">
              <i class="fas fa-file-pdf fa-5x" v-if="attachment_extension[index] === 'pdf'"></i>
              <i class="fas fa-file-word fa-5x" v-if="attachment_extension[index] === 'docx'"></i>
            </b-col>
            <b-col md="10">
              <h5 class="ml-3 text-dark">
                {{ file_name[index] }}
                <!-- {{ attachment_list_display[index] }} -->
              </h5>
              <span class="ml-3 text-muted">{{ attachment_extension[index] }}</span>
            </b-col>
          </b-row>
        </b-card>
        </a>
      </div>
    </div>
    <template v-if="userData.role_key_name == 'student'">
    <v-divider></v-divider>
    <h6>Jawaban</h6>
    <form @submit.stop.prevent="classworkAssignmentResult" enctype="multipart/form-data">
      <b-form-group
        id="input-group-answer"
        label="Keterangan:"
        label-for="input-answer"
      >
        <b-form-input
          id="input-answer"
          v-model="postAssignment.answer"
          placeholder="Keterangan"
        ></b-form-input>
        <small class="text-danger">{{ error.answer }}</small>
      </b-form-group>

      <b-form-group
        id="input-group-file"
        label="Lampiran:"
        label-for="input-file"
      >
        <b-form-file
          multiple
          placeholder="Upload Lampiran"
          drop-placeholder="Lepaskan File disini..."
          @change="upload"
        ></b-form-file>
        <small class="text-danger">{{ error.file_uploaded }}</small>
      </b-form-group>
      <b-button type="submit" variant="primary" v-if="userData.role_id == 2">Simpan</b-button>
      <b-button
      type="button"
      class="ml-2"
      variant="default"
      @click="handleContent"
    >
      Tutup
    </b-button>
    </form>
    </template>
    <template v-else>
      <b-button
      type="button"
      class="ml-2 mt-4"
      variant="primary"
      @click="handleContent"
    >
      Tutup
    </b-button>
    </template>

    <!-- pdf preview -->
    <PdfPreview :pdfFile="pdfFile" @closePreview="closePreview" />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import PdfPreview from "@/view/components/general/PdfPreview.vue"

export default {
  props: {
    detail: Object,
    classroom_id: String,
  },
  components: {
    PdfPreview
  },
  data() {
    return {
      attachment_list: [],
      attachment_list_display: [],
      attachment_extension: [],
      file_name: [],
      image_list: [],
      image_name: [],
      image_extension: [],
      postAssignment: {
        classwork_id: "",
        assignment_id: "",
        student_id: "",
        answer: "",
        is_correct: "",
        score: "",
        checked_by: "",
        file_uploaded: "",
        final_score: "",
        final_checked_by: "",
        is_finish: ""
      },
      error: {
        classwork_id: "",
        assignment_id: "",
        student_id: "",
        answer: "",
        is_correct: "",
        score: "",
        checked_by: "",
        file_uploaded: "",
        final_score: "",
        final_checked_by: "",
      },
      formData: new FormData(),
      userData: getUser(),
      // other
      pdfFile: null
    };
  },
  methods: {
    viewPdf(pdfFile){
      this.pdfFile = pdfFile
    },
    closePreview(){
      this.pdfFile = null
    },
    handleContent() {
      this.$emit("handleContent", "list");
      this.attachment_list = [];
      this.attachment_list_display = [];
      this.file_name = [];
      this.image_list = [];
      this.image_name = [];
    },
    setDate(paramsTime) {
     let date = new Date(paramsTime).toLocaleString();
     let time = new Date(paramsTime).toTimeString().substr(0, 8);
     
      return date
    },
    setAttachmentList() {
      // set date
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let localdata = new Date(this.detail.created_at).toLocaleDateString(
        "id-ID",
        options
      );
      this.detail.created_at_display = localdata.split(",")[1];

      this.attachment_list = this.detail.attachment_list.split(",");
      //("attach", this.attachment_list);
      for (let c = 0; c < this.attachment_list.length; c++) {
        if (this.attachment_list[c] == "") {
          this.attachment_list.splice(c, 1);
        }
      }

      for (let a = 0; a < this.attachment_list.length; a++) {
        let splitExtension = this.attachment_list[a].split(".");
        let extension = splitExtension[splitExtension.length - 1];
        if (extension === "jpg" || extension == "png" || extension == "jpeg") {
          this.image_extension.push(splitExtension[splitExtension.length - 1]);
          //("image extens", this.image_extension);
          this.image_list.push(this.attachment_list[a]);
          //("image display", this.image_list);
          let image_name = this.attachment_list[a].split("____")[1];
          this.image_name.push(image_name.substring(0, 10) + "...");
          //("image_name", this.image_name);
        } else {
          this.attachment_extension.push(
            splitExtension[splitExtension.length - 1]
          );
          //("attach extens", this.attachment_extension);
          this.attachment_list_display.push(this.attachment_list[a]);
          //("attach display", this.attachment_list_display);
          let file_name = this.attachment_list[a].split("____")[1];
          this.file_name.push(file_name.substring(0, 18) + "...");
          //("file_name", this.file_name);
        }
      }
    },
    upload(event) {
      if (event.target.files.length > 0) {
        this.formData = new FormData();
      }

      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("attachment[]", event.target.files[a]);
      }

      //("attachment", event.target.files);
    },
    async classworkAssignmentResult() {
      let user_data = JSON.parse(window.localStorage.getItem("user"));
      this.postAssignment.checked_by = this.detail.created_by;
      this.postAssignment.final_checked_by = this.detail.created_by;
      this.postAssignment.classwork_id = this.detail.classwork_id;
      this.postAssignment.assignment_id = this.detail.assignment_id;
      this.postAssignment.score = 0;
      this.postAssignment.final_score = 0;
      this.postAssignment.is_correct = 0;
      this.postAssignment.student_id = user_data.id;
      this.postAssignment.is_finish = 1;
      this.postAssignment['_method'] = 'put'

      for (const [key, value] of Object.entries(this.postAssignment)) {
        this.formData.append(key, value);
      }
      // Make Request
      // classrooms/{classroomId}/classworks/{classworkId}/classwork-assignment-results/{classworkAssignmentResultId}
      let response = await module.submit(
          this.formData, `api/classrooms/${this.classroom_id}/classworks/${this.detail.classwork_id}/classwork-assignment-results/${this.detail.assignment_classwork_id}`
        );
        
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.handleContent();
      }
    },
  },
  mounted() {
    this.setAttachmentList();
  },
  watch: {
    detail: function (newVal, oldVal) {
      // watch it
      //("detail classwork changed: ", newVal, " | was: ", oldVal);
      this.setAttachmentList();
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 60px !important;
  height: 100px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper:hover {
  opacity: 0.8;
}

.card-attach-image:hover {
  opacity: 0.8;
  color: #1bc5bd !important;
}

.text-dark:hover {
  color: #1bc5bd !important;
}
</style>