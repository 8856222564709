<template>
  <div class="card card-custom">
    <div class="card-body p-0" v-if="dataLoaded == true">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div
          class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10"
          v-if="is_presence == true"
        >
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <img :src="detail.presence.photo" width="200" alt="" />
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <!-- <h4 class="font-weight-bold text-dark">
                        {{ detail.name }}
                      </h4> -->

                  <div class="card-body">
                    <table class="table table-borderless">
                      <tr>
                        <th class="pr-1" width="130">
                          <span class="font-weight-bold">Nama</span>
                        </th>
                        <th class="pl-0">
                          <span class="font-weight-bold">:</span>
                        </th>
                        <td class="pl-2">
                          <span class="">{{ detail.user.name }}</span>
                        </td>
                      </tr>
                      <tr>
                        <th class="pr-1" width="130">
                          <span
                            class="font-weight-bold"
                            v-if="detail.presence.is_presence == 1"
                            >Waktu Izin</span
                          >
                          <span
                            class="font-weight-bold"
                            v-if="detail.presence.is_presence == 2"
                            >Waktu Presensi</span
                          >
                        </th>
                        <th class="pl-0">
                          <span class="font-weight-bold">:</span>
                        </th>
                        <td class="pl-2">
                          <span
                            class=""
                            v-if="detail.presence.is_presence == 2"
                            >{{ convertDate(detail.presence.date) }}</span
                          >
                          <span
                            class=""
                            v-if="detail.presence.is_presence == 1"
                            >{{ setDate(detail.presence.date) }}</span
                          >
                        </td>
                      </tr>
                      <tr v-if="detail.presence.is_presence == 1">
                        <th class="pr-1" width="130">
                          <span class="font-weight-bold">Keterangan</span>
                        </th>
                        <th class="pl-0">
                          <span class="font-weight-bold">:</span>
                        </th>
                        <td class="pl-2">
                          <span class="">{{ detail.presence.notes }}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div>
                    <b-button
                      type="button"
                      class="ml-2"
                      variant="primary"
                      @click="$emit('handleCloseDetail')"
                    >
                      Tutup
                    </b-button>
                    <!-- <b-button
                      type="button"
                      class="ml-2"
                      variant="success"
                      @click="
                        $router.push('/presence/edit/' + $route.params.id)
                      "
                      v-if="detail.presence.is_presence == 2"
                    >
                      Edit
                    </b-button> -->
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<style>
.wizard-steps {
  height: 230px;
  width: 230px;
}

.wizard-steps img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import ApiService from "@/core/services/api.service.js";

export default {
  props: {
    detailPresence: Object,
  },
  data() {
    return {
      detail: {},
      is_presence: false,
      dataLoaded: false,
    };
  },
  methods: {
    setData() {
      this.detail = this.detailPresence;
      this.is_presence = true;

      setTimeout(() => {
        this.dataLoaded = true;
        //("dataloaded", this.dataLoaded);
      }, 2000);
    },
    get() {
      ApiService.get("api/student-presences/" + this.$route.params.id)
        .then((response) => {
          this.detail = response.data.data;
          if (this.detail.presence.is_presence == 2) {
            this.is_presence = true;
          }
          //("detail", this.detail);

          setTimeout(() => {
            this.dataLoaded = true;
            //("dataloaded", this.dataLoaded);
          }, 2000);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    convertDate(date) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let localdata = new Date(date).toLocaleDateString("id-ID", options);
      return localdata + " " + date.split(" ")[1];
    },
    setDate(date) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let localdata = new Date(date).toLocaleDateString("id-ID", options);
      return localdata;
    },
  },
  mounted() {
    // this.get();
    this.setData();
  },
  watch: {
    detailPresence: function (newVal, oldVal) {
      // watch it
      //("detailPresence changed: ", newVal, " | was: ", oldVal);
      this.setData();
    },
  },
};
</script>
