<template>
  <v-row class="fill-height animate__animated animate__fadeIn">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <!-- <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Hari ini
          </v-btn> -->
          <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Hari</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Minggu</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Bulan</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 Hari</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          locale="id-ID"
          ref="calendar"
          v-model="focus"
          color="primary"
          :event-more="false"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="calendarChange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-btn
                icon
                @click="confirmSetActivity(selectedEvent)"
                v-b-tooltip.hover
                title="Jadikan hari masuk"
                v-if="
                  selectedEvent.parent_id == 0 && selectedEvent.is_school == 0
                "
              >
                <v-icon>fas fa-book-open</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="confirmSetHoliday(selectedEvent)"
                v-b-tooltip.hover
                title="Jadikan hari libur"
                v-if="
                  selectedEvent.parent_id == 0 && selectedEvent.is_school == 1
                "
              >
                <v-icon>fas fa-book</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :style="'z-index: 99'"
                    @click.stop.prevent
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-card class="mx-auto" max-height="300px" min-width="30%" tile>
                  <v-list dense>
                    <v-list-item-group color="primary" min-width="150">
                      <v-list-item
                        @click="
                          $router.push(
                            `/academic-calendars/edit/${selectedEvent.id}`
                          )
                        "
                      >
                        <v-list-item-content> Edit </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-if="selectedEvent.parent_id != 0">
                        <v-list-item-content
                          @click="deleteActivity(selectedEvent.id)"
                        >
                          Hapus
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-menu>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.description"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  props:{
    orderMonth: Number,
    classroomId: String
  },
  data() {
    return {
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Bulan",
        week: "Minggu",
        day: "Hari",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      // events: [
      //   {
      //     name: "test",
      //     start: "2021-06-25 10:00:00",
      //     end: "2021-06-26 11:30:00",
      //     color: "cyan",
      //   },
      //   {
      //     name: "test",
      //     start: "2021-06-27 07:00:00",
      //     end: "2021-06-28 07:25:00",
      //     color: "green",
      //   },
      //   {
      //     name: "test",
      //     start: "2021-06-28 08:00:00",
      //     end: "2021-06-28 08:15:00",
      //     color: "red",
      //   },
      // ],
      events: [],
      events_form: {
        id: "",
        name: "",
        start: "",
        end: "",
        color: "",
        parent_id: "",
        description: "",
        school_year_id: "",
        is_school: "",
        is_absent: "",
      },
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      // filter
      filter: {
        school_year_id: "",
        now: new Date().toISOString().substr(0, 10),
        month: new Date().toISOString().substr(0, 7)
      },
      items: [],
      perPage: 31,
      currentPage: 1,
      totalRows: 1,
      set_holiday: {
        name: "Libur",
        start_date: new Date().toISOString().substr(0, 10),
        due_date: new Date().toISOString().substr(0, 10),
        school_year_id: "",
        is_school: 0,
        is_absent: 0,
        description: "",
      },
      school_years: [],
      school_year_actv: "",
      // other
      dataLoaded: false,
    };
  },
  methods: {
    calendarChange(evt){
      //("evt", evt)
    },
    async getSchedules() {
      this.events = [];
      // let today = new Date().toISOString().substr(0, 10);
      let filterParams = `&now=${this.filter.now}&school_year_id=${this.filter.school_year_id}&month=${this.filter.month}`;
      let response = await module.paginate(
        `api/classrooms/${this.classroomId}/academic-calendars/presences/users/${getUser().id}/students`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      //("calendar", response);
      this.items = response.data;
      for (let a = 0; a < this.items.length; a++) {
        this.events_form.id = this.items[a].id;
        this.events_form.name = this.items[a].name;
        // this.events_form.start = this.items[a].start_date;
        // this.events_form.end = this.items[a].due_date;
        this.events_form.start = new Date(this.items[a].start_date)
          .toISOString()
          .substr(0, 10);
        this.events_form.end = new Date(this.items[a].due_date)
          .toISOString()
          .substr(0, 10);
        this.events_form.parent_id = this.items[a].parent_id;
        this.events_form.description = this.items[a].description;
        this.events_form.school_year_id = this.items[a].school_year_id;
        this.events_form.is_school = this.items[a].is_school;
        this.events_form.is_absent = this.items[a].is_absent;
        if (this.items[a].is_school == 0 && this.items[a].parent_id == 0) {
          this.events_form.color = "red";
        } else if (
          this.items[a].is_school == 1 &&
          this.items[a].parent_id == 0
        ) {
          this.events_form.color = "green";
        } else if (
          this.items[a].is_school == 1 &&
          this.items[a].parent_id != 0
        ) {
          this.events_form.color = "deep-purple";
        }

        let clone = { ...this.events_form };
        this.events.push(clone);
        this.events_form = []

        if(this.items[a].presence != null && this.items[a].presence.is_presence == 2){
            let presence = this.items[a].presence
            this.events_form.name = `Hadir`
            this.events_form.start = presence.date
            this.events_form.end = presence.date
            this.events_form.color = "#1BC5BD"
            let clone = { ...this.events_form };
            this.events.push(clone);
        }else if(this.items[a].status == 1 && this.items[a].presence == null){
            this.events_form.name = `Tidak Hadir`
            this.events_form.start = new Date(this.items[a].start_date)
            .toISOString()
            .substr(0, 10);
            this.events_form.end = new Date(this.items[a].due_date)
            .toISOString()
            .substr(0, 10);
            this.events_form.color = "red"
            let clone = { ...this.events_form };
            this.events.push(clone);
        }else if(this.items[a].presence != null && this.items[a].presence.is_presence == 1){
            this.events_form.name = `Izin`
            this.events_form.start = new Date(this.items[a].start_date)
            .toISOString()
            .substr(0, 10);
            this.events_form.end = new Date(this.items[a].due_date)
            .toISOString()
            .substr(0, 10);
            this.events_form.color = "purple"
            let clone = { ...this.events_form };
            this.events.push(clone);
        }

        if(a + 1 == this.items.length){
          this.dataLoaded = true
        }
      }

      //("event", this.events);
    },
    setActivity(evt) {
      //("evt", evt);
    },
    async confirmSetHoliday(evt) {
      //("evt", evt);
      this.set_holiday.name = "Libur";
      this.set_holiday.start_date = evt.start;
      this.set_holiday.due_date = evt.end;
      this.set_holiday.is_school = 0;
      this.set_holiday.is_absent = 0;
      this.set_holiday.description = evt.description;
      this.set_holiday.school_year_id = evt.school_year_id;
      //("evt", this.set_holiday);

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let convert_date = new Date(evt.start).toLocaleDateString(
        "id-ID",
        options
      );
      Swal.fire({
        title: `Buat hari ${convert_date} jadi hari libur?`,
        text: `Semua Kegiatan yang ada pada hari ${convert_date} akan terhapus`,
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.setHoliday();
        }
      });
    },
    async setHoliday() {
      // Make Request
      let response = await module.submit(
        this.set_holiday,
        "api/academic-calendars/holidays"
      );
      // Check Response
      if (response.state == "error") {
        // error
      } else {
        // Success
        this.getSchedules();
      }
    },
    async confirmSetActivity(evt) {
      //("evt", evt);
      this.set_holiday.name = "Masuk";
      this.set_holiday.start_date = evt.start;
      this.set_holiday.due_date = evt.end;
      this.set_holiday.is_school = 1;
      this.set_holiday.is_absent = 1;
      this.set_holiday.description = evt.description;
      this.set_holiday.school_year_id = evt.school_year_id;
      //("evt", this.set_holiday);

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let convert_date = new Date(evt.start).toLocaleDateString(
        "id-ID",
        options
      );
      Swal.fire({
        title: `Buat hari ${convert_date} jadi hari masuk sekolah?`,
        // text: `Semua Kegiatan yang ada pada hari ${convert_date} akan terhapus`,
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.setActivity();
        }
      });
    },
    async setActivity() {
      // Make Request
      let response = await module.submit(
        this.set_holiday,
        "api/academic-calendars/activities"
      );
      // Check Response
      if (response.state == "error") {
        // error
      } else {
        // Success
        this.getSchedules();
      }
    },
    async deleteActivity(id) {
      // Delete Data
      let result = await module.delete("api/academic-calendars/" + id);
      // If Deleted
      if (result) {
        this.getSchedules();
      }
    },
    async getSchoolYearOption() {
      // get school year active
      let today = new Date().toISOString().substr(0, 10)
      let filterParams = `now=${today}`;
      let school_year_actv = await module.paginate(
        "api/school-years",
        `?${filterParams}`
      );
      //("school_year_actv", school_year_actv)
      // this.filter.school_year_id = school_year_actv.data[0].id
      this.school_year_actv = school_year_actv.data[0].name
      //("schol actv", this.school_year_actv)

      // get all school year
      let response = await module.setTreeSelect("api/school-years");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.school_years = response.data;
        this.school_years.unshift({
          label: "Tahun Ajaran Berjalan",
          id: school_year_actv.data[0].id,
          isDisabled: true,
        });
      }
    },
    filterBySchoolYear(id, name){
      this.filter.now = ''
      this.school_year_actv = name
      this.filter.school_year_id = id
      //("id", id)
      this.events = []
      this.getSchedules()
    },
    async getSchoolYearActive() {
      
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      //("event", event);
      //("nativeevent", nativeEvent);

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    // updateRange({ start, end }) {
    //   const events = [];

    //   const min = new Date(`${start.date}T00:00:00`);
    //   const max = new Date(`${end.date}T23:59:59`);
    //   const days = (max.getTime() - min.getTime()) / 86400000;
    //   const eventCount = this.rnd(days, days + 20);

    //   for (let i = 0; i < eventCount; i++) {
    //     const allDay = this.rnd(0, 3) === 0;
    //     const firstTimestamp = this.rnd(min.getTime(), max.getTime());
    //     const first = new Date(firstTimestamp - (firstTimestamp % 900000));
    //     const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
    //     const second = new Date(first.getTime() + secondTimestamp);

    //     events.push({
    //       name: this.names[this.rnd(0, this.names.length - 1)],
    //       start: this.formatDate(first, !allDay),
    //       end: this.formatDate(second, !allDay),
    //       color: this.colors[this.rnd(0, this.colors.length - 1)],
    //     });
    //   }

    //   this.start = start;
    //   this.end = end;
    //   this.events = events;
    // },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() - 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() - 1 }-${a.getDate()}`;
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
    if(this.$refs.calendar.title.split(" ")[0] == "July"){
      this.$refs.calendar.prev(6)
    }
    //("refs", this.$refs.calendar)

    this.getSchedules();
    this.getSchoolYearOption();
    this.getSchoolYearActive()

    this.$root.$on("refreshPresenceCalendar", () => {
      this.getSchedules()
    });
  },
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
};
</script>

<style>
</style>