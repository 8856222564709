import { render, staticRenderFns } from "./Participants.vue?vue&type=template&id=5dbb6b70&scoped=true&"
import script from "./Participants.vue?vue&type=script&lang=js&"
export * from "./Participants.vue?vue&type=script&lang=js&"
import style0 from "./Participants.vue?vue&type=style&index=0&id=5dbb6b70&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dbb6b70",
  null
  
)

export default component.exports