<template>
  <div class="animate__animated animate__fadeIn">
    <div class="row justify-content-between mt-8 mb-10">
      <div>
        <h3 class="d-block">{{ detail.title }}</h3>
        <span class="text-muted">{{
          detail.created_by_name + `(${detail.created_at_display})`
        }}</span>
      </div>
      <div>
        <table>
          <tr>
            <td class="pr-1" style="vertical-align: top">
              <span class="font-weight-bold">Tanggal</span>
            </td>
            <td class="pl-0 pr-0" width="10" style="vertical-align: top">
              <span class="font-weight-bold">:</span>
            </td>
            <td class="pl-2">
              <span>{{ detail.start_date_display + " - "}}</span><br>
              <span>{{ detail.due_date_display}}</span>
            </td>
          </tr>
          <tr>
            <td class="pr-1">
              <span class="font-weight-bold">Waktu</span>
            </td>
            <td class="pl-0 pr-0" width="10">
              <span class="font-weight-bold">:</span>
            </td>
            <td class="pl-2">
              <span>{{detail.duration + " " + detail.duration_unit_name}}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <v-divider></v-divider>
    <!-- <template v-if="userData.role_id == 1">
      ahay
      <v-divider></v-divider>
    </template> -->
<!-- example value for deadline 2018-12-25 00:00:00 -->
    <Countdown
      :deadline="detail.due_date"
      @timeElapsed="timeElapsedHandler"
      @handleWarning="handleWarning"
      v-if="start_quiz"
    ></Countdown>

    <div class="container" v-if="questionLoaded">
      <div class="row">
        <div class="col-12">
        <form @submit.stop.prevent="confirmSubmit()">
          <b-table
            borderless
            :items="detail.questions"
            :fields="fieldsQuestion"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width: field.key === 'number' ? '5%' : '',
                }"
              />
            </template>

            <template #cell(content)="data">
              <!-- Single text -->
              <template v-if="data.item.question_type_id == 1">
                <span>{{ data.item.content }}</span> <br />
                <b-form-group
                  :id="
                    'input-group-single-text' +
                    classwork_quiz_result_collection[data.index].identity_form
                  "
                  :label-for="
                    'input-single-text' +
                    classwork_quiz_result_collection[data.index].identity_form
                  "
                >
                  <b-form-input
                    :id="
                      'input-single-text' +
                      classwork_quiz_result_collection[data.index].identity_form
                    "
                    v-model="
                      classwork_quiz_result_collection[data.index].answer
                    "
                    placeholder="Jawaban"
                    :disabled="disable_form"
                  ></b-form-input>
                </b-form-group>
              </template>
              <!-- Multiple text -->
              <template v-if="data.item.question_type_id == 2">
                <span>{{ data.item.content }}</span> <br />
                <b-form-group
                  :id="
                    'input-group-multiple-text' +
                    classwork_quiz_result_collection[data.index].identity_form
                  "
                >
                  <b-form-textarea
                    :id="
                      'input-multiple-text' +
                      classwork_quiz_result_collection[data.index].identity_form
                    "
                    v-model="
                      classwork_quiz_result_collection[data.index].answer
                    "
                    placeholder="Jawaban"
                    rows="4"
                    max-rows="8"
                    :disabled="disable_form"
                  ></b-form-textarea>
                </b-form-group>
              </template>

              <!-- True Or False -->
              <template v-if="data.item.question_type_id == 3">
                <span>{{ data.item.content }}</span>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    :id="
                      'radio-group-' +
                      classwork_quiz_result_collection[data.index].identity_form
                    "
                    v-model="
                      classwork_quiz_result_collection[data.index].answer
                    "
                    :options="data.item.answer_choices_display"
                    :aria-describedby="ariaDescribedby"
                    :name="
                      'radio-options-' +
                      classwork_quiz_result_collection[data.index].identity_form
                    "
                    :disabled="disable_form"
                  ></b-form-radio-group>
                </b-form-group>
              </template>

              <!-- Single Choice -->
              <template v-if="data.item.question_type_id == 4">
                <span>{{ data.item.content }}</span>
                <span class="d-block mt-2">Jawaban:</span>
                <div
                  class="d-flex align-items-center"
                  v-for="answer in data.item.answer_choices_display"
                  :key="answer.value"
                >
                  <div>
                    <b-form-radio
                      :key="answer.value"
                      :name="
                        'some-radios-' +
                        classwork_quiz_result_collection[data.index]
                          .identity_form
                      "
                      v-model="
                        classwork_quiz_result_collection[data.index].answer
                      "
                      :value="answer.value"
                      :disabled="disable_form"
                      >{{ answer.text }}</b-form-radio
                    >
                  </div>
                </div>
              </template>

              <!-- Multiple Choice -->
              <template v-if="data.item.question_type_id == 5">
                <span>{{ data.item.content }}</span>
                <div
                  class="d-flex align-items-center"
                  v-for="(answer, index) in data.item.answer_choices_display"
                  :key="answer.value"
                >
                  <div>
                    <b-form-checkbox
                      :checked="answer.checked"
                      :id="
                        `checkbox-pick-` +
                        data.item.answer_choices_display[index].identity_form
                      "
                      :name="
                        `checkbox-pick-` +
                        data.item.answer_choices_display[index].identity_form
                      "
                      @change="chooseAnswerMultiple(answer.value, data.index)"
                      :disabled="disable_form"
                    >
                    </b-form-checkbox>
                    <!-- {{data.item.questions[data.index].answer_choices_display[index].identity_form}} -->
                  </div>
                  <div>
                    <td class="pl-0">
                      <span>{{ answer.text }}</span>
                    </td>
                  </div>
                </div>
              </template>
              <!-- Completion -->
              <template v-if="data.item.question_type_id == 6">
                <span>{{ data.item.content }}</span>
                <b-form-group
                  :id="
                    'input-group-answer-choice' +
                    classwork_quiz_result_collection[data.index].identity_form
                  "
                >
                  <label
                    :for="
                      'input-answer-completion' +
                      classwork_quiz_result_collection[data.index].identity_form
                    "
                    >Jawaban</label
                  >
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-input
                        :id="
                          'input-answer-completion' +
                          classwork_quiz_result_collection[data.index]
                            .identity_form
                        "
                        v-model="answer_collection_form[data.index].text"
                        placeholder="Jawaban"
                        :disabled="disable_form"
                      >
                      </b-form-input>
                    </div>
                    <div class="col-md-3">
                      <b-button
                        size="sm"
                        class="btn-info"
                        v-b-tooltip.hover
                        title="Tambah Jawaban"
                        type="button"
                        @click="
                          setAnswerCompletionChoice(
                            answer_collection_form[data.index].identity_form,
                            data.index
                          )
                        "
                        v-if="answer_collection_form[data.index].text != ''"
                        ><i class="fas fa-plus px-0"></i
                      ></b-button>
                      <b-button
                        size="sm"
                        class="btn-info"
                        disabled
                        v-if="answer_collection_form[data.index].text == ''"
                        ><i class="fas fa-plus px-0"></i
                      ></b-button>
                    </div>
                  </div>
                  <table>
                    <!-- <draggable
                      v-model="answer_collection_completion[data.index]"
                      tag="tbody"
                    > -->
                      <tr
                        v-for="(answer, index) in answer_collection_completion[
                          data.index
                        ]"
                        :key="
                          answer.value +
                          classwork_quiz_result_collection[data.index]
                            .identity_form
                        "
                      >
                        <th class="pr-4">
                          <i
                            class="fas fa-times text-danger px-0"
                            style="cursor: pointer"
                            v-b-tooltip.hover
                            title="Hapus"
                            @click="removeAnswerChoice(answer.value)"
                          ></i>
                        </th>
                        <th>
                          <span>{{ index + 1 + ". " }}</span>
                        </th>
                        <th>
                          <span style="cursor: pointer">{{
                            " " + answer.text
                          }}</span>
                        </th>
                      </tr>
                    <!-- </draggable> -->
                  </table>
                </b-form-group>
              </template>
            </template>
          </b-table>
          <v-divider></v-divider>
          <b-button type="submit" variant="primary" v-if="start_quiz"
            >Selesai</b-button
          >
        </form>
        </div>
      </div>
    </div>

    <b-button
      type="button"
      class="ml-2 btn-start-quiz"
      variant="primary"
      @click="startQuiz"
      v-if="start_quiz == false && isFinishClassworkQuiz == 0 && userData.role_id == 2"
    >
      Kerjakan
    </b-button>
    <b-button
      type="button"
      class="ml-2"
      variant="default"
      @click="handleContent"
      v-if="start_quiz == false"
    >
      Tutup
    </b-button>
  </div>
</template>

<script>
import Countdown from "@/view/components/general/Countdown.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import draggable from "vuedraggable";
import { relativeTimeThreshold } from 'moment';

export default {
  components: {
    Countdown,
    draggable
  },
  props: {
    detail: Object,
    classroom_id: String,
    available_answer: Array,
    isFinishClassworkQuiz: Number,
    userData: Object
  },
  data() {
    return {
      fieldsQuestion: [
        {
          key: "number",
          label: "",
        },
        { key: "content", label: "" },
      ],
      // classwork quiz result and scores
      classwork_quiz_result_collection: [],
      classwork_quiz_result_form: {
        // quiz_result
        classwork_id: "",
        quiz_id: "",
        question_id: "",
        student_id: "",
        answer: "",
        is_correct: "",
        score: "",
        checked_by: "",
        // quiz_scores
        student_id: "",
        final_checked_by: "",
        // other
        identity_form: "",
        question_type_id: "",
      },
      // set answer for question type completion
      answer_collection_form: [],
      answer_collection_completion_form: {
        text: "",
        value: "",
        identity_form: ""
      },
      answer_collection_completion: [],
      answer_value : 0,
      // answer
      put:{
        answer: null,
        id: [],
        is_finish: 0,
        // end: ''
      },
      id_form:{
        classwork_id: "",
        quiz_id: "",
        student_id: "",
      },
      // other
      questionLoaded: false,
      start_quiz: false,
      disable_form: true,
      multiple_choice_answer: [],
      set_answer_form_success: false,
      set_answer_form_success2: false,
      auto_save_handler: false,
      today: new Date()
    };
  },
  methods: {
    convertDate(date){
      return new Date(date)
    },
    handleContent() {
      this.$emit("handleContent", "list");
    },
    scrollToTop(options) {
      // window.scrollTo(0, 0);
      let el = this.$el.getElementsByClassName("text-muted")[0];
      if (el) {
        el.scrollIntoView(options);
      }
    },
    scrollToBottom(options) {
      // window.scrollTo(0, 0);
      let el = this.$el.getElementsByClassName("btn-start-quiz")[0];
      if (el) {
        el.scrollIntoView(options);
      }
    },
    // set answer form
  async setAnswerForm() {
      // set form for all question types
      let user_data = JSON.parse(window.localStorage.getItem("user"));
      for (let a = 0; a < this.detail.questions.length; a++) {
        this.classwork_quiz_result_form.identity_form = a;
        this.classwork_quiz_result_form.classwork_id = this.detail.classwork_id;
        this.classwork_quiz_result_form.quiz_id = this.detail.quiz_id;
        this.classwork_quiz_result_form.question_id = this.detail.questions[a].id;
        this.classwork_quiz_result_form.checked_by = this.detail.questions[a].created_by;
        this.classwork_quiz_result_form.student_id = user_data.id;
        this.classwork_quiz_result_form.final_checked_by = this.detail.questions[a].created_by;
        this.classwork_quiz_result_form.is_correct = 0
        this.classwork_quiz_result_form.score = 0
        this.classwork_quiz_result_form.question_type_id = this.detail.questions[a].question_type_id;
        if(this.detail.questions[a].question_type_id == 3 || this.detail.questions[a].question_type_id == 4 || this.detail.questions[a].question_type_id == 5){
          this.classwork_quiz_result_form.score = parseInt(this.detail.question_list_display[a].mark)
          // this.classwork_quiz_result_form.is_correct = 1
        }
        if(this.detail.questions[a].question_type_id == 5){
          this.classwork_quiz_result_form.answer = []
        }
        let clone = { ...this.classwork_quiz_result_form };
        this.classwork_quiz_result_collection.push(clone);
        // classwork_id: "",
        // quiz_id: "",
        // question_id: "",
        // student_id: "",
        // answer: "",
        // is_correct: "",
        // score: "",
        // checked_by: "",
        // student_id: "",
        // final_checked_by: "",
        // identity_form: "",
        // question_type_id: "",
        // reset classwork quiz form
        for (const [key, value] of Object.entries(this.classwork_quiz_result_form)) {
        this.classwork_quiz_result_form[key] = "";
        }

        if(a == this.detail.questions.length - 1){
          this.set_answer_form_success = true
          // //("stop==========")
        }
      }
      // //(
      //   "classwork_quiz_result_collection",
      //   this.classwork_quiz_result_collection
      // );

      // set identity form for multiple choice
      for (let b = 0; b < this.detail.questions.length; b++) {
        if (this.detail.questions[b].question_type_id == 5) {
          for (
            let c = 0;
            c < this.detail.questions[b].answer_choices_display.length;
            c++
          ) {
            this.detail.questions[b].answer_choices_display[c].identity_form =
              c +
              "," +
              b +
              this.detail.questions[b].answer_choices_display[c].text +
              this.detail.questions[b].answer_choices_display[c].value;
          }
        }
        // mengatur wadah untuk jawaban completion
        this.answer_collection_completion_form.identity_form = b
        let clone_completion = { ...this.answer_collection_completion_form }
        this.answer_collection_form.push(clone_completion)

        let arr_answer_collection_completion = []
        this.answer_collection_completion.push(arr_answer_collection_completion)

        // mengatur wadah untuk jawaban multiple choice
        let arr_multiple_choice_answer = []
        this.multiple_choice_answer.push(arr_multiple_choice_answer)
        this.set_answer_form_success2 = true
      }
      // //("answer completion form", this.answer_collection_form)
      // //("multiple choices answer", this.multiple_choice_answer)
      // //("answer collection", this.answer_collection_completion)

      // //("detail", this.detail);

      // mengambil jawaban dari database
      if(this.set_answer_form_success === true && this.set_answer_form_success2 === true && this.available_answer.length != 0){
        this.alreadyInput()
        // set unique value completion
      }

      // this.answer_value = this.answer_collection_completion.length

      this.questionLoaded = true;
    },
    // jawaban yang telah di input
    alreadyInput(){
      // //("available answer", this.available_answer)
      for(let d = 0; d < this.classwork_quiz_result_collection.length; d++){
            if(this.classwork_quiz_result_collection[d].question_type_id == 1 || this.classwork_quiz_result_collection[d].question_type_id == 2){
              this.classwork_quiz_result_collection[d].answer = this.available_answer[d].answer
            }
            else if(this.classwork_quiz_result_collection[d].question_type_id == 3){
              this.classwork_quiz_result_collection[d].answer = this.available_answer[d].answer
              // //("true or false =============", this.available_answer[d].answer)
            }
            else if(this.classwork_quiz_result_collection[d].question_type_id == 4){
              this.classwork_quiz_result_collection[d].answer = parseInt(this.available_answer[d].answer)
            }
            else if(this.classwork_quiz_result_collection[d].question_type_id == 5){
              let multiple_choices_answer = JSON.parse(this.available_answer[d].answer)
              for(let e = 0; e < multiple_choices_answer.length; e++){
                this.chooseAnswerMultiple(multiple_choices_answer[e], d)
              }
            }
            else if(this.classwork_quiz_result_collection[d].question_type_id == 6){
              // this.classwork_quiz_result_collection[d].answer = JSON.parse(this.available_answer[d].answer)
              let answer_completion = JSON.parse(this.available_answer[d].answer);
              // //("answer_completion yang ini =============", answer_completion)
              for(let f = 0; f < answer_completion.length; f++){
                this.answer_collection_completion_form.text = answer_completion[f].text
                this.setAnswerCompletionChoice(d, d)
              }
              this.answer_collection_completion[d] = JSON.parse(this.available_answer[d].answer)
            }
        }
        if(this.isFinishClassworkQuiz == 1){
        this.start_quiz = true
        this.disable_form = false
        this.auto_save_handler = true
        this.autoSaveHandler()
        }
    },
    // set answer for question type multiple choice
    chooseAnswerMultiple(id, index) {
      let question = this.detail.questions[index];
      for (let a = 0; a < question.answer_choices_display.length; a++) {
        if (
          question.answer_choices_display[a].value == id &&
          question.answer_choices_display[a].checked == false
        ) {
          this.multiple_choice_answer[index].push(question.answer_choices_display[a].value)
          question.answer_choices_display[a].checked = true;
        } else if (
          question.answer_choices_display[a].value == id &&
          question.answer_choices_display[a].checked == true
        ) {
          for(let b = 0; b < this.multiple_choice_answer[index].length; b++){
            if(this.multiple_choice_answer[index][b] == id){
              this.multiple_choice_answer[index].splice(b, 1)
            }
          }
          question.answer_choices_display[a].checked = false;
        }
      }
      // //("multiple choices answer", this.multiple_choice_answer)
    },
    // set answer for question type completion
    setAnswerCompletionChoice(identity_form, index) {
      for(let a = 0; a < this.answer_collection_form.length; a++){
        if(this.answer_collection_form[a].identity_form == identity_form){
            this.answer_value = Math.floor(Math.random() * 100) + 1
            this.answer_collection_form[a].value = this.answer_value
            let clone = { ...this.answer_collection_form[a] }
            this.answer_collection_completion[index].push(clone)
            this.answer_collection_form[a].text = ""
        }
      }
      for(let b = 0; b < this.answer_collection_completion.length; b++){
        for(let c = 0; c < this.answer_collection_completion[b].length; c++){
          this.answer_collection_completion[b][c].value = c
        }
      }
    },
    removeAnswerChoice(id) {
      for(let b = 0; b < this.answer_collection_completion.length; b++){
        for(let c = 0; c < this.answer_collection_completion[b].length; c++){
          if(this.answer_collection_completion[b][c].value == id){
            // //("true", this.answer_collection_completion[b][c])
            // this.answer_collection_completion[b].splice(c, 1)
            this.answer_collection_completion[b].splice(c, 1)
            this.answer_collection_completion[b].splice(this.answer_collection_completion.length)
            this.classwork_quiz_result_collection.splice(this.classwork_quiz_result_collection.length)
            // vm.items.splice(newLength)
            // vm.items.splice(indexOfItem, 1, newValue)
            // this.answer_collection_completion = []
          }
        }
      }
    },
    async getClassworkQuiz(classworkId, quizId) {
      let user_data = JSON.parse(window.localStorage.getItem('user'))
      let response = await module.paginate(
        `api/classrooms/${this.classroom_id}/classworks/${classworkId}/classwork-quiz-results/${quizId}/${user_data.id}`
      );
      this.put.id = response.data
      // //("put", this.put)
    },
    async formOnsubmit() {
      for(let a = 0; a < this.classwork_quiz_result_collection.length; a++){
        // single choice
        if(this.classwork_quiz_result_collection[a].question_type_id == 4){
          this.classwork_quiz_result_collection[a].answer = this.classwork_quiz_result_collection[a].answer
        }
        // multiple choice
        if(this.classwork_quiz_result_collection[a].question_type_id == 5){
          this.classwork_quiz_result_collection[a].answer = JSON.stringify(this.multiple_choice_answer[a])
        }
        // completion
        if(this.classwork_quiz_result_collection[a].question_type_id == 6){
          this.classwork_quiz_result_collection[a].answer = JSON.stringify(this.answer_collection_completion[a])
        }
      }
      this.put.is_finish = 1
      // //("jawaban", this.classwork_quiz_result_collection);
      this.put.answer = this.classwork_quiz_result_collection
      // //("put", this.put);
      // Make Request
      // classrooms/{classroomId}/classworks/{classworkId}/classwork-quiz-results
      let response = await module.submitUpdate(this.put, `api/classrooms/${this.classroom_id}/classworks/${this.detail.classwork_id}/classwork-quiz-results/update`)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        // Swal.fire(response.success.title, response.success.message, 'success')
        this.getClassworkQuiz(response.success.item.original.classwork_id, response.success.item.original.quiz_id)
        let endTime = new Date(response.success.item.original.end_quiz).toISOString().substr(0, 10)
        let currentDate = new Date(response.success.item.original.end_quiz).toISOString().substr(0, 10)
        let currentTime = new Date(response.success.item.original.end_quiz).toTimeString().substr(0, 8)
        // //("time", currentDate + " " + currentTime)
        this.detail.due_date = currentDate + " " + currentTime
      }
    },
    confirmSubmit(){
      Swal.fire({
        title: "Kirim Jawaban?",
        text: "Jawaban yang telah dikirimkan tidak dapat diubah kembali",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.formOnsubmitUpdate()
        } else if (result.isDenied) {
          this.scrollToBottom({ behavior: "smooth" });
        }
      });
    },
    async formOnsubmitUpdate() {

      for(let a = 0; a < this.classwork_quiz_result_collection.length; a++){
        // single choice
        if(this.classwork_quiz_result_collection[a].question_type_id == 4){
          this.classwork_quiz_result_collection[a].answer = this.classwork_quiz_result_collection[a].answer.toString()
        }
        // multiple choice
        if(this.classwork_quiz_result_collection[a].question_type_id == 5){
          this.classwork_quiz_result_collection[a].answer = JSON.stringify(this.multiple_choice_answer[a])
        }
        // completion
        if(this.classwork_quiz_result_collection[a].question_type_id == 6){
          this.classwork_quiz_result_collection[a].answer = JSON.stringify(this.answer_collection_completion[a])
        }
      }
      this.put.is_finish = 2
      let date = new Date().toISOString().substr(0, 10)
      let time = new Date().toTimeString().substr(0, 8)


      this.put.end = date + " " + time
      // //("jawaban", this.classwork_quiz_result_collection);
      this.put.answer = this.classwork_quiz_result_collection
      // //("put", this.put);
      // Make Request
      // classrooms/{classroomId}/classworks/{classworkId}/classwork-quiz-results
      let response = await module.submitUpdate(this.put, `api/classrooms/${this.classroom_id}/classworks/${this.detail.classwork_id}/classwork-quiz-results/update`)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.handleContent()
        this.auto_save_handler = false
        this.$root.$emit("refreshClassworkQuizScore")
        // this.getClassworkQuiz(response.success.item.original.classwork_id, response.success.item.original.classwork_id)
        // //("Response di sini", response)
      }
    },
    // start quiz
    startQuiz() {
      Swal.fire({
        title: "Mulai Mengerjakan?",
        text: "",
        icon: "question",
        showCancelButton: true,
        onAfterClose: () =>
          this.start_quiz == true
            ? this.scrollToTop({ behavior: "smooth" })
            : this.scrollToBottom({ behavior: "smooth" }),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.start_quiz = true;
          this.disable_form = false;
          this.auto_save_handler = true;
          this.autoSaveHandler()
          // if(this.available_answer.length == 0){
            this.formOnsubmit()
          // }
        } else if (result.isDenied) {
          this.scrollToBottom({ behavior: "smooth" });
        }
      });
    },
    timeElapsedHandler() {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Waktu habis! Jawaban akan tersimpan otomatis',
      })
      setTimeout(() => {
        this.formOnsubmitUpdate()    
      }, 3000)
      this.start_quiz = false
    },
    handleWarning(){
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: 'Waktu Kurang dari 10 menit. Jawaban akan otomatis terkirim ketika waktu habis',
      })
    },
    autoSaveHandler() {
      if(this.auto_save_handler == true){
        setTimeout(() => {
          if(this.auto_save_handler == true){
            this.formAutoSave()
            this.autoSaveHandler()
            // //("save")
          }else{
            // //("auto save stop")
          }
        }, 180000)
      }
    },
    async formAutoSave() {
      for(let a = 0; a < this.classwork_quiz_result_collection.length; a++){
        // single choice
        if(this.classwork_quiz_result_collection[a].question_type_id == 4){
          this.classwork_quiz_result_collection[a].answer = this.classwork_quiz_result_collection[a].answer.toString()
        }
        // multiple choice
        if(this.classwork_quiz_result_collection[a].question_type_id == 5){
          this.classwork_quiz_result_collection[a].answer = JSON.stringify(this.multiple_choice_answer[a])
        }
        // completion
        if(this.classwork_quiz_result_collection[a].question_type_id == 6){
          this.classwork_quiz_result_collection[a].answer = JSON.stringify(this.answer_collection_completion[a])
        }
      }
      this.put.is_finish = 1
      // //("jalan yang form auto save ==========================================================")
      // //("jawaban", this.classwork_quiz_result_collection);
      this.put.answer = this.classwork_quiz_result_collection
      // //("put", this.put);
      // Make Request
      // classrooms/{classroomId}/classworks/{classworkId}/classwork-quiz-results
      let response = await module.submitUpdateWithoutAlert(this.put, `api/classrooms/${this.classroom_id}/classworks/${this.detail.classwork_id}/classwork-quiz-results/update`)
      // this.getClassworkQuiz(this.detail.classwork_id, this.detail.quiz_id)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        // Swal.fire(response.success.title, response.success.message, 'success')
        // this.handleContent()
        // this.getClassworkQuiz(response.success.item.original.classwork_id, response.success.item.original.classwork_id)
        // //("Response di sini", response)
      }
    },
  },
  mounted() {
    this.setAnswerForm();
    this.getClassworkQuiz(this.detail.classwork_id, this.detail.quiz_id);
    let el = this.$el.getElementsByClassName("text-muted")[0];
    // //('=========asdasdasdsad====================================*******************************************', el)
    // this.autoSaveHandler();

    this.$root.$on("autoSaveStop", () => {
      // reset form on new (tambah data)
      this.auto_save_handler = false
    });
  },
  watch: {
    detail: function (newVal, oldVal) {
      // watch it
      // //("detail classwork changed: ", newVal, " | was: ", oldVal);
      this.setAnswerForm();
      this.getClassworkQuiz(this.detail.classworkId, this.detail.quiz_id)
    },
    available_answer: function (newVal, oldVal) {
      // watch it
      // //("available answer changed: ", newVal, " | was: ", oldVal);
      // this.alreadyInput();
    },
    isFinishClassworkQuiz: function (newVal, oldVal) {
      // watch it
      // //("is finish ============================ changed: ", newVal, " | was: ", oldVal);
      // this.alreadyInput();
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 60px !important;
  height: 100px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper:hover {
  opacity: 0.8;
}

.card-attach-image:hover {
  opacity: 0.8;
  color: #1bc5bd !important;
}

.text-dark:hover {
  color: #1bc5bd !important;
}
</style>