<template>
  <div>
    <div class="container pt-0 px-5">
      <div class="row animate__animated animate__fadeIn">
        <div class="col-12">
          <template v-if="content === 'list'">
            <div>
              <div class="row justify-content-end mt-n3">
                <div class="col-md-3" v-if="!isMobile()">
                  <b-button
                   variant="info"
                   @click="$bvModal.show('modal-assignment-score-classroom')"
                   >
                    Ekspor <i class="fas fa-print"></i>
                  </b-button>
                </div>
                <div class="col-md-3 offset-md-3">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text squared>
                        <b-icon-search></b-icon-search>
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      type="text"
                      v-model="filter.name"
                      placeholder="Cari Nama Siswa"
                      @keyup="filterByName"
                    ></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-md-3">
                  <b-input-group>
                    <treeselect
                      v-model="filter.assignment_id"
                      :multiple="false"
                      placeholder="Pilih Tugas"
                      :options="assignments"
                      @input="filterByAssignment"
                    />
                  </b-input-group>
                </div>
              </div>
              <div class="d-flex justify-content-start" v-if="isMobile()">
                <div class="col-md-3">
                  <b-button
                    variant="info"
                    @click="$bvModal.show('modal-assignment-score-classroom')"
                  >
                    Ekspor <i class="fas fa-print"></i>
                  </b-button>
                </div>
              </div>
              <b-table
                striped
                hover
                :items="classworkAssignmentScore"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '10%' : '' }"
                  />
                </template>

                <template #cell(competence_standard)="data">
                  <span>{{data.item.competence_standard ? data.item.competence_standard.code : ''}}</span> 
                  <span class="ml-1">{{data.item.competence_standard ? data.item.competence_standard.name : ''}}</span>
                </template>

                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Koreksi"
                    @click="
                      handleClassworkDetail(
                        data.item.classwork_id,
                        1,
                        data.item.student_id,
                        data.item.student_name
                      )
                    "
                    v-if="btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                </template>
              </b-table>

              <b-pagination
                v-if="classworkAssignmentScore.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="mt-4"
              ></b-pagination>
            </div>
          </template>

          <template v-if="content === 'correction'">
            <AssignmentCorrection
              @handleContent="handleContent"
              :detail="detailClasswork"
              :classroom_id="classroom_id"
              :studentId="student_id"
              :studentName="student_name"
              :availableAnswer="available_answer"
            />
          </template>
        </div>
      </div>
    </div>
    <ModalAssignmentScoreClassroom
      :classworkAssignmentScoreNoPagination="classworkAssignmentScoreNoPagination"
      :classroom_name="classroom_name"
    />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import AssignmentCorrection from "@/view/components/classroom/scores/assignment/AssignmentCorrection";
import ModalAssignmentScoreClassroom from "@/view/components/general/ModalAssignmentScoreClassroom";
export default {
  props: {
    classworkAssignmentScore: Array,
    classworkAssignmentScoreNoPagination: Array,
    classroom_id: String,
    classroom_name: String
  },
  components: {
    AssignmentCorrection,
    ModalAssignmentScoreClassroom
  },
  name: "nilai",
  data() {
    return {
      content: "list",
      // filter
      filter: {
        name: "",
        assignment_id: ""
      },
      assignments: [],
      // detail classwork
      detailClasswork: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "assignment_title",
          label: "Judul Tugas",
          sortable: true,
        },
        {
          key: "student_name",
          label: "Siswa",
          sortable: true,
        },
        {
          key: "final_score",
          label: "Total Nilai",
          sortable: true,
        },
        {
          key: "final_checked_by_name",
          label: "Pengoreksi",
          sortable: true,
        },
        {
          key: "is_finish_display",
          label: "Status",
          sortable: true,
        },
        {
          key: "competence_standard",
          label: "Standar Kompetensi",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      student_id: "",
      student_name: "",
      available_answer: [],
      btnAccess: false
    };
  },
  methods: {
    filterByName() {
      this.$root.$emit("classworkAssignmentScoreFilter", this.filter);
    },
    async filterByAssignment(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.assignment_id = await "";
        this.$root.$emit("classworkAssignmentScoreFilter", this.filter);
      } else {
        this.filter.assignment_id = await evt;
        this.$root.$emit("classworkAssignmentScoreFilter", this.filter);
        //("ok", this.filter.assignment_id);
      }
    },
    async getAssignmentOption() {
      let response = await module.setTreeSelectTitle(
        `api/classrooms/${this.classroom_id}/classworks/assignment-classworks/assignments`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.assignments = response.data;
        this.assignments.unshift({
          label: "Pilih Tugas",
          id: "",
          isDisabled: true,
        });
      }
    },
    handleContent(content) {
      this.content = content;
    },
    async handleClassworkDetail(
      classwork_id,
      classwork_type_id,
      student_id,
      student_name
    ) {
      this.student_id = student_id;
      //("student name", student_name);
      this.student_name = student_name;
      //("content", this.content);
      // classworks/{classworkId}/{classworkTypeId}
      this.detailClasswork = await module.get(
        `api/classworks/${classwork_id}/${classwork_type_id}`
      );
      // If Data Not Found
      if (this.detailClasswork == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.detailClasswork = this.detailClasswork[0];
        // classwork assignment
        //("detailClasswork", this.detailClasswork);
        this.getClassworkAssignment(
          this.detailClasswork.classwork_id,
          this.detailClasswork.assignment_id
        );
      }
    },
    async getClassworkAssignment(classworkId, assignment_id, studentId) {
      // classrooms/{classroomId}/classworks/{classworkId}/classwork-quiz-results/{assignment_id}
      let response = await module.paginate(
        `api/classrooms/${this.classroom_id}/classworks/${classworkId}/classwork-assignment-results/${assignment_id}/${this.student_id}`
      );
      this.available_answer = response.data;
      if (this.available_answer[0].is_correct == 0) {
        this.available_answer[0].is_active = false;
      } else {
        this.available_answer[0].is_active = true;
      }
      //("classwork assignment ============", this.available_answer);
      this.content = "correction";
      this.$root.$emit("refreshScoreContent", "correction");
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1010") {
          this.btnAccess = true
        }
      }
    },
    // mobile detection
    isMobile() {
        var checkIsMobile = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          checkIsMobile = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return checkIsMobile;
    },
  },
  mounted(){
    this.getAssignmentOption()
    this.checkAccess()
  },
  watch: {
    classworkAssignmentScore: function (newVal, oldVal) {
      // watch it
      //("classwork quiz score changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.sr-only {
  width: 0px !important;
}
</style>