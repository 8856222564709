<template>
  <div class="container animate__animated animate__fadeIn">
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h4 font-size-h3-lg">
          Edit Diskusi
        </h5>
        <div class="row">
          <div class="col-md-4 pr-0 col-sm-12">
            <b-form-group id="input-group-photo">
              <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                <div
                  class="image-input image-input-outline"
                  id="kt_profile_avatar"
                >
                  <div class="image-input-wrapper">
                    <img :src="photo" alt="" />
                  </div>
                  <label
                    class="
                      btn
                      btn-xs
                      btn-icon
                      btn-circle
                      btn-white
                      btn-hover-text-primary
                      btn-shadow
                    "
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i class="fa fa-pen icon-sm text-muted"></i>
                    <input
                      type="file"
                      name="profile_avatar"
                      accept=".png, .jpg, .jpeg"
                      @change="onFileChange($event)"
                    />
                    <input type="hidden" name="profile_avatar_remove" />
                  </label>
                  <span
                    class="
                      btn
                      btn-xs
                      btn-icon
                      btn-circle
                      btn-white
                      btn-hover-text-primary
                      btn-shadow
                    "
                    data-action="cancel"
                    data-toggle="tooltip"
                    title="Cancel avatar"
                  >
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                  <span
                    class="
                      btn
                      btn-xs
                      btn-icon
                      btn-circle
                      btn-white
                      btn-hover-text-primary
                      btn-shadow
                    "
                    data-action="remove"
                    data-toggle="tooltip"
                    title="Remove avatar"
                    @click="current_photo = null"
                  >
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                </div>
              </div>
            </b-form-group>
          </div>
          <div class="col-md-8 pl-0 col-sm-12">
            <b-form-group
              id="input-group-name"
              label="Judul:"
              label-for="input-name"
              class="mt-3"
            >
              <b-form-input
                id="input-name"
                v-model="form.name"
                placeholder="Judul Diskusi"
              ></b-form-input>
              <small class="text-danger">{{ error.name }}</small>
            </b-form-group>

            <b-form-group id="input-group-description">
              <label for="input-description"
                >Deskripsi: <em class="text-muted">opsional</em></label
              >
              <b-form-textarea
                id="input-description"
                v-model="form.description"
                placeholder="Deskripsi"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.description }}</small>
            </b-form-group>
          </div>
        </div>
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="handleFormEdit"
          >
            Batal
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    classroomName: String,
    classroomId: String,
    classroomConversationId: Number,
    form: Object
  },
  data() {
    return {
    //   form: {
    //     name: "",
    //     image: "",
    //     classroom_id: "",
    //     classroom_name: "",
    //     description: "",
    //   },
      error: {
        name: "",
        image: "",
        classroom_id: "",
      },
      // other
      current_photo: null,
      default_photo: "/img/blank.jpg",
    };
  },
  methods: {
    // async get() {
    //   this.form = await module.get(`api/classrooms/${this.classroomId}/classroom-conversations/${this.classroomConversationId}`)
    //   // If Data Not Found
    //   if (this.form == null) {
    //     // Redirect To List
    //     this.handleContentUpdate()
    //   } else {
    //     this.form['_method'] = 'put'
    //   }
    // },
    handleFormEdit() {
      this.$emit("handleFormEdit", "detail");
      this.resetErrorOnNew();
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.image = "";
    },
    resetErrorOnNew() {
      for (const [key, value] of Object.entries(this.error)) {
        this.error[key] = "";
      }
    },
    async formOnsubmit() {
      this.form.classroom_name = this.classroomName;
      this.form.classroom_id = this.classroomId;
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      // classrooms/{classroomId}/classroom-conversations
      let response = await module.submit(
        formData,
        `api/classrooms/${this.classroomId}/classroom-conversations/${this.classroomConversationId}`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.handleFormEdit();
        this.$root.$emit("refreshClassroomConversation")
      }
    },
  },
  mounted() {
      this.current_photo = this.form.image
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 230px !important;
  height: 230px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>