<template>
  <div class="animate__animated animate__fadeIn">
    <div class="classwork-lesson-form">
      <div id="kt_classwork_lesson_form">
        <div
          class="wizard wizard-1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Body-->
          <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
            <div class="col-xl-12">
              <!--begin: Wizard Form-->
              <form class="form" @submit.stop.prevent="classworkLessonAdd">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h5
                    class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg"
                  >
                    Edit Pelajaran
                  </h5>

                  <b-form-group
                    id="input-group-section-id"
                    label="Siswa:"
                    label-for="input-section-id"
                  >
                    <treeselect
                      v-model="classwork_to"
                      :multiple="true"
                      placeholder="Pilih Siswa"
                      :options="students"
                    />
                    <small class="text-danger">{{ error.classwork_to }}</small>
                  </b-form-group>

                  <b-form-group
                    id="input-group-section-id"
                    label="Seksi:"
                    label-for="input-section-id"
                  >
                    <treeselect
                      v-model="postClasswork.section_id"
                      :multiple="false"
                      placeholder="Pilih Seksi"
                      :options="sections"
                    />
                    <small class="text-danger">{{ error.section_id }}</small>
                  </b-form-group>

                  <b-form-group
                    id="input-group-trigger-modal-lesson"
                    label="Pelajaran:"
                    label-for="input-trigger-modal-lesson"
                  >
                    <b-form-input
                      id="input-trigger-modal-lesson"
                      v-model="display.lesson_title"
                      placeholder="Pilih Pelajaran"
                      readonly
                      @click="$bvModal.show('modal-lesson')"
                    >
                    </b-form-input>
                    <small class="text-danger">{{ error.lesson_id }}</small>
                  </b-form-group>

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div>
                      <b-button type="submit" variant="primary"
                        >Simpan</b-button
                      >
                      <b-button
                        type="button"
                        class="ml-2"
                        variant="default"
                        @click="handleContent"
                      >
                        Batal
                      </b-button>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
      </div>
    </div>
    <ModalLesson @chooseLesson="chooseLesson" :userData="user_data"/>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ApiService from "@/core/services/api.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ModalLesson from "@/view/components/general/ModalLesson"
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    postLesson: Object,
    postClasswork: Object,
    classroom_id: String,
  },
  components:{
    ModalLesson
  },
  data() {
    return {
      display:{
        lesson_title: ""
      },
      error: {
        section_id: "",
        lesson_id: "",
        classwork_to: "",
        classroom_id: "",
        classwork_id: "",
      },
      sections: [],
      lessons: [],
      participants: [],
      participantsData: [],
      fixedParticipants: [],
      students: [],
      classwork_to: [0],
      // other
      user_data: [],
      oldLessonId: 0
    };
  },
  methods: {
    chooseLesson(value){
      this.display.lesson_title = value.title
      this.postLesson.lesson_id = value.id
      //("post lesson", this.postLesson)
      this.$bvModal.hide('modal-lesson')
    },
    handleContent() {
      this.$emit("handleContent", "list");
    },
    async getSectionOption() {
      let response = await module.setTreeSelect(
        `api/classrooms/${this.classroom_id}/sections`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.sections = response.data;
        this.sections.unshift({
          label: "Pilih Seksi",
          id: "",
          isDisabled: true,
        });
      }
    },
    getStudentOption() {
      ApiService.get(
        "api/classrooms/" + this.classroom_id + "/participants"
      )
        .then((response) => {
          this.participants = response.data.data;
          this.participantsData = [];
          for (let a = 0; a < this.participants.length; a++) {
            this.fixedParticipants = response.data.data[a].children;
            if (response.data.data[a].role_id == 2) {
              for (let b = 0; b < this.fixedParticipants.length; b++) {
                this.participantsData.push(this.fixedParticipants[b]);
              }
            }
          }

          this.students = [
            {
              id: 0,
              label: "Semua siswa",
              children: this.participantsData.map((elem) => ({
                id: elem.participant_id,
                label: elem.user_name,
              })),
            },
          ];

          //("option student", this.students);
        })
        .catch((error) => {
          //(error);
        });
    },
    async getLessonOption() {
      let response = await module.setTreeSelectLesson("api/lessons");
      //("===LESSON");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.lessons = response.data;
        this.lessons.unshift({
          label: "Pilih Pelajaran",
          id: "",
          isDisabled: true,
        });
      }
    },
    async classworkLessonAdd() {
      // jika tugas ditujukan untuk semua siswa
      //("classwork_to", this.classwork_to);
      //("classwork_to[0]", this.classwork_to[0]);
      if (this.classwork_to[0] == 0) {
        this.postClasswork.classroom_id = parseInt(this.classroom_id);
        this.postClasswork.classwork_to = [];
        for (let a = 0; a < this.participantsData.length; a++) {
          this.postClasswork.classwork_to.push(
            this.participantsData[a].participant_id
          );
        }
        this.postClasswork.classwork_to = JSON.stringify(
          this.postClasswork.classwork_to
        );
        //("classwork", this.postClasswork);
        //("lesson", this.postLesson);
        // Make Request
        let response = await module.submit(
          this.postClasswork,
          "api/classrooms/" + this.classroom_id + "/classworks/" + this.postLesson.classwork_id
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
          //("this.error", this.error);
          //("response.data.errors", response.error.errors);
        } else {
          this.postLesson["_method"] = "put";
          this.postLesson.classwork_id = response.success;
          //("response woyy", response);
          let lesson = await module.submitWithoutAlert(
            this.postLesson,
            `api/classrooms/${this.classroom_id}/classworks/${response.success}/lessons/attach/${this.oldLessonId}`
          );

          // Check Response
          if (lesson.state == "error") {
            // Validation Error
            this.error = validation.setValidationError(
              this.error,
              lesson.error.errors
            );
          } else {
            // Success
            Swal.fire(
              lesson.success.title,
              lesson.success.message,
              "success"
            );

            this.$root.$emit("refreshClasswork")
            this.handleContent()
          }
        }
      }
      // jika tugas ditujukan untuk beberapa siswa
      else {
        //("classwork_to", this.classwork_to);
        //("classwork_to[0]", this.classwork_to[0]);
          this.postClasswork.classroom_id = parseInt(this.classroom_id);
          this.postClasswork.classwork_to = JSON.stringify(
            this.classwork_to
          );
          //("classwork", this.postClasswork);
          //("lesson", this.postLesson);
          // Make Request
          let response = await module.submit(
            this.postClasswork,
            "api/classrooms/" + this.classroom_id + "/classworks/" + this.postLesson.classwork_id
          );
          // Check Response
          if (response.state == "error") {
            // Validation Error
            this.error = validation.setValidationError(
              this.error,
              response.error.errors
            );
            //("this.error", this.error);
            //("response.data.errors", response.error.errors);
          } else {
            this.postLesson.classwork_id = response.success;
            //("response woyy", response);
            let lesson = await module.submitWithoutAlert(
              this.postLesson,
              `api/classrooms/${this.classroom_id}/classworks/${response.success}/lessons/attach/${this.oldLessonId}`
            );

            // Check Response
            if (lesson.state == "error") {
              // Validation Error
              this.error = validation.setValidationError(
                this.error,
                lesson.error.errors
              );
            } else {
              // Success
              Swal.fire(
                lesson.success.title,
                lesson.success.message,
                "success"
              );
              this.$root.$emit("refreshClasswork")
              this.handleContent()
            }
          }
      }
    },
  },
  mounted() {
    this.getSectionOption();
    this.getLessonOption();
    this.getStudentOption();
    this.user_data = getUser()

    console.log('post lesson', this.postLesson)
    this.display.lesson_title = this.postLesson.title
    this.oldLessonId = this.postLesson.lesson_id
  },
  watch: {
  },
};
</script>

<style>
</style>