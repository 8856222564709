<template>
  <div class="animate__animated animate__fadeIn">
    <div
      class="d-flex align-items-center justify-content-between mt-8 mb-10"
      style="height: 0px"
    >
      <div>
        <h3 class="d-block">{{ detail.classwork_type_name }}</h3>
        <span class="text-muted">{{
          detail.created_by_name + `(${detail.created_at_display})`
        }}</span>
      </div>
      <div>
        <!-- <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content
            ><b-icon-three-dots-vertical></b-icon-three-dots-vertical>
          </template>
          <b-dropdown-item>Edit</b-dropdown-item>
          <b-dropdown-item>Hapus</b-dropdown-item>
        </b-dropdown> -->
      </div>
    </div>
    <v-divider></v-divider>
    <div class="col-12 pt-0">
      <table class="table table-borderless mt-8">
        <tr>
          <th class="pr-1" width="100">
            <span class="font-weight-bold">Agenda</span>
          </th>
          <th class="pl-0 pr-0" width="10">
            <span class="font-weight-bold">:</span>
          </th>
          <td class="pl-2">
            <span class="">{{ detail.agenda }}</span>
          </td>
        </tr>
        <tr>
          <th class="pr-1" width="100">
            <span class="font-weight-bold">Mulai</span>
          </th>
          <th class="pl-0 pr-0" width="10">
            <span class="font-weight-bold">:</span>
          </th>
          <td class="pl-2">
            <span class="">{{ setDate(detail.start_date) }}</span>
          </td>
        </tr>
        <tr>
          <th class="pr-1" width="100">
            <span class="font-weight-bold">Selesai</span>
          </th>
          <th class="pl-0 pr-0" width="10">
            <span class="font-weight-bold">:</span>
          </th>
          <td class="pl-2">
            <span class="">{{ setDate(detail.end_date) }}</span>
          </td>
        </tr>
        <tr>
          <th class="pr-1" width="100">
            <span class="font-weight-bold">Platform</span>
          </th>
          <th class="pl-0 pr-0" width="10">
            <span class="font-weight-bold">:</span>
          </th>
          <td class="pl-2">
            <span class="">{{ detail.meeting_platform_name }}</span>
          </td>
        </tr>
        <tr>
          <th class="pr-1" width="100">
            <span class="font-weight-bold">Tautan</span>
          </th>
          <th class="pl-0 pr-0" width="10">
            <span class="font-weight-bold">:</span>
          </th>
          <td class="pl-2">
            <span class=""><a :href="detail.meeting_url" target="_blank">{{ detail.meeting_url }}</a></span>
          </td>
        </tr>
        <tr>
          <th class="pr-1" width="100">
            <span class="font-weight-bold">Password</span>
          </th>
          <th class="pl-0 pr-0" width="10">
            <span class="font-weight-bold">:</span>
          </th>
          <td class="pl-2">
            <span class="">{{ detail.password }}</span>
          </td>
        </tr>
        <tr>
          <th class="pr-1" width="100">
            <span class="font-weight-bold">Seksi</span>
          </th>
          <th class="pl-0 pr-0" width="10">
            <span class="font-weight-bold">:</span>
          </th>
          <td class="pl-2">
            <span class="">{{ detail.section_name }}</span>
          </td>
        </tr>
      </table>
    </div>
    <v-divider></v-divider>

    <b-button
      type="button"
      class="ml-2"
      variant="primary"
      @click="handleContent"
    >
      Tutup
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object,
  },
  data() {
    return {};
  },
  methods: {
    handleContent() {
      this.$emit("handleContent", "list");
    },
    setDate(time){
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      let localdate = new Date(time).toLocaleDateString("id-ID", options)
      let localtime = new Date(time).toTimeString().substr(0, 8)
      return localdate + " " + localtime
    }
  },
  mounted() {},
  watch: {
    detail: function (newVal, oldVal) {
      // watch it
      //("detail classwork changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 60px !important;
  height: 100px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper:hover {
  opacity: 0.8;
}

.card-attach-image:hover {
  opacity: 0.8;
  color: #1bc5bd !important;
}

.text-dark:hover {
  color: #1bc5bd !important;
}
</style>