<template>
  <div class="animate__animated animate__fadeIn">
    <template v-if="content == 'detail'">
      <b-card no-body>
        <b-card-body class="pb-4">
          <b-card-title>{{ detail.name }}</b-card-title>

          <div class="d-flex align-items-center">
            <div>
              <b-img
                width="43"
                :src="detail.created_by_photo"
                rounded="circle"
                alt="Circle image"
              ></b-img>
            </div>
            <div class="ml-2">
              <span class="text-dark d-block">{{
                detail.created_by_name
              }}</span>
              <span class="text-muted"
                ><MomentAgo
                  prefix="di buat"
                  suffix="yang lalu"
                  :date="detail.created_at"
                  lang="id"
              /></span>
            </div>
          </div>

          <b-card-text class="mt-10">{{ detail.description }}</b-card-text>

          <a :href="detail.image" download="file" target="_blank">
            <img
              :src="detail.image"
              class="conversation-image"
              width="150"
              alt=""
            />
          </a>

          <div class="d-flex bd-highlight mt-10">
            <div class="p-2 bd-highlight flex-grow-1">
              <div class="d-flex align-items-center">
                <div>
                  <span v-if="comments.length == 0">Belum ada tanggapan</span>
                  <span v-if="comments.length != 0"
                    >{{ comments.length }} tanggapan</span
                  >
                </div>
                <div class="ml-4">
                  <b-button
                    variant="primary"
                    squared
                    @click="scrollToForm({ behavior: 'smooth' })"
                  >
                    <i class="fas fa-reply"></i>Balas
                  </b-button>
                </div>
              </div>
            </div>
            <div
              class="p-2 bd-highlight mt-4"
              v-if="user_data.id == detail.created_by"
            >
              <i
                style="cursor: pointer"
                class="fas fa-pencil-alt btn-conversation"
                @click="handleContentUpdate"
              ></i>
            </div>
            <div
              class="p-2 bd-highlight mt-4"
              v-if="user_data.id == detail.created_by"
            >
              <i
                style="cursor: pointer"
                class="fas fa-trash-alt btn-conversation"
                @click="deleteData(detail.id)"
              ></i>
            </div>
          </div>
        </b-card-body>
      </b-card>

      <div class="container">
        <div class="row" v-for="comment in comments" :key="comment.id">
          <div class="col-10">
            <b-card title="Card Title" no-body>
              <b-card-body class="p-4">
                <div class="d-flex align-items-center">
                  <div>
                    <b-img
                      width="43"
                      :src="comment.created_by_photo"
                      rounded="circle"
                    ></b-img>
                  </div>
                  <div class="ml-2">
                    <span class="text-dark d-block">{{
                      comment.created_by_name
                    }}</span>
                    <span class="text-muted"
                      ><MomentAgo
                        prefix="di buat"
                        suffix="yang lalu"
                        :date="comment.created_at"
                        lang="id"
                    /></span>
                  </div>
                </div>
                <b-card-text class="mt-5 comment-message" v-html="comment.message">
                </b-card-text>
                <div class="d-flex justify-content-end">
                  <div class="mr-3" v-if="user_data.id == comment.created_by">
                    <i
                      style="cursor: pointer"
                      class="fas fa-pencil-alt btn-conversation"
                      @click="handleCommentUpdate(comment.id)"
                    ></i>
                  </div>
                  <div v-if="user_data.id == comment.created_by">
                    <i
                      style="cursor: pointer"
                      class="fas fa-trash-alt btn-conversation"
                      @click="deleteComment(comment.id)"
                    ></i>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </div>

      <!-- <div class="container pb-0"> -->
        <div class="row justify-content-center">
          <div class="col-md-10 pb-0">
            <form @submit.stop.prevent="formOnsubmit()">
              <b-form-group id="input-group-description" ref="juga">
                <div class="editor1">
                <Vueditor v-model="postConversation.message" ref="testinggg"></Vueditor>
                </div>
              </b-form-group>
              <b-button type="submit" variant="primary">Simpan</b-button>
              <b-button
                type="button"
                class="ml-2"
                variant="default"
                @click="handleContent('list')"
              >
                Tutup
              </b-button>
            </form>
          </div>
        </div>
      <!-- </div> -->
    </template>

    <template v-if="content == 'conversation-edit'">
      <FormEdit
        @handleFormEdit="handleFormEdit"
        :classroomName="classroomName"
        :classroomId="classroomId"
        :classroomConversationId="detail.id"
        :form="detail"
      />
    </template>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import FormEdit from "@/view/components/classroom/classroom-conversations/FormEdit.vue";
import MomentAgo from "@/view/components/general/MomentAgo.vue";
import { getUser, setUser } from "@/core/services/jwt.service.js";
// import plugin from "../../../../core/plugins/emoji/plugin.js"
// import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
// import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
// import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';

export default {
  props: {
    detail: Object,
    classroomName: String,
    classroomId: String,
    comments: Array,
  },
  components: {
    MomentAgo,
    FormEdit,
  },
  data() {
    return {
      content: "detail",
      postConversation: {
        classroom_conversation_id: "",
        user_id: "",
        message: "",
        message_to: "",
        purpose: "add",
      },
      error: {
        classroom_conversation_id: "",
        user_id: "",
        message: "",
        message_to: "",
      },
      user_data: [],
      route: "",
      // ckeditor
      // editor: DecoupledEditor,
      editorConfig: {
        toolbar: [["Bold", "Italic", "Underline"], ["emoji"], ["Strike"]],
        extraPlugins: 'emoji',
        // toolbar: [
        //   "fontColor",
        //   "|",
        //   "bold",
        //   "italic",
        //   "underline",
        //   "strikethrough",
        //   "|",
        //   "link",
        //   "blockQuote",
        //   "bulletedList",
        //   "numberedList",
        //   "|",
        //   "specialCharacters",
        //   "|",
        //   "undo",
        //   "redo",
        // ],
        // table: {
        //   toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        // },
      },
    };
  },
  methods: {
    getRef(){
      //("ref")
      //(this.$refs)
// class="ve-design"

    let el = this.$el.getElementsByClassName("ve-design");
      //("el", el);
      //("tembak", el[0].children[0].contentDocument.body.innerHTML);
      this.postConversation.message = el[0].children[0].contentDocument.body.innerHTML
      //("post", this.postConversation);
    },
    onNamespaceLoaded(CKEDITOR) {
      // Add external `placeholder` plugin which will be available for each
      // editor instance on the page.
      let route = `/../../../../core/plugins/emoji/`
      //("route", route)

      //("inii=========", CKEDITOR)
      CKEDITOR.plugins.addExternal( 'emoji', '/@/core/plugins/emoji', 'plugin.js' );
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    handleContent() {
      this.$emit("handleContent", "list");
    },
    handleContentUpdate() {
      this.content = "conversation-edit";
    },
    handleFormEdit() {
      this.content = "detail";
    },
    async handleCommentUpdate(id) {
      //("ini jalan hee");

      // classrooms/{classroomId}/conversations/{conversationId}
      this.postConversation = await module.get(
        `api/classrooms/${this.classroomId}/conversations/${id}`
      );
      // If Data Not Found
      if (this.postConversation == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.postConversation["_method"] = "put";
        this.postConversation.purpose = "edit";

        let el = this.$el.getElementsByClassName("ve-design");
        el[0].children[0].contentDocument.body.innerHTML = this.postConversation.message
        //("message = ", el[0].children[0].contentDocument.body.innerHTML);


        this.scrollToForm({ behavior: "smooth" });
      }
    },
    scrollToForm(options) {
      // window.scrollTo(0, 0);
      let el = this.$el.getElementsByClassName("bv-no-focus-ring")[0];
      //("el", el);
      if (el) {
        el.scrollIntoView(options);
      }
    },
    async deleteData(id) {
      // Delete Data
      let result = await module.delete(
        `classrooms/${this.classroomId}/conversations/${id}`
      );
      // If Deleted
      if (result) {
        this.pagination();
      }
    },
    async deleteComment(id) {
      // Delete Data
      // classrooms/{classroomId}/conversations/{conversationId}
      let result = await module.deleteComment(
        `api/classrooms/${this.classroomId}/conversations/${id}`
      );
      // If Deleted
      if (result) {
        //("resultnya", result);
        this.$emit(
          "refreshConversation",
          result.data.item.original.classroom_conversation_id
        );
      }
    },
    resetErrorOnNew() {
      for (const [key, value] of Object.entries(this.error)) {
        this.error[key] = "";
      }
    },
    resetPostConversationOnNew() {
      for (const [key, value] of Object.entries(this.postConversation)) {
        this.postConversation[key] = "";
      }
    },
    async formOnsubmit() {
      let el = this.$el.getElementsByClassName("ve-design");
      //("message = ", el[0].children[0].contentDocument.body.innerHTML);
      this.postConversation.message = el[0].children[0].contentDocument.body.innerHTML
      // itu mungkin kamu menulisnya if(a = b), seharusnya menggunakan dua tanda ==. Jadi if(a == b). ini mungkin loh ya, bisa iya bisa tidak
      this.postConversation.classroom_conversation_id = this.detail.id;
      this.postConversation.user_id = this.user_data.id;
      this.postConversation.message_to = "-1";
      //("post", this.postConversation);

      if (this.postConversation.purpose == "add") {
        //("route tambah");
        this.route = `api/classrooms/${this.classroomId}/conversations`;
      } else {
        //("route ubah");
        this.route = `api/classrooms/${this.classroomId}/conversations/${this.postConversation.id}`;
      }
      // Make Request
      let response = await module.submit(this.postConversation, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.resetErrorOnNew();
        this.resetPostConversationOnNew();
        this.$emit(
          "refreshConversation",
          response.success.item.original.classroom_conversation_id
        );
        this.postConversation.purpose = "add";

        let el = this.$el.getElementsByClassName("ve-design");
        el[0].children[0].contentDocument.body.innerHTML = ""
      }
    },
  },
  mounted() {
    this.user_data = getUser();
  },
  watch: {
    detail: function (newVal, oldVal) {
      // watch it
      //("detail changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>

<style>
.btn-conversation:hover {
  color: #1bc5bd;
}
.conversation-image:hover {
  opacity: 0.5;
}

table .btn {
  margin-right: 5px;
}

.vueditor{
  height: 250px !important;
}

.comment-message img{
  height: 20px !important;
}

.ve-design img{
  height: 20px !important;
}

iframe img{
  height: 20px !important;
}
</style>