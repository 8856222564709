<template>
  <div class="animate__animated animate__fadeIn">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center my-3 px-8" v-if="content == 'list'">
        <div>
          <div class="alert-icon d-inline">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text d-inline">Daftar <b>Diskusi</b> di Kelas ini</div>
        </div>
        <div>
          <b-button
          squared
          variant="primary"
          @click="handleContent('classroom-conversation-add')"
          v-if="btn"
          >Buat Diskusi</b-button>
        </div>
      </div>
      <!-- <v-divider v-if="content == 'list'"></v-divider> -->
      <template v-if="content == 'list'">
        <div class="row animate__animated animate__fadeIn">
          <div class="col-md-4" v-for="conversation in classroomConversation" :key="conversation.id">
            <v-card class="mx-auto" max-width="344" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">{{conversation.created_by_name}}</div>
                  <v-list-item-title class="headline mb-1">{{conversation.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{conversation.description}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn text @click="handleContent('detail-conversation', conversation.id)">Lihat</v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </template>

      <template v-if="content == 'classroom-conversation-add'">
        <Form
          @handleContent="handleContent"
          :classroomName="classroomName"
          :classroomId="classroomId"
        />
      </template>

      <template v-if="content == 'detail-conversation'">
        <Detail
          @handleContent="handleContent"
          :detail="detail"
          :classroomName="classroomName"
          :classroomId="classroomId"
          :comments="comments"
          @refreshConversation="getConversation"
        />
      </template>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import Form from "@/view/components/classroom/classroom-conversations/Form.vue";
import Detail from "@/view/components/classroom/classroom-conversations/Detail.vue";

export default {
  props: {
    classroomName: String,
    classroomId: String,
    classroomConversation: Array
  },
  components: {
    Form,
    Detail,
  },
  data() {
    return {
      content: "list",
      detail: [],
      comments: [],
      btn: false
    };
  },
  methods: {
    handleContent(content, conversationId) {
      if(content == "detail-conversation"){
        this.getDetail(conversationId)
      }else{
        this.content = content;
      }
    },
    async getDetail(conversationId) {
      // classrooms/{classroomId}/classroom-conversations/{classroomConversationId}
      this.detail = await module.get(`api/classrooms/${this.classroomId}/classroom-conversations/${conversationId}`)
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.content = "list"
      } else {
        this.getConversation(this.detail.id)
        this.detail['_method'] = 'put'
      }
    },
    async getConversation(id) {
      // classrooms/{classroomId}/conversations/{classroomConversationId}
      this.comments = await module.get(`api/classrooms/${this.classroomId}/conversations/classroom-conversations/${id}`)
      // If Data Not Found
      if (this.comments == null) {
        this.content = "detail-conversation"
      } else {
        this.comments['_method'] = 'put'
        this.content = "detail-conversation"
      }
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1008") {
          this.btn = true
        }
      }
    },
  },
  mounted(){
    this.checkAccess()
  },
  watch:{
    classroomConversation: function (newVal, oldVal) {
      // watch it
      // //("classroomConversation changed: ", newVal, " | was: ", oldVal);
    },
  }
};
</script>

<style scoped>
</style>