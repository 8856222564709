<template>
<v-app>
  <div class="animate__animated animate__fadeIn">
    <div :class="purpose !== 'class-groups' ? 'container' : ''">
      <template v-if="content == 'list'">
      <div
        class="d-flex justify-content-start align-items-center my-3 px-8"
        v-if="user_data.role_id == 1 && purpose != 'class-groups'"
      >
        <div>
          <b-button
            squared
            variant="success"
            @click.stop.prevent="openPresence"
            v-if="classroomScheduleToday != null && classroomScheduleToday.is_open_presence != 1"
            >Buka Lembar Kehadiran</b-button
          >
          <b-button
            squared
            variant="success"
            @click.stop.prevent="openPresence"
            v-if="classroomScheduleToday != null && classroomScheduleToday.is_open_presence == 1"
            >Tutup Lembar Kehadiran</b-button
          >
        </div>
      </div>
      <b-alert
        show
        variant="light"
        class="
          alert alert-custom alert-white alert-shadow
          fade
          show
          gutter-b
          py-2
        "
        v-if="
          capture_status == false &&
          is_open_presence == true &&
          presence_today_status == false &&
          user_data.role_id == 2 &&
          confirmPresence == true
        "
      >
        <div class="col-md-2 p-0 mr-4">
          <vue-web-cam
            ref="webcam"
            :device-id="deviceId"
            width="100%"
            height="100%"
            @started="onStarted"
            @stopped="onStopped"
            @error="onError"
            @cameras="onCameras"
            @camera-change="onCameraChange"
            v-if="capture_status == false"
          />
          <img
            :src="baseImg"
            height="100%"
            width="100%"
            v-if="capture_status"
          />
        </div>
        <div class="alert-text" v-if="presence_today_status == false">
          <b>{{ date }}, {{ time }}</b>
        </div>
        <div class="mt-md-9 mt-sm-4" v-if="presence_today_status == true">
          <span class="text-h5"
            >Kamu sudah melakukan presensi hari ini pada {{ absentTime }}</span
          >
        </div>
        <b-button
          squared
          variant="primary"
          class="h-50 mb-auto mt-auto mr-2"
          @click.stop.prevent="formOnsubmitPresence"
          v-if="capture_status == false"
          ><i class="fas fa-camera"></i> Hadir</b-button
        >
        <b-button
          squared
          variant="danger"
          class="h-50 mb-auto mt-auto"
          @click.stop.prevent="showModalAbsent"
          v-if="capture_status == false"
          >Izin tidak hadir</b-button
        >
      </b-alert>
      <v-alert
        prominent
        type="info"
        color="#1BC5BD"
        v-if="presence_today_status == true && is_presence == 2"
      >
        <v-row align="center">
          <v-col class="grow">
            Kamu telah berhasil melakukan presensi hari ini pada
            {{ absentTime }}
          </v-col>
          <!-- <v-col class="shrink">
            <v-btn
              @click="handleOpenDetail(presence_today)"
              >Detail</v-btn
            >
          </v-col> -->
        </v-row>
      </v-alert>
      <v-alert
        prominent
        type="info"
        color="info"
        v-if="presence_today_status == true && is_presence == 1"
      >
        <v-row align="center">
          <v-col class="grow"> Kamu izin untuk tidak sekolah hari ini </v-col>
          <!-- <v-col class="shrink">
            <v-btn
              @click="$router.push(`/presence/detail/${presence_today.id}`)"
              >Detail</v-btn
            >
          </v-col> -->
        </v-row>
      </v-alert>

      <!-- filter -->
              <div class="row justify-content-end">
                <!-- <div class="col-md-3">
                  <b-form-group
                    id="input-group-class-groups"
                    label-for="input-class-groups"
                  >
                    <treeselect
                      v-model="filter.class_group_id"
                      :multiple="false"
                      placeholder="Pilih Kelompok Kelas"
                      :options="class_groups"
                      @input="filterByClassGroup"
                    />
                  </b-form-group>
                </div> -->
                <div :class="purpose !== 'class-groups' ? 'col-md-3 mb-1' : 'col-md-3 pt-0'" v-if="orderTabActive !== 0">
                  <b-form-select
                    v-model="filter.month"
                    :options="monthOption"
                    @input="filterByMonth"
                  ></b-form-select>
                </div>
                <div :class="purpose !== 'class-groups' ? 'col-md-3 mb-1' : 'col-md-3 pt-0'" v-if="purpose == 'class-groups'">
                    <treeselect
                      v-model="filter.classroom_id"
                      :multiple="false"
                      placeholder="Pilih Ruang Kelas"
                      :options="classrooms"
                      @input="filterByClassroom"
                    />
                </div>
                <!-- <div class="col-md-3">
                  <b-form-input
                    id="filter-input"
                    v-model="search"
                    type="search"
                    placeholder="Cari nama siswa"
                    @keyup="filterByName"
                    v-if="userData.role_id == 1"
                  ></b-form-input>
                </div> -->
              </div>
      <div class="row mt-7 my-10" v-if="orderTabActive == 0"></div>
      <b-tabs content-class="mt-3" @input="inputTabs">
        <b-tab title="Rekap Hari ini" active>
          <TablePresenceToday :classroomId="classroomId" :participants="participants" :purpose="purpose"/>
        </b-tab>
        <b-tab title="Rekap Absen">
          <Table :classroomId="classroomId" :participants="participants" :purpose="purpose"/>
        </b-tab>
        <b-tab title="Kalender" v-if="user_data.role_id == 2">
          <Calendar :classroomId="classroomId" v-if="purpose != 'class-groups'" />
          <Calendar :classroomId="filter.classroom_id" v-if="purpose == 'class-groups'" />
        </b-tab>
      </b-tabs>
      </template>
      <template v-if="content == 'detail'">
        <Detail :detailPresence="detail" @handleCloseDetail="handleCloseDetail"/>
      </template>
    </div>
    <ModalAbsent
      @cancelAbsent="$bvModal.hide('modal-absent')"
      @successAbsent="successAbsent"
      :classroomId="classroomId"
      :purpose="purposeAbsent"
      :route="routeAbsent"
      :data="update_absent"
      secondPurpose="PresenceClassroom"
    />
  </div>
</v-app>
</template>

<script>
import { getUser, setUser } from "@/core/services/jwt.service.js";
import { WebCam } from "vue-web-cam";
import module from "@/core/modules/CrudModule.js";
import ModalAbsent from "@/view/components/general/ModalAbsent.vue";
import Table from "@/view/components/classroom/presences/Table.vue";
import TablePresenceToday from "@/view/components/classroom/presences/TablePresenceToday.vue";
import Calendar from "@/view/components/classroom/presences/Calendar.vue";
import Detail from "@/view/components/classroom/presences/Detail.vue";

export default {
  props: {
    classroomId: String,
    classroomSchedule: Array,
    classroomScheduleToday: Object,
    participants: Array,
    purpose: String
  },
  components: {
    "vue-web-cam": WebCam,
    ModalAbsent,
    Table,
    TablePresenceToday,
    Calendar,
    Detail,
  },
  data() {
    return {
      orderTabActive: 0,
      // content
      content: 'list',
      // camera
      camera: null,
      deviceId: null,
      devices: [],
      img: null,
      baseImg: null,
      content: "list",
      // filter
      filter: {
        school_year_id: "",
        now: new Date().toISOString().substr(0, 10),
        month:
          new Date().toISOString().substr(0, 4) +
          "-0" +
          (new Date().getMonth() + 1),
        start_periode: "",
        class_group_id: '',
        classroom_id: ''
      },
      // form
      form: {
        student_id: getUser().id,
        classroom_id: '',
        presence_type_id: 0,
        date: "",
        photo: "",
        late: "",
        student_name: getUser().name,
        is_presence: 2,
      },
      // date&time
      time: "",
      date: "",
      updateTimeStatus: true,
      yearOption: new Date().toISOString().substr(0, 4),
      monthOption: [{ value: "-1", text: "Semua Bulan" }],
      monthOptionForm: {
        value: "",
        text: "",
      },
      months: [],
      // route
      routeAbsent: 'api/student-presences',
      purposeAbsent: 'add',
      update_absent: {},
      // other
      presence_types: [],
      user_data: [],
      capture_status: false,
      is_open_presence: false,
      presence_today_status: false,
      presence_today: [],
      is_presence: "",
      detail: {},
      // class-groups
      classrooms: [],
      // access
      confirmPresence: false,
    };
  },
  methods: {
    inputTabs(evt){
      this.orderTabActive = evt
    },
    handleOpenDetail(item){
      this.detail = item
      this.content = 'detail'
    },
    handleCloseDetail(){
      this.content = 'list'
    },
    setData() {
      if(this.classroomScheduleToday != null){
        if (this.classroomScheduleToday.is_open_presence == 1) {
          this.is_open_presence = true;
        }
      }
    },
    async getSChoolYearActive() {
      let response = await module.paginate("api/school-years/month");

      this.months = response.data[0].months;
      for (let a = 0; a < this.months.length; a++) {
        this.monthOptionForm.value =
          this.months[a].year + "-" + this.months[a].order_month_natural;
        if (this.months[a].order_month == "1") {
          this.monthOptionForm.text = "Januari " + this.months[a].year;
        }
        if (this.months[a].order_month == "2") {
          this.monthOptionForm.text = "Februari " + this.months[a].year;
        }
        if (this.months[a].order_month == "3") {
          this.monthOptionForm.text = "Maret " + this.months[a].year;
        }
        if (this.months[a].order_month == "4") {
          this.monthOptionForm.text = "April " + this.months[a].year;
        }
        if (this.months[a].order_month == "5") {
          this.monthOptionForm.text = "Mei " + this.months[a].year;
        }
        if (this.months[a].order_month == "6") {
          this.monthOptionForm.text = "Juni " + this.months[a].year;
        }
        if (this.months[a].order_month == "7") {
          this.monthOptionForm.text = "Juli " + this.months[a].year;
        }
        if (this.months[a].order_month == "8") {
          this.monthOptionForm.text = "Agustus " + this.months[a].year;
        }
        if (this.months[a].order_month == "9") {
          this.monthOptionForm.text = "September " + this.months[a].year;
        }
        if (this.months[a].order_month == "10") {
          this.monthOptionForm.text = "Oktober " + this.months[a].year;
        }
        if (this.months[a].order_month == "11") {
          this.monthOptionForm.text = "November " + this.months[a].year;
        }
        if (this.months[a].order_month == "12") {
          this.monthOptionForm.text = "Desember " + this.months[a].year;
        }
        let clone = { ...this.monthOptionForm };
        this.monthOption.push(clone);
      }
    },
    filterByMonth(evt) {
      this.filter.month = evt;
      this.filter.start_periode = this.months[0].date;
      this.$root.$emit("filterPresenceByMonth", this.filter);
    },
    async checkPresenceToday() {
      let currentTime = new Date().toISOString().substring(0, 10);
      let filterParams = `presence_today=${currentTime}&student_id=${getUser().id}&classroom_id=${this.classroomId}`;
      let response = await module.paginate(
        `api/student-presences`,
        `?${filterParams}`
      );

      if (response.data.length > 0) {
        this.presence_today_status = true;
        this.presence_today = response.data[0];
        this.baseImg = response.data[0].photo;
        this.capture_status = true;
        this.absentTime = response.data[0].date.split(" ")[1];
        this.is_presence = response.data[0].is_presence;
      }
    },

   async openPresence() {
      this.classroomScheduleToday['_method'] = 'put'
      // this.classroomScheduleToday.is_open_presence = 1
      // Make Request
      let response = await module.submit(
        this.classroomScheduleToday, 
        `api/classrooms/${this.classroomId}/classroom-schedules/${this.classroomScheduleToday.id}/open-presence`
        );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        // this.error = validation.setValidationError(
        //   this.error,
        //   response.error.errors
        // );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$root.$emit('refreshClassroomSchedule')
      }
    },
    diff(start, end) {
      start = start.split(":");
      end = end.split(":");
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);

      let convertHourToMinute = 60 * hours + minutes;

      return convertHourToMinute;

      // If using time pickers with 24 hours format, add the below line get exact hours
      // if (hours < 0)
      //    hours = hours + 24;

      // return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
    },

    // form submit
    async formOnsubmitPresence() {
      // stop time
      this.updateTimeStatus = false;
      await this.onCapture();
      // let currentTime = new Date().toTimeString();

      this.form.photo = this.dataURItoBlob(this.baseImg);
      this.form.late = 0;
      this.form.is_presence = 2;
      this.form.classroom_id = this.classroomId

      let mergeTime = this.date + " " + this.time;
      let currentDate = new Date().toISOString().substr(0, 10);
      let currentTime = new Date().toTimeString().substr(0, 8);
      this.form.date = currentDate + " " + currentTime;

      // if(currentTime >= this.presence_type_display.open_time && currentTime <= this.presence_type_display.close_time){
      //   // this.form.presence_type_id = this.form.presence_type_id
      //   this.form.late = 0
      //   //("betul")
      // }else{
      //   // this.form.presence_type_id = this.form.presence_type_id
      //   this.form.late = this.diff(this.presence_type_display.close_time, currentTime)
      //   //("salah disini")
      // }

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      // classrooms/{classroomId}/student-presences
      let response = await module.submit(formData, `api/student-presences`);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        // this.error = validation.setValidationError(
        //   this.error,
        //   response.error.errors
        // );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.presence_today_status = true;
        this.absentTime = response.success.item.original.date.split(" ")[1];
        this.presence_today = response.success.item.original;
        this.$root.$emit("refreshPresenceCalendar");
        this.is_presence = 2;
      }

    },

    showModalAbsent() {
      this.$bvModal.show("modal-absent");
    },
    successAbsent(response) {
      this.presence_today_status = true;
      this.absentTime = response.success.item.original.date.split(" ")[1];
      this.presence_today = response.success.item.original;
      this.is_presence = 1;
      this.$root.$emit("refreshPresenceCalendar");
      this.$bvModal.hide("modal-absent");
      this.routeAbsent = 'api/student-presences'
    },

    // convert base64 to image
    dataURItoBlob(dataURI) {
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mimeString,
      });
    },

    // set date&time
    setTime() {
      let ct = new Date();
      this.time =
        this.zeroPadding(ct.getHours(), 2) +
        ":" +
        this.zeroPadding(ct.getMinutes(), 2) +
        ":" +
        this.zeroPadding(ct.getSeconds(), 2);
    },

    setDate() {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      this.date = new Date().toLocaleDateString("id-ID", options);
    },

    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    updateTime() {
      if (this.updateTimeStatus == true) {
        setTimeout(() => {
          if (this.updateTimeStatus == true) {
            this.setTime();
            this.updateTime();
          }
        }, 1000);
      }
    },

    // camera method
    onCapture() {
      this.baseImg = this.$refs.webcam.capture();
      this.img = this.dataURItoBlob(this.baseImg);
      this.form.photo = this.dataURItoBlob(this.baseImg);
      this.capture_status = true;
    },
    onStarted(stream) {
      //("On Started Event", stream);
    },
    onStopped(stream) {
      //("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      //("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      //("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      //("On Camera Change Event", deviceId);
    },

    // class groups
    async getClassroomOption() {
      let response = await module.setTreeSelect(`api/classrooms/my-classrooms/${getUser().id}`);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.classrooms = response.data;
        this.classrooms.unshift({
          label: "Pilih Ruang Kelas",
          id: "",
          isDisabled: true,
        });
      }
    },
   async filterByClassroom(evt){
      if (!evt) {
        this.filter.classroom_id = await "";
        this.$root.$emit("filterPresenceByClassroom", this.filter);
        this.optionsName = []
        // this.getMyStudent()
      } else {
        this.filter.classroom_id = await evt;
        this.$root.$emit("filterPresenceByClassroom", this.filter);
        this.optionsName = []
        // this.getMyStudent()
      }
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1012") {
          this.confirmPresence = true
        }
      }
    },
  },
  mounted() {
    this.user_data = getUser();
    this.updateTime();
    this.setDate();
    this.checkPresenceToday();
    this.getSChoolYearActive();
    this.checkAccess()

    this.$root.$on("handleDetailPresenceClassroom", (item) => {
      this.detail = item
      this.content = 'detail'
    });

    this.$root.$on("handleUpdateAbsentClassroom", (presence) => {
      // this.detail = item
      this.update_absent = presence
      this.purposeAbsent='edit'
      this.routeAbsent = `api/student-presences/${presence.id}`
      this.$bvModal.show('modal-absent')
    });

    this.getClassroomOption()
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
    classroomScheduleToday: function (newVal, oldVal) {
      // watch it
      this.setData()
    },
  },
};
</script>

<style scoped>
</style>