<template>
  <div>
    <b-modal
      id="modal-quiz"
      size="xl"
      title="Daftar Kuis"
      body-class="pt-0"
      hide-footer
    >
      <b-tabs>
        <b-tab title="Daftar Kuis Saya" active>
          <div class="row">
            <div class="col-md-12">
              <Table
                @chooseQuiz="chooseQuiz"
                @handleDetailModal="handleContentDetail"
                purpose="modal-quiz"
              />
            </div>
          </div>
        </b-tab>
        <b-tab title="Daftar Kuis Sekolah">
          <div class="row">
            <div class="col-md-3">
              <b-form-select
                v-model="selected"
                @input="handleCourseActv"
                :options="options"
                size="sm"
                class="mt-3"
              ></b-form-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <TableCurriculum
                purpose="modal-quiz"
                :userData="userData"
                :course="course_actv"
                @chooseQuiz="chooseQuiz"
                @handleDetailModal="handleContentDetail"
              />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
    <ModalQuizDetail :detail="detail" @handleContent="handleContent" />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import Table from "@/view/components/materials/quizzes/Table.vue";
import TableCurriculum from "@/view/components/courses/quizzes/Table.vue";
import Detail from "@/view/components/materials/quizzes/Detail.vue";
import ModalQuizDetail from "@/view/components/general/ModalQuizDetail.vue";

export default {
  props: {
    userData: Object,
  },
  components: {
    Table,
    TableCurriculum,
    Detail,
    ModalQuizDetail,
  },
  data() {
    return {
      content: "list",
      perPage: 20,
      totalRows: 1,
      currentPage: 1,
      detail: {},
      courses: [],
      course_actv: {},
      selected: "",
      options: [],
      options_form: {
        value: "",
        text: "",
      },
    };
  },
  methods: {
    handleContent() {
      this.$bvModal.hide("modal-quiz-detail");
    },
    chooseQuiz(value) {
      this.$emit("chooseQuiz", value);
    },
    async handleContentDetail(id) {
      this.detail = await module.get("api/quizzes/" + id);
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.$bvModal.show("modal-quiz-detail");

        if (this.detail.questions.length != 0) {
          let a, b;
          b = this.perPage * (this.currentPage - 1) + 1;
          for (a = 0; a < this.detail.questions.length; a++) {
            this.detail.questions[a].number = b + a;
          }
        }

        if (this.detail.questions.length != 0) {
          for (let c = 0; c < this.detail.questions.length; c++) {
            if (this.detail.questions[c].answer_choices != "") {
              this.detail.questions[c].answer_choices_display = JSON.parse(
                this.detail.questions[c].answer_choices
              );
              // set jawaban multiple choices
              if (this.detail.questions[c].question_type_id == 5) {
                let answer_display = JSON.parse(
                  this.detail.questions[c].answer
                );
                this.detail.questions[c].answer_display = answer_display;
                //("new detail", this.detail);
                // set true
                for (
                  let d = 0;
                  d < this.detail.questions[c].answer_choices_display.length;
                  d++
                ) {
                  for (
                    let e = 0;
                    e < this.detail.questions[c].answer_display.length;
                    e++
                  ) {
                    if (
                      this.detail.questions[c].answer_choices_display[d]
                        .value == this.detail.questions[c].answer_display[e]
                    ) {
                      this.detail.questions[c].answer_choices_display[
                        d
                      ].checked = true;
                    }
                  }
                }
              }
            }
          }
        }
        //("detail", this.detail);
      }
    },
    async getCourse() {
      let currentPage = "";
      let perPage = "";
      // let filterParams = `&name=${this.filter.name}&category_id=${this.filter.category_id}&collection_id=${this.filter.collection_id}`
      let response = await module.paginate(
        `api/courses`,
        `?page=${currentPage}&page_size=${perPage}`
      );

      this.courses = response.data;

      for (let a = 0; a < this.courses.length; a++) {
        this.options_form.value = this.courses[a].id;
        this.options_form.text = this.courses[a].name;

        let clone = { ...this.options_form };
        this.options.push(clone);
      }

      //("course", this.courses);
      //("options", this.options);
      this.course_actv = await module.get('api/curriculums/' + this.courses[0].id)
      this.selected = this.courses[0].id
      //("course acth", this.course_actv);
    },
    async handleCourseActv(evt) {
      this.course_actv = await module.get('api/curriculums/' + evt)
      //("course actv", this.course_actv)
    },
  },
  mounted() {
    this.getCourse();
  },
};
</script>

<style>
</style>