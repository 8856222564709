<template>
  <div>
    <div class="container">
      <div class="row animate__animated animate__fadeIn">
        <div class="col-12">
          <!-- Empty -->
          <!-- <template
            v-if="
              students.length == 0 && teachers.length == 0
            "
          >
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-img></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title></v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-btn
                    icon
                    v-b-tooltip.hover.top="'Tambah Peserta'"
                    @click="showModalUser(2)"
                  >
                    <i class="fas fa-user-plus"></i>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>

              <v-divider style="color: #1bc5bd"></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template> -->
          <!-- Teacher -->
          <template>
            <div
              class="d-flex align-items-center justify-content-between mt-5"
              style="height: 0px"
            >
              <h4>Guru</h4>
              <i
                class="fas fa-user-plus"
                @click="showModalUser(1)"
                v-b-tooltip.hover.top="'Tambah Pengajar'"
                style="cursor: pointer"
                v-if="btnAccess"
              ></i>
            </div>

            <v-divider></v-divider>

            <template v-for="teacher in teachers">
              <div
                class="d-flex bd-highlight pl-5 pr-6"
                style="height: 50px"
                :key="teacher.user_id"
              >
                <div class="p-2 bd-highlight flex-grow-1">
                  <div class="d-flex align-items-center">
                    <img :src="teacher.user_photo" class="user-img rounded-circle mr-2" />
                   <span>
                     {{ teacher.user_name }}
                    </span>
                  </div>
                </div>
                <div class="p-2 bd-highlight" v-if="btnAccess">
                  <i
                    class="fas fa-times"
                    @click="deleteData(teacher.participant_id)"
                    v-b-tooltip.hover.top="'Hapus Pengajar'"
                  ></i>
                </div>
              </div>
            </template>
          </template>

          <!-- Student -->
          <template>
            <div
              class="d-flex align-items-center justify-content-between mt-15"
              style="height: 0px"
            >
              <h4>Murid</h4>
              <i
                class="fas fa-user-plus"
                @click="showModalUser(2)"
                style="cursor: pointer"
                v-b-tooltip.hover.top="'Tambah Peserta'"
                v-if="btnAccess"
              ></i>
            </div>

            <v-divider></v-divider>

            <template v-for="student in students">
              <div
                class="d-flex bd-highlight pl-5 pr-6"
                style="height: 50px"
                :key="student.user_id"
              >
                <div class="p-2 bd-highlight flex-grow-1">
                  <div class="d-flex align-items-center">
                    <img :src="student.user_photo" class="user-img rounded-circle mr-2" />
                   <span>
                     {{ student.user_name }}
                    </span>
                  </div>
                </div>
                <div class="p-2 bd-highlight" v-if="btnAccess">
                  <i
                    class="fas fa-times"
                    @click="deleteData(student.participant_id)"
                  ></i>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <ModalUser
      purpose="modal-user"
      secondPurpose="addParticipant"
      :route="`api/users/get-available-users/${classroom_id}`"
      :selectedItem="participant_id"
      :filterParticipant="filter"
      @chooseUser="setAddParticipant"
      @checkAll="checkAll"
      @formOnSubmit="formOnSubmit"
    />
  </div>
</template>

<script>
import ModalUser from "@/view/components/general/ModalUser.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    teacherParticipants: Array,
    studentParticipants: Array,
    participants: Array,
    classroom_id: String,
  },
  components: {
    ModalUser,
  },
  data() {
    return {
      display: {
        participant_name: "",
      },
      form: {
        classroom_id: "",
        user_id: "",
        role_id: "",
      },
      perPage: 40,
      currentPage: 1,
      totalRows: 1,
      students: [],
      teachers: [],
      participant_id: [],
      filter: {
        role_id: "",
      },
      // access
      btnAccess: false
    };
  },
  methods: {
    showModalUser(role_id) {
      this.filter.role_id = role_id;
      this.$bvModal.show("modal-user");
    },
    setListParticipant() {
      for (let a = 0; a < this.participants.length; a++) {
        if (this.participants[a].role_id == 1) {
          this.teachers = this.participants[a].children;
          for (let b = 0; b < this.teachers.length; b++) {
            this.teachers[b].id = this.teachers[b].user_id;
            // let clone = { ...this.teachers[b] };
          }
        } else if (this.participants[a].role_id == 2) {
          this.students = this.participants[a].children;
          for (let c = 0; c < this.students.length; c++) {
            this.students[c].id = this.students[c].user_id;
            // let clone = { ...this.students[c] };
          }
        }
      }
    },
    setListParticipantOnChild(value) {
      for (let a = 0; a < value.length; a++) {
        if (value[a].role_id == 1) {
          this.teachers = value[a].children;
          for (let b = 0; b < this.teachers.length; b++) {
            this.teachers[b].id = this.teachers[b].user_id;
            // let clone = { ...this.teachers[b] };
          }
        } else if (value[a].role_id == 2) {
          this.students = value[a].children;
          for (let c = 0; c < this.students.length; c++) {
            this.students[c].id = this.students[c].user_id;
            // let clone = { ...this.students[c] };
          }
        }
      }
    },
    setAddParticipant(value) {
      if (this.participant_id.length == 0) {
        let clone = { ...value };
        this.participant_id.push(clone);
        this.displayParticipantName(this.participant_id);
      } else {
        let a;
        for (a = 0; a < this.participant_id.length; a++) {
          if (this.participant_id[a].id == value.id) {
            this.participant_id.splice(a, 1);
            if (this.participant_id.length == 0) {
              this.display.participant_name = "";
              break;
            } else if (this.participant_id.length > 0) {
              this.displayParticipantName(this.participant_id);
              break;
            }
            break;
          } else if (a == this.participant_id.length - 1) {
            let clone = { ...value };
            this.participant_id.push(clone);
            this.displayParticipantName(this.participant_id);
            break;
          }
        }
      }
    },
    displayParticipantName(participant) {
      if (participant.length == 0) {
        this.display.participant_name = "";
      } else {
        let str = "";
        participant.forEach(function (value, index) {
          str += value.name;
          if (index != participant.length - 1) {
            if (participant.length > 1) str += ", ";
          }
        });
        this.display.participant_name = str;
      }
    },
    resetParticipant() {
      this.participant_id.splice(0, this.participant_id.length);
      this.display.participant_name = "";
    },
    checkAll(value) {
      if (value.length > 0) {
        for (let a = 0; a < value.length; a++) {
          this.setAddParticipant(value[a]);
        }
      } else {
        this.participant_id = []
        this.display.participant_name = ""
      }
    },
    async formOnSubmit() {
      if (this.participant_id.length > 0) {
        SwalLoading.fire();
        let a;
        for (a = 0; a < this.participant_id.length; a++) {
          this.form.user_id = this.participant_id[a].id;
          this.form.role_id = this.participant_id[a].role;
          this.form.classroom_id = this.classroom_id;
          let response = await module.submitParticipant(
            this.form,
            `api/classrooms/${this.classroom_id}/participants`
          );

          if (a == this.participant_id.length - 1) {
            SwalLoading.close();
            Swal.fire(
              response.success.title,
              response.success.message,
              "success"
            );
            this.display.participant_name = "";
            this.participant_id = [];

            this.$root.$emit("refreshParticipants", this.classroom_id);
          }
        }
      } else {
        Swal.fire({
          title: "Gagal",
          text: "Anda belum memilih peserta",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.error.name = "Peserta harus diisi";
      }
    },
    async deleteData(id) {
      // Delete Data
      let result = await module.delete(
        "api/classrooms/" + this.classroom_id + "/participants/" + id
      );
      // If Deleted
      if (result) {
        this.teachers = [];
        this.students = [];
        this.$root.$emit("refreshParticipants", this.classroom_id);
      }
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1014") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    // this.setListParticipant();
    this.checkAccess()
    this.setListParticipant();
  },
  watch: {
    classroom_id: function (newVal, oldVal) {
      // watch it
    },
    participants: function (newVal, oldVal) {
      // watch it
      this.setListParticipantOnChild(newVal);
    },
  },
};
</script>

<style scoped>
.user-img {
  height: 40px;
}

.tampilkan-semua {
  border-radius: 20px;
}

</style>