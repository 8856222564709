<template>
  <div>
    <b-modal
      id="modal-quiz-detail"
      size="lg"
      body-class="pt-0"
      hide-footer
      hied-header
    >
      <div class="row">
        <div class="col-md-12">
            <Detail purpose="modal-quiz-detail" :detail="detail" @handleContent="handleContent" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/materials/quizzes/Table.vue";
import Detail from "@/view/components/materials/quizzes/Detail.vue";
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    detail: Object,
  },

  components: {
    Table,
    Detail
  },
  data() {
    return {
    };
  },
  methods: {
    handleContent(){
      this.content = "list"
    },
  },
};
</script>

<style>
</style>