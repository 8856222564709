<template>
<v-app>
  <div class="animate__animated animate__fadeIn">
    <div class="classwork-quiz-form">
      <div id="kt_classwork_schedule_form">
        <div
          class="wizard wizard-1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Body-->
          <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
            <div class="col-xl-12">
              <!--begin: Wizard Form-->
              <form class="form" @submit.stop.prevent="classworkQuizAdd()">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h5
                    class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg"
                  >
                    Edit Tugas Kuis
                  </h5>

                  <b-form-group
                    id="input-group-trigger-modal-quiz"
                    label="Kuis:"
                    label-for="input-trigger-modal-quiz"
                  >
                    <b-form-input
                      id="input-trigger-modal-quiz"
                      v-model="display.quiz_title"
                      placeholder="Pilih Kuis"
                      readonly
                      @click="$bvModal.show('modal-quiz')"
                    >
                    </b-form-input>
                    <small class="text-danger">{{ error.quiz_id }}</small>
                  </b-form-group>

                  <!-- <b-form-group
                    id="input-group-point"
                    label="Point:"
                    label-for="input-point"
                  >
                    <b-form-input
                      id="input-point"
                      v-model="postQuiz.point"
                      placeholder="Point"
                    ></b-form-input>
                    <small class="text-danger">{{ error.point }}</small>
                  </b-form-group> -->

                  <b-form-group
                    id="input-group-start-date"
                    label="Mulai:"
                    label-for="input-start-date"
                  >
                      <v-datetime-picker
                        v-model="date_start"
                        :time-picker-props="timeProps"
                        time-format="HH:mm:ss"
                      ></v-datetime-picker>
                    <small class="text-danger">{{ error.start_date }}</small>
                  </b-form-group>

                  <b-form-group
                    id="input-group-end-date"
                    label="Selesai:"
                    label-for="input-end-date"
                  >
                      <v-datetime-picker
                        v-model="date_end"
                        :time-picker-props="timeProps"
                        time-format="HH:mm:ss"
                      ></v-datetime-picker>
                    <small class="text-danger">{{ error.end_date }}</small>
                  </b-form-group>                  

                  <b-form-group
                    id="input-group-section-id"
                    label="Seksi:"
                    label-for="input-section-id"
                  >
                    <treeselect
                      v-model="postClasswork.section_id"
                      :multiple="false"
                      placeholder="Pilih Seksi"
                      :options="sections"
                    />
                    <small class="text-danger">{{ error.section_id }}</small>
                  </b-form-group>

                  <div class="form-group">
                    <label>Topik</label>
                    <treeselect
                      :options="topics"
                      v-model="postQuiz.topic_id"
                      :multiple="false"
                    >
                    </treeselect>
                    <small class="text-danger">{{ error.topic_id }}</small>
                  </div>

                  <b-form-group
                      id="input-group-role"
                      label="Standar Kompetensi:"
                      label-for="input-role"
                    >
                      <treeselect
                        v-model="postQuiz.competence_standard_id"
                        :multiple="false"
                        placeholder="Pilih Standar Kompetensi"
                        :options="competence_standards"
                      />
                      <small class="text-danger">{{
                        error.competence_standard_id
                      }}</small>
                    </b-form-group>

                  <b-form-group
                    id="input-group-classwork-to"
                    label="Siswa:"
                    label-for="input-classwork-to"
                  >
                    <treeselect
                      v-model="classwork_to"
                      :multiple="true"
                      placeholder="Pilih Siswa"
                      :options="students"
                    />
                    <small class="text-danger">{{ error.classwork_to }}</small>
                  </b-form-group>

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div>
                      <b-button type="submit" variant="primary"
                        >Simpan</b-button
                      >
                      <b-button
                        type="button"
                        class="ml-2"
                        variant="default"
                        @click="handleContent"
                      >
                        Batal
                      </b-button>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
      </div>
    </div>

    <ModalQuiz purpose="modal-quiz" @chooseQuiz="chooseQuiz" />
  </div>
</v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ApiService from "@/core/services/api.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ModalQuiz from "@/view/components/general/ModalQuiz.vue";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    postQuiz: Object,
    postClasswork: Object,
    classroom_id: String,
  },
  components: {
    ModalQuiz,
  },
  data() {
    return {
      timeProps: {
        useSeconds: true,
        ampmInTitle: true
      },
      display: {
        quiz_title: "",
      },
      error: {
        classwork_to: "",
        classwork_id: "",
        point: "",
        start_date: "",
        due_date: "",
        topic_id: "",
        section_id: ""
      },
      participants: [],
      participantsData: [],
      fixedParticipants: [],
      students: [],
      topics: [],
      date_start: new Date().toISOString().substr(0, 10),
      time_start: "00:00:00",
      date_end: new Date().toISOString().substr(0, 10),
      time_end: "00:00:00",
      classwork_to: [0],
      quizzes: [],
      // other
      btnClearClass: "bg-white",
      competence_standards: [],
      sections: []
    };
  },
  methods: {
    chooseQuiz(value) {
      //("value", value)
      this.display.quiz_title = value.title;
      this.postQuiz.quiz_id = value.id;
      this.postQuiz.point = value.total_score;
      //("postQuiz", this.postQuiz);
      this.$bvModal.hide("modal-quiz");
    },
    handleContent() {
      this.$emit("handleContent", "list");
    },
    async getQuizOption() {
      let response = await module.setTreeSelectLesson("api/quizzes");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.quizzes = response.data;
        this.quizzes.unshift({
          label: "Pilih Kuis",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getCompetenceStandardOption() {
      let response = await module.setTreeSelectCompetenceStandard("api/competence-standards");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.competence_standards = response.data;
        this.competence_standards.unshift({
          label: "Pilih Standar Kompetensi",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getSectionOption() {
      let response = await module.setTreeSelect(
        `api/classrooms/${this.classroom_id}/sections`
      );
      // //("section option", response)
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.sections = response.data;
        this.sections.unshift({
          label: "Pilih Seksi",
          id: "",
          isDisabled: true,
        });
      }
    },
    getStudentOption() {
      ApiService.get("api/classrooms/" + this.classroom_id + "/participants")
        .then((response) => {
          this.participants = response.data.data;
          this.participantsData = [];
          for (let a = 0; a < this.participants.length; a++) {
            this.fixedParticipants = response.data.data[a].children;
            if (response.data.data[a].role_id == 2) {
              for (let b = 0; b < this.fixedParticipants.length; b++) {
                this.participantsData.push(this.fixedParticipants[b]);
              }
            }
          }

          this.students = [
            {
              id: 0,
              label: "Semua siswa",
              children: this.participantsData.map((elem) => ({
                id: elem.participant_id,
                label: elem.user_name,
              })),
            },
          ];

          //("option student", this.students);
        })
        .catch((error) => {
          //(error);
        });
    },
    async getTopicOption() {
      let response = await module.setTreeSelect(`api/topics/my-topics/${getUser().id}`);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.topics = response.data;
        this.topics.unshift({
          label: "Pilih Topik",
          id: '',
          isDisabled: true,
        });
      }
    },
    async classworkQuizAdd() {
      // jika tugas ditujukan untuk semua siswa
      //("classwork_to", this.classwork_to);
      //("classwork_to[0]", this.classwork_to[0]);
      if (this.classwork_to[0] == 0) {
        this.postClasswork.classroom_id = parseInt(this.classroom_id);
        this.postClasswork.classwork_to = [];
        for (let a = 0; a < this.participantsData.length; a++) {
          this.postClasswork.classwork_to.push(
            this.participantsData[a].participant_id
          );
        }
        this.postClasswork.classwork_to = JSON.stringify(
          this.postClasswork.classwork_to
        );
        //("classwork", this.postClasswork);
        //("quiz", this.postQuiz);

        // set classwork-quiz
        let start_date = new Date(this.date_start).toISOString().substr(0, 10)
        let start_time = new Date(this.date_start).toTimeString().substr(0, 8)

        let end_date = new Date(this.date_end).toISOString().substr(0, 10)
        let end_time = new Date(this.date_end).toTimeString().substr(0, 8)

        this.postQuiz.start_date = start_date + " " + start_time
        this.postQuiz.due_date = end_date + " " + end_time

        // Make Request
        let response = await module.submit(
          this.postClasswork,
          "api/classrooms/" + this.classroom_id + "/classworks/" + this.postQuiz.classwork_id
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          this.postQuiz.classwork_id = response.success;
          let quiz = await module.submitWithoutAlert(
            this.postQuiz,
            `api/classrooms/${this.classroom_id}/classworks/${response.success}/classwork-quizzes/${this.postQuiz.classwork_quiz_id}`
          );
          // classrooms/{classroomId}/classworks/{classworkId}/classwork-quizzes/{classworkQuizId}
          // Check Response
          if (quiz.state == "error") {
            // Validation Error
            this.error = validation.setValidationError(
              this.error,
              quiz.error.errors
            );
          } else {
            // Success
            Swal.fire(
              quiz.success.title,
              quiz.success.message,
              "success"
            );

            this.$root.$emit("refreshClasswork");
            this.handleContent();
          }
        }
      }
      // jika tugas ditujukan untuk beberapa siswa
      else {
        //("classwork_to", this.classwork_to);
        //("classwork_to[0]", this.classwork_to[0]);
        this.postClasswork.classroom_id = parseInt(this.classroom_id);
        this.postClasswork.classwork_to = JSON.stringify(this.classwork_to);
        //("classwork", this.postClasswork);
        //("quiz", this.postQuiz);

        // set classwork-quiz
        let start_date = new Date(this.date_start).toISOString().substr(0, 10)
        let start_time = new Date(this.date_start).toTimeString().substr(0, 8)

        let end_date = new Date(this.date_end).toISOString().substr(0, 10)
        let end_time = new Date(this.date_end).toTimeString().substr(0, 8)

        this.postQuiz.start_date = start_date + " " + start_time
        this.postQuiz.due_date = end_date + " " + end_time
        this.postQuiz.section_id = this.postClasswork.section_id;

        // Make Request
        let response = await module.submit(
          this.postClasswork,
          "api/classrooms/" + this.classroom_id + "/classworks/" + this.postQuiz.classwork_id
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
          //("this.error", this.error);
          //("response.data.errors", response.error.errors);
        } else {
          this.postQuiz.classwork_id = response.success;
          //("response woyy", response);
          // "api/classrooms/" +
          //     this.classroom_id +
          //     "/classworks/" +
          //     this.classwork_id +
          //     "/classwork-schedules",
          let quiz = await module.submitWithoutAlert(
            this.postQuiz,
            `api/classrooms/${this.classroom_id}/classworks/${response.success}/classwork-quizzes/${this.postQuiz.classwork_quiz_id}`
          );

          // Check Response
          if (quiz.state == "error") {
            // Validation Error
            this.error = validation.setValidationError(
              this.error,
              quiz.error.errors
            );
          } else {
            // Success
            Swal.fire(
              quiz.success.title,
              quiz.success.message,
              "success"
            );
            this.$root.$emit("refreshClasswork");
            this.handleContent();
          }
        }
      }
    },
  },
  mounted() {
    this.getQuizOption();
    this.getTopicOption();
    this.getStudentOption();
    this.getSectionOption();
    this.getCompetenceStandardOption();
    this.display.quiz_title = this.postQuiz.title
    this.date_start = this.postQuiz.start_date
    this.time_start = this.postQuiz.start_date.split(" ")[1]
    this.date_end = this.postQuiz.due_date
    this.time_end = this.postQuiz.due_date.split(" ")[1]
  },
  watch: {},
};
</script>

<style>
</style>