<template>
  <div class="animate__animated animate__fadeIn">
    <div class="container" v-if="content == 'list'">
      <div class="row">
        <div class="col-md-3">
          <b-form-select v-model="score_active" :options="options"></b-form-select>
        </div>
      </div>
    </div>
    <QuizScore
      :classworkQuizScore="classworkQuizScore"
      :classworkQuizScoreNoPagination="classworkQuizScoreNoPagination"
      :classroom_id="classroom_id"
      :classroom_name="classroom_name"
      @handleContent="handleContent"
      v-if="score_active == 2"
    />

    <AssignmentScore
      :classworkAssignmentScore="classworkAssignmentScore"
      :classroom_id="classroom_id"
      :classroom_name="classroom_name"
      :classworkAssignmentScoreNoPagination="classworkAssignmentScoreNoPagination"
      @handleContent="handleContent"
      v-if="score_active == 1"
    />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import QuizScore from "@/view/components/classroom/scores/quiz/QuizScore";
import AssignmentScore from "@/view/components/classroom/scores/assignment/AssignmentScore";
export default {
  props: {
    classworkQuizScore: Array,
    classworkQuizScoreNoPagination: Array,
    classworkAssignmentScore: Array,
    classworkAssignmentScoreNoPagination: Array,
    classroom_id: String,
    classroom_name: String
  },
  components: {
    QuizScore,
    AssignmentScore,
  },
  name: "nilai",
  data() {
    return {
      content: "list",
      options: [
        { value: null, text: "Pilih Jenis Tugas" },
        { value: 1, text: "Tugas" },
        { value: 2, text: "Tugas Kuis" },
      ],
      score_active: 1
    };
  },
  methods: {
    handleContent(content) {
      this.content = content;
    },
  },
  mounted(){
    this.$root.$on("refreshScoreContent", (content) => {
      this.content = content
    });
  },
  watch: {
    classworkQuizScore: function (newVal, oldVal) {
      // watch it
      // //("classwork quiz score changed: ", newVal, " | was: ", oldVal);
    },
    classworkAssignmentScore: function (newVal, oldVal) {
      // watch it
      // //("classwork assignment score changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>

<style>
</style>