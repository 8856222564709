<template>
  <div>
    <b-modal
      id="modal-quiz-score-classroom"
      size="xl"
      title="Daftar Nilai Tugas Kuis"
      body-class=""
      hide-footer
    >
      <div class="row justify-content-start">
        <vue-excel-xlsx
          :data="classworkQuizScoreNoPagination"
          :columns="columns"
          :filename="`Daftar Nilai Kuis ${classroom_name}`"
          :sheetname="`Daftar Nilai Kuis ${classroom_name}`"
        >
          <b-button
            class="mr-1"
            style="background-color: #107c41"
            v-b-tooltip.hover
            title="Download Excel"
          >
            <i class="fas fa-file-excel text-white px-0"></i>
          </b-button>
        </vue-excel-xlsx>
      </div>
      <b-table
        striped
        hover
        :items="classworkQuizScoreNoPagination"
        :fields="fields"
        class="mt-3"
        responsive
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'action' ? '10%' : '' }"
          />
        </template>

        <template #cell(number)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(competence_standard)="data">
          <span>{{
            data.item.competence_standard
              ? data.item.competence_standard.code
              : ""
          }}</span>
          <span class="ml-1">{{
            data.item.competence_standard
              ? data.item.competence_standard.name
              : ""
          }}</span>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    userData: Object,
    classworkQuizScoreNoPagination: Array,
    classroom_name: String
  },
  components: {},
  data() {
    return {
      columns: [
        {
          field: "quiz_title",
          label: "Judul Kuis",
        },
        {
          field: "student_name",
          label: "Siswa",
        },
        {
          field: "final_score",
          label: "Total Nilai",
        },
        {
          field: "final_checked_by_name",
          label: "Pengoreksi",
        },
        {
          field: "is_finish_display",
          label: "Status",
        },
        {
          field: "competence_standard_excel",
          label: "Standar Kompetensi",
        },
      ],
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "quiz_title",
          label: "Judul Kuis",
          sortable: true,
        },
        {
          key: "student_name",
          label: "Siswa",
          sortable: true,
        },
        {
          key: "final_score",
          label: "Total Nilai",
          sortable: true,
        },
        {
          key: "final_checked_by_name",
          label: "Pengoreksi",
          sortable: true,
        },
        {
          key: "is_finish_display",
          label: "Status",
          sortable: true,
        },
        {
          key: "competence_standard",
          label: "Standar Kompetensi",
          sortable: true,
        },
      ],
    };
  },
};
</script>

<style>
</style>