<template>
  <div>
    <div class="classwork-update-form">
      <div id="kt_classwork_update_form">
        <div
          class="wizard wizard-1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Body-->
          <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
            <div class="col-xl-12">
              <!--begin: Wizard Form-->
              <form class="form" @submit.stop.prevent="classworkUpdate()">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                  >
                    Edit Kegiatan
                  </h3>

                  <b-form-group
                    id="input-group-classwork-to"
                    label="Siswa:"
                    label-for="input-classwork-to"
                  >
                    <treeselect
                      v-model="form.classwork_to_display"
                      :multiple="true"
                      placeholder="Pilih Siswa"
                      :options="students"
                    />
                    <small class="text-danger">{{ error.classwork_to }}</small>
                  </b-form-group>

                  <b-form-group
                    id="input-group-section-id"
                    label="Seksi:"
                    label-for="input-section-id"
                  >
                    <treeselect
                      v-model="form.section_id"
                      :multiple="false"
                      placeholder="Pilih Seksi"
                      :options="sections"
                    />
                    <small class="text-danger">{{ error.section_id }}</small>
                  </b-form-group>

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div>
                      <b-button type="submit" variant="primary"
                        >Simpan</b-button
                      >
                      <b-button
                        type="button"
                        class="ml-2"
                        variant="default"
                        @click="handleContent"
                      >
                        Batal
                      </b-button>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ApiService from "@/core/services/api.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    classroom_id: String,
    classwork_to: Array,
  },
  data() {
    return {
      error: {
        section_id: "",
        classwork_to: "",
      },
      sections: [],
      participants: [],
      participantsData: [],
      fixedParticipants: [],
      students: [],
    };
  },
  methods: {
    handleContent() {
      this.$emit("handleContent", "list");
    },
    resetErrorOnNew() {
      for (const [key, value] of Object.entries(this.error)) {
        this.error[key] = "";
      }
    },
    async getSectionOption() {
      let response = await module.setTreeSelect(
        `api/classrooms/${this.classroom_id}/sections`
      );

      // Success
      if (response.state == "success") {
        // Set Option Select
        this.sections = response.data;
        this.sections.unshift({
          label: "Pilih Seksi",
          id: "",
          isDisabled: true,
        });
      }
    },
    getStudentOption() {
      ApiService.get("api/classrooms/" + this.classroom_id + "/participants")
        .then((response) => {
          this.participants = response.data.data;
          this.participantsData = [];
          for (let a = 0; a < this.participants.length; a++) {
            this.fixedParticipants = response.data.data[a].children;
            if (response.data.data[a].role_id == 2) {
              for (let b = 0; b < this.fixedParticipants.length; b++) {
                this.participantsData.push(this.fixedParticipants[b]);
              }
            }
          }

          this.students = [
            {
              id: 0,
              label: "Semua siswa",
              children: this.participantsData.map((elem) => ({
                id: elem.participant_id,
                label: elem.user_name,
              })),
            },
          ];
        })
        .catch((error) => {
          //(error);
        });
    },
    async classworkUpdate() {
      // jika tugas ditujukan untuk semua siswa
      //("classwork_to", this.classwork_to);
      if (this.classwork_to[0] == 0) {
        this.form.classwork_to = [];
        for (let a = 0; a < this.participantsData.length; a++) {
          this.form.classwork_to.push(
            this.participantsData[a].participant_id
          );
        }
        this.form.classwork_to = JSON.stringify(
          this.form.classwork_to
        );
        //("classwork", this.form);
        // api/classrooms/" +
        //     this.classroom_id +
        //     "/classworks/" +
        //     this.form.classwork_id,
        // Make Request
        let response = await module.submit(
          this.form,
          "api/classrooms/" + this.classroom_id + "/classworks/" + this.form.classwork_id
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          // Success
          Swal.fire(
            response.success.title,
            response.success.message,
            "success"
          );

          this.handleContent();
          this.$root.$emit("refreshClasswork");
        }
      }
      // jika tugas ditujukan untuk beberapa siswa
      else {
        //("classwork_to", this.classwork_to);
        this.form.classroom_id = parseInt(this.classroom_id);
        this.form.classwork_to = JSON.stringify(this.form.classwork_to);
        //("classwork", this.form);
        //("material", this.postMaterial);
        // Make Request
        let response = await module.submit(
          this.form,
          "api/classrooms/" + this.classroom_id + "/classworks/" + this.form.classwork_id
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
          //("this.error", this.error);
          //("response.data.errors", response.error.errors);
        } else {
          // Success
          Swal.fire(
            response.success.title,
            response.success.message,
            "success"
          );
          this.handleContent();
          this.$root.$emit("refreshClasswork");
        }
      }
    },
    // classworkUpdate() {
    //   SwalLoading.fire();
    //   if (this.classwork_to == this.classwork_to[0]) {
    //     let a;
    //     this.form.classwork_to = [];
    //     for (a = 0; a < this.participantsData.length; a++) {
    //       this.form.classwork_to.push(this.participantsData[a].participant_id);
    //     }
    //     // //("classwork_to", this.form.classwork_to.toString())
    //     this.form.classwork_to = this.form.classwork_to.toString();
    //     //("data yang akan diinput", this.form);
    //     // classrooms/{classroomId}/classworks/{classworkId}
    //     ApiService.put(
    //       "api/classrooms/" +
    //         this.classroom_id +
    //         "/classworks/" +
    //         this.form.classwork_id,
    //       this.form
    //     )
    //       .then((response) => {
    //         SwalLoading.close();
    //         //("setelah diinput", this.form);
    //         //("setelah diinput response", response);
    //         Swal.fire({
    //           title: "Berhasil",
    //           text: "Berhasil Mengubah Tugas",
    //           icon: "success",
    //           confirmButtonClass: "btn btn-secondary",
    //         });

    //         // this.postLesson.classwork_id = null;
    //         // this.postLesson.lesson_id = 1;
    //         this.classwork_to = [0];

    //         this.form.classwork_to = null;
    //         this.showForm("list");
    //       })
    //       .catch((error) => {
    //         //(error);
    //         SwalLoading.close();
    //         Swal.fire({
    //           title: "Gagal",
    //           text: "Data gagal diubah",
    //           icon: "error",
    //           confirmButtonClass: "btn btn-secondary",
    //         });
    //       });
    //   } else {
    //     this.form.classwork_to = this.classwork_to.toString();
    //     //("classwork_to", this.form.classwork_to);
    //     // classrooms/{classroomId}/classworks/{form.classwork_id}
    //     ApiService.put(
    //       "api/classrooms/" +
    //         this.classroom_id +
    //         "/classworks/" +
    //         this.form.classwork_id,
    //       this.form
    //     )
    //       .then((response) => {
    //         SwalLoading.close();
    //         //("setelah diinput", this.form);
    //         //("setelah diinput response", response);
    //         Swal.fire({
    //           title: "Berhasil",
    //           text: "Berhasil Mengubah Tugas",
    //           icon: "success",
    //           confirmButtonClass: "btn btn-secondary",
    //         });
    //         this.form.classwork_to = null;
    //         this.showForm();
    //       })
    //       .catch((error) => {
    //         SwalLoading.close();
    //         //("responseerror", error);
    //         Swal.fire({
    //           title: "Gagal",
    //           text: "Data gagal diubah",
    //           icon: "error",
    //           confirmButtonClass: "btn btn-secondary",
    //         });
    //       });
    //   }
    // },
  },
  mounted() {
    this.getSectionOption();
    this.getStudentOption();
  },
  watch: {
    form: function (newVal, oldVal) {
      // watch it
      //("Form changed: ", newVal, " | was: ", oldVal);
    },
    classwork_to: function (newVal, oldVal) {
      // watch it
      //("Classwork to changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>

<style>
</style>