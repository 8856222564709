var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animate__animated animate__fadeIn"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mt-8 mb-10",staticStyle:{"height":"0px"}},[_c('div',[_c('h3',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.detail.classwork_type_name))]),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.detail.created_by_name + `(${_vm.detail.created_at_display})`))])]),_c('div')]),_c('v-divider'),_c('div',{staticClass:"col-12 pt-0"},[_c('table',{staticClass:"table table-borderless mt-8"},[_c('tr',[_vm._m(0),_vm._m(1),_c('td',{staticClass:"pl-2"},[_c('span',{},[_vm._v(_vm._s(_vm.detail.agenda))])])]),_c('tr',[_vm._m(2),_vm._m(3),_c('td',{staticClass:"pl-2"},[_c('span',{},[_vm._v(_vm._s(_vm.setDate(_vm.detail.start_date)))])])]),_c('tr',[_vm._m(4),_vm._m(5),_c('td',{staticClass:"pl-2"},[_c('span',{},[_vm._v(_vm._s(_vm.setDate(_vm.detail.end_date)))])])]),_c('tr',[_vm._m(6),_vm._m(7),_c('td',{staticClass:"pl-2"},[_c('span',{},[_vm._v(_vm._s(_vm.detail.meeting_platform_name))])])]),_c('tr',[_vm._m(8),_vm._m(9),_c('td',{staticClass:"pl-2"},[_c('span',{},[_c('a',{attrs:{"href":_vm.detail.meeting_url,"target":"_blank"}},[_vm._v(_vm._s(_vm.detail.meeting_url))])])])]),_c('tr',[_vm._m(10),_vm._m(11),_c('td',{staticClass:"pl-2"},[_c('span',{},[_vm._v(_vm._s(_vm.detail.password))])])]),_c('tr',[_vm._m(12),_vm._m(13),_c('td',{staticClass:"pl-2"},[_c('span',{},[_vm._v(_vm._s(_vm.detail.section_name))])])])])]),_c('v-divider'),_c('b-button',{staticClass:"ml-2",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.handleContent}},[_vm._v(" Tutup ")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pr-1",attrs:{"width":"100"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Agenda")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pl-0 pr-0",attrs:{"width":"10"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pr-1",attrs:{"width":"100"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Mulai")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pl-0 pr-0",attrs:{"width":"10"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pr-1",attrs:{"width":"100"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Selesai")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pl-0 pr-0",attrs:{"width":"10"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pr-1",attrs:{"width":"100"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Platform")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pl-0 pr-0",attrs:{"width":"10"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pr-1",attrs:{"width":"100"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Tautan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pl-0 pr-0",attrs:{"width":"10"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pr-1",attrs:{"width":"100"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Password")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pl-0 pr-0",attrs:{"width":"10"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pr-1",attrs:{"width":"100"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Seksi")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"pl-0 pr-0",attrs:{"width":"10"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
}]

export { render, staticRenderFns }