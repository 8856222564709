var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animate__animated animate__fadeIn"},[_c('div',{staticClass:"row justify-content-between mt-8 mb-10"},[_c('div',[_c('h3',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.detail.title))]),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.detail.created_by_name + `(${_vm.detail.created_at_display})`))])]),_c('div',[_c('table',[_c('tr',[_vm._m(0),_vm._m(1),_c('td',{staticClass:"pl-2"},[_c('span',[_vm._v(_vm._s(_vm.detail.start_date_display + " - "))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.detail.due_date_display))])])]),_c('tr',[_vm._m(2),_vm._m(3),_c('td',{staticClass:"pl-2"},[_c('span',[_vm._v(_vm._s(_vm.detail.duration + " " + _vm.detail.duration_unit_name))])])])])])]),_c('v-divider'),(_vm.start_quiz)?_c('Countdown',{attrs:{"deadline":_vm.detail.due_date},on:{"timeElapsed":_vm.timeElapsedHandler,"handleWarning":_vm.handleWarning}}):_vm._e(),(_vm.questionLoaded)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.confirmSubmit()}}},[_c('b-table',{attrs:{"borderless":"","items":_vm.detail.questions,"fields":_vm.fieldsQuestion},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
                  width: field.key === 'number' ? '5%' : '',
                })})})}},{key:"cell(content)",fn:function(data){return [(data.item.question_type_id == 1)?[_c('span',[_vm._v(_vm._s(data.item.content))]),_vm._v(" "),_c('br'),_c('b-form-group',{attrs:{"id":'input-group-single-text' +
                    _vm.classwork_quiz_result_collection[data.index].identity_form,"label-for":'input-single-text' +
                    _vm.classwork_quiz_result_collection[data.index].identity_form}},[_c('b-form-input',{attrs:{"id":'input-single-text' +
                      _vm.classwork_quiz_result_collection[data.index].identity_form,"placeholder":"Jawaban","disabled":_vm.disable_form},model:{value:(
                      _vm.classwork_quiz_result_collection[data.index].answer
                    ),callback:function ($$v) {_vm.$set(_vm.classwork_quiz_result_collection[data.index], "answer", $$v)},expression:"\r\n                      classwork_quiz_result_collection[data.index].answer\r\n                    "}})],1)]:_vm._e(),(data.item.question_type_id == 2)?[_c('span',[_vm._v(_vm._s(data.item.content))]),_vm._v(" "),_c('br'),_c('b-form-group',{attrs:{"id":'input-group-multiple-text' +
                    _vm.classwork_quiz_result_collection[data.index].identity_form}},[_c('b-form-textarea',{attrs:{"id":'input-multiple-text' +
                      _vm.classwork_quiz_result_collection[data.index].identity_form,"placeholder":"Jawaban","rows":"4","max-rows":"8","disabled":_vm.disable_form},model:{value:(
                      _vm.classwork_quiz_result_collection[data.index].answer
                    ),callback:function ($$v) {_vm.$set(_vm.classwork_quiz_result_collection[data.index], "answer", $$v)},expression:"\r\n                      classwork_quiz_result_collection[data.index].answer\r\n                    "}})],1)]:_vm._e(),(data.item.question_type_id == 3)?[_c('span',[_vm._v(_vm._s(data.item.content))]),_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-radio-group',{attrs:{"id":'radio-group-' +
                      _vm.classwork_quiz_result_collection[data.index].identity_form,"options":data.item.answer_choices_display,"aria-describedby":ariaDescribedby,"name":'radio-options-' +
                      _vm.classwork_quiz_result_collection[data.index].identity_form,"disabled":_vm.disable_form},model:{value:(
                      _vm.classwork_quiz_result_collection[data.index].answer
                    ),callback:function ($$v) {_vm.$set(_vm.classwork_quiz_result_collection[data.index], "answer", $$v)},expression:"\r\n                      classwork_quiz_result_collection[data.index].answer\r\n                    "}})]}}],null,true)})]:_vm._e(),(data.item.question_type_id == 4)?[_c('span',[_vm._v(_vm._s(data.item.content))]),_c('span',{staticClass:"d-block mt-2"},[_vm._v("Jawaban:")]),_vm._l((data.item.answer_choices_display),function(answer){return _c('div',{key:answer.value,staticClass:"d-flex align-items-center"},[_c('div',[_c('b-form-radio',{key:answer.value,attrs:{"name":'some-radios-' +
                        _vm.classwork_quiz_result_collection[data.index]
                          .identity_form,"value":answer.value,"disabled":_vm.disable_form},model:{value:(
                        _vm.classwork_quiz_result_collection[data.index].answer
                      ),callback:function ($$v) {_vm.$set(_vm.classwork_quiz_result_collection[data.index], "answer", $$v)},expression:"\r\n                        classwork_quiz_result_collection[data.index].answer\r\n                      "}},[_vm._v(_vm._s(answer.text))])],1)])})]:_vm._e(),(data.item.question_type_id == 5)?[_c('span',[_vm._v(_vm._s(data.item.content))]),_vm._l((data.item.answer_choices_display),function(answer,index){return _c('div',{key:answer.value,staticClass:"d-flex align-items-center"},[_c('div',[_c('b-form-checkbox',{attrs:{"checked":answer.checked,"id":`checkbox-pick-` +
                        data.item.answer_choices_display[index].identity_form,"name":`checkbox-pick-` +
                        data.item.answer_choices_display[index].identity_form,"disabled":_vm.disable_form},on:{"change":function($event){return _vm.chooseAnswerMultiple(answer.value, data.index)}}})],1),_c('div',[_c('td',{staticClass:"pl-0"},[_c('span',[_vm._v(_vm._s(answer.text))])])])])})]:_vm._e(),(data.item.question_type_id == 6)?[_c('span',[_vm._v(_vm._s(data.item.content))]),_c('b-form-group',{attrs:{"id":'input-group-answer-choice' +
                    _vm.classwork_quiz_result_collection[data.index].identity_form}},[_c('label',{attrs:{"for":'input-answer-completion' +
                      _vm.classwork_quiz_result_collection[data.index].identity_form}},[_vm._v("Jawaban")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-input',{attrs:{"id":'input-answer-completion' +
                          _vm.classwork_quiz_result_collection[data.index]
                            .identity_form,"placeholder":"Jawaban","disabled":_vm.disable_form},model:{value:(_vm.answer_collection_form[data.index].text),callback:function ($$v) {_vm.$set(_vm.answer_collection_form[data.index], "text", $$v)},expression:"answer_collection_form[data.index].text"}})],1),_c('div',{staticClass:"col-md-3"},[(_vm.answer_collection_form[data.index].text != '')?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-info",attrs:{"size":"sm","title":"Tambah Jawaban","type":"button"},on:{"click":function($event){return _vm.setAnswerCompletionChoice(
                            _vm.answer_collection_form[data.index].identity_form,
                            data.index
                          )}}},[_c('i',{staticClass:"fas fa-plus px-0"})]):_vm._e(),(_vm.answer_collection_form[data.index].text == '')?_c('b-button',{staticClass:"btn-info",attrs:{"size":"sm","disabled":""}},[_c('i',{staticClass:"fas fa-plus px-0"})]):_vm._e()],1)]),_c('table',_vm._l((_vm.answer_collection_completion[
                          data.index
                        ]),function(answer,index){return _c('tr',{key:answer.value +
                          _vm.classwork_quiz_result_collection[data.index]
                            .identity_form},[_c('th',{staticClass:"pr-4"},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-times text-danger px-0",staticStyle:{"cursor":"pointer"},attrs:{"title":"Hapus"},on:{"click":function($event){return _vm.removeAnswerChoice(answer.value)}}})]),_c('th',[_c('span',[_vm._v(_vm._s(index + 1 + ". "))])]),_c('th',[_c('span',{staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(" " + answer.text))])])])}),0)])]:_vm._e()]}}],null,false,1050538608)}),_c('v-divider'),(_vm.start_quiz)?_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Selesai")]):_vm._e()],1)])])]):_vm._e(),(_vm.start_quiz == false && _vm.isFinishClassworkQuiz == 0 && _vm.userData.role_id == 2)?_c('b-button',{staticClass:"ml-2 btn-start-quiz",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.startQuiz}},[_vm._v(" Kerjakan ")]):_vm._e(),(_vm.start_quiz == false)?_c('b-button',{staticClass:"ml-2",attrs:{"type":"button","variant":"default"},on:{"click":_vm.handleContent}},[_vm._v(" Tutup ")]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pr-1",staticStyle:{"vertical-align":"top"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Tanggal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pl-0 pr-0",staticStyle:{"vertical-align":"top"},attrs:{"width":"10"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pr-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Waktu")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pl-0 pr-0",attrs:{"width":"10"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
}]

export { render, staticRenderFns }