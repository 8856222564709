<template>
  <div>
    <b-modal
      id="modal-classroom-schedules"
      size="lg"
      title="Atur Jadwal Mingguan"
      body-class="pt-0"
      hide-footer
    >
      <div class="row">
        <div class="col-md-12">
          <form @submit.stop.prevent="formOnsubmit()">
            <b-table
              borderless
              :items="items"
              :fields="fields"
              class="mt-3"
              responsive
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'action' ? '25%' : '' }"
                />
              </template>
              <template #cell(day_name)="data">
                <!-- <b-form-checkbox
                  :disabled="data.item.start_time == '' && data.item.end_time == '' ? true : false"
                  :checked="data.item.checked"
                  :id="`checkbox-pick-item-${data.item.id}`"
                  :name="`checkbox-pick-item-${data.item.id}`"
                  @change="
                    chooseSchedule(
                      {
                        id: data.item.id,
                        start_time: data.item.start_time,
                        end_time: data.item.end_time,
                        schedule_type: data.item.schedule_type,
                        day_name: data.item.day_name,
                        checked: data.item.checked
                      },
                      data.index
                    )
                  "
                  >{{ data.item.day_name }}
                </b-form-checkbox> -->
                <b-form-checkbox
                  :checked="data.item.checked"
                  :id="`checkbox-pick-item-${data.index}`"
                  :name="`checkbox-pick-item-${data.index}`"
                  @change="checkedSchedule(data.index)"
                  >{{ data.item.day_name }}
                </b-form-checkbox>
              </template>
              <template #cell(start_time)="data">
                <b-time
                  v-model="items[data.index].start_time"
                  :disabled="data.item.checked == false ? true : false"
                  :hour12="false"
                  locale="id"
                  @context="onContext"
                ></b-time>
              </template>
              <template #cell(end_time)="data">
                <b-time
                  v-model="items[data.index].end_time"
                  :disabled="data.item.checked == false ? true : false"
                  :hour12="false"
                  locale="id"
                  @context="onContext"
                ></b-time>
              </template>
              <template #cell(schedule_type)="data">
                <b-form-select
                  v-model="items[data.index].schedule_type"
                  :disabled="data.item.checked == false ? true : false"
                  :options="options"
                ></b-form-select>
              </template>
            </b-table>
          </form>
          <div class="d-flex justify-content-between border-top pt-10">
            <div>
              <b-button
                type="submit"
                @click.stop.prevent="formOnsubmit()"
                variant="primary"
                >Simpan</b-button
              >
              <b-button
                type="button"
                class="ml-2"
                variant="default"
                @click="cancelClassroomSchedule"
              >
                Batal
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    classroomId: String,
    classroomSchedule: Array
  },

  components: {},
  data() {
    return {
      courseData: [],
      // items
      items: [
        { id: -1,day_name: "Senin", day_number: 1, start_time: "07:00:00", end_time: "08:00:00", schedule_type: 0, checked: false },
        { id: -2,day_name: "Selasa",day_number: 2, start_time: "07:00:00", end_time: "08:00:00", schedule_type: 0, checked: false },
        { id: -3,day_name: "Rabu",  day_number: 3, start_time: "07:00:00", end_time: "08:00:00", schedule_type: 0, checked: false },
        { id: -4,day_name: "Kamis", day_number: 4, start_time: "07:00:00", end_time: "08:00:00", schedule_type: 0, checked: false },
        { id: -5,day_name: "Jumat", day_number: 5, start_time: "07:00:00", end_time: "08:00:00", schedule_type: 0, checked: false },
        { id: -6,day_name: "Sabtu", day_number: 6, start_time: "07:00:00", end_time: "08:00:00", schedule_type: 0, checked: false },
        { id: -7,day_name: "Minggu",day_number: 7, start_time: "07:00:00", end_time: "08:00:00", schedule_type: 0, checked: false },
      ],
      fields: [
        {
          key: "day_name",
          label: "Hari",
        },
        {
          key: "start_time",
          label: "Mulai",
        },
        {
          key: "end_time",
          label: "Selesai",
        },
        {
          key: "schedule_type",
          label: "Jenis Pertemuan",
        },
      ],
      options: [
          { value: '0', text: 'Regular' },
          { value: '1', text: 'Praktikum' }
        ],
      // form
      form: {
        classroom_id: "",
        schedules: [],
        is_open_presence: 0,
      },
      error: {
        day_name: "",
        start_time: '',
        end_time: "",
        schedule_type: "",
        is_open_presence: "",
      },

    };
  },

  methods: {
    onContext(ctx) {
        this.context = ctx
    },
    setData() {
        //("item lengrh", this.items.length)
        //("schedule lengrh", this.classroomSchedule.length)

      for(let a = 0; a < this.items.length; a++){
          for(let b = 0; b < this.classroomSchedule.length; b++){
              if(this.items[a].day_name == this.classroomSchedule[b].day_name){
                  this.items[a] = this.classroomSchedule[b]
                  //("betul", this.items[a])
                  //("item", this.items)
              }
          }
      }
    },
    checkedSchedule(index){
       if(this.items[index].checked == false ){
           this.items[index].checked = true
           //("items", this.items)
       }else{
           this.items[index].checked = false
           //("items", this.items)
       }
    },
    chooseSchedule(schedule, index){
        //("schedule", schedule)
        //("item", this.items[index])
        this.setSchedule(schedule, index)
    },
    chooseValidation(schedule){
        let response = true
        if(schedule.start_time == '' || schedule.end_time == ''){
            response = false
        }
        return response
    },
    setSchedule(schedule, index){
    if(this.chooseValidation(schedule) == true){
        //("tetep tembus")
        if(this.form.schedules.length > 0){
            for(let a = 0; a < this.form.schedules.length; a++){
                if(this.form.schedules[a].id == schedule.id){
                    //("splice")
                    //("schedule.id", schedule.id)
                    //("this.form.schedules[a].id", this.form.schedules[a].id)
                    //("yang di splice", this.form.schedules[a])
                    //("schedulue", schedule)
                    this.form.schedules.splice(a, 1)
                }else if (a + 1 == this.form.schedules.length){
                    this.form.schedules.push(schedule)
                    //("form", this.form)
                    //("item", this.items)
                    break;
                }
            }
        }else{
            this.form.schedules.push(schedule)
            //("form", this.form)
        }
    }else{
        Swal.fire({
        title: 'Gagal',
        text: 'Mulai dan Selesai tidak boleh kosong',
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })
    }
    },
    formValidation(){
        let response = true
        for(let a = 0; a < this.items.length; a++){
            if(this.items[a].checked == true){
                break;
            }else if(a + 1 == this.items.length){
                response = false
            }
        }
        return response
    },
    async formOnsubmit() {
      if(this.formValidation() == true){
      this.form.schedules = this.items
      this.form.classroom_id = this.classroomId

      // Make Request
      let response = await module.submit(this.form, `api/classrooms/${this.classroomId}/classroom-schedules`);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$root.$emit("refreshClassroomSchedule");
        // this.successAbsent(response);
      }
      }else{
          Swal.fire({
        title: 'Gagal',
        text: 'Mulai dan Selesai tidak boleh kosong',
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })
    }
    },
    cancelClassroomSchedule() {
      this.$emit("cancelClassroomSchedule");
      this.resetErrorOnNew();
      //   this.resetFromOnNew()
    },
    successAbsent(response) {
      this.$emit("successAbsent", response);
      this.resetErrorOnNew();
      this.resetFromOnNew();
    },
    resetErrorOnNew() {
      let self = this;
      for (const [key, value] of Object.entries(this.error)) {
        self.error[key] = "";
      }
    },
    resetFromOnNew() {
      let self = this;
      for (const [key, value] of Object.entries(this.form)) {
        self.form[key] = "";
      }
    },
  },
  mounted(){
      this.setData()
  },
  watch: {
      classroomSchedule: function (newVal, oldVal) {
      // watch it
      //("ClassroomSchedule changed: ", newVal, " | was: ", oldVal);
      this.setData()
    },
  },
};
</script>

<style>
</style>