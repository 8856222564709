<template>
  <v-app>
    <div class="animate__animated animate__fadeIn">
      <div class="classwork-material-form">
        <div id="kt_classwork_material_form">
          <div
            class="wizard wizard-1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <form
                  class="form"
                  @submit.stop.prevent="classworkAssignmentAdd()"
                >
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <h5
                      class="
                        font-weight-bolder
                        text-dark
                        font-size-h6 font-size-h3-lg
                      "
                    >
                      Tambah Tugas
                    </h5>
                    <b-form-group
                      id="input-group-title"
                      label="Judul:"
                      label-for="input-title"
                    >
                      <b-form-input
                        id="input-title"
                        v-model="postClassworkAssignment.title"
                        placeholder="Judul Tugas"
                      ></b-form-input>
                      <small class="text-danger">{{ error.title }}</small>
                    </b-form-group>

                    <b-form-group id="input-group-instruction">
                      <label for="input-instruction">Instruksi: </label>
                      <b-form-textarea
                        id="input-instruction"
                        v-model="postClassworkAssignment.instruction"
                        placeholder="Deskripsi Tugas"
                        rows="4"
                        max-rows="8"
                      ></b-form-textarea>
                      <small class="text-danger">{{ error.instruction }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-point"
                      label="Point:"
                      label-for="input-point"
                    >
                      <b-form-input
                        id="input-point"
                        v-model="postClassworkAssignment.point"
                        placeholder="Point Tugas"
                      ></b-form-input>
                      <small class="text-danger">{{ error.point }}</small>
                    </b-form-group>

                    <div class="form-group">
                      <label>Topik</label>
                      <treeselect
                        :options="topics"
                        @select="selectTopic"
                        :multiple="false"
                      >
                      </treeselect>
                      <small class="text-danger">{{ error.topic_id }}</small>
                    </div>

                    <b-form-group
                      id="input-group-role"
                      label="Standar Kompetensi:"
                      label-for="input-role"
                    >
                      <treeselect
                        v-model="postAssignment.competence_standard_id"
                        :multiple="false"
                        placeholder="Pilih Standar Kompetensi"
                        :options="competence_standards"
                      />
                      <small class="text-danger">{{
                        error.competence_standard_id
                      }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-start-date"
                      label="Mulai:"
                      label-for="input-start-date"
                    >
                      <v-datetime-picker
                        v-model="date_start"
                        :time-picker-props="timeProps"
                        time-format="HH:mm:ss"
                      ></v-datetime-picker>
                      <small class="text-danger">{{ error.start_date }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-end-date"
                      label="Selesai:"
                      label-for="input-end-date"
                    >
                      <v-datetime-picker
                        v-model="date_end"
                        :time-picker-props="timeProps"
                        time-format="HH:mm:ss"
                      ></v-datetime-picker>
                      <small class="text-danger">{{ error.end_date }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-student-id"
                      label="Siswa:"
                      label-for="input-student-id"
                    >
                      <treeselect
                        v-model="classwork_to"
                        :multiple="true"
                        placeholder="Pilih Siswa"
                        :options="students"
                        @select="testingGees()"
                      />
                      <small class="text-danger">{{
                        error.classwork_to
                      }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-section-id"
                      label="Seksi:"
                      label-for="input-section-id"
                    >
                      <treeselect
                        v-model="postClasswork.section_id"
                        :multiple="false"
                        placeholder="Pilih Seksi"
                        :options="sections"
                      />
                      <small class="text-danger">{{ error.section_id }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-file"
                      label="Lampiran:"
                      label-for="input-file"
                    >
                      <b-form-file
                        multiple
                        placeholder="Upload Lampiran"
                        drop-placeholder="Lepaskan File disini..."
                        @change="upload"
                      ></b-form-file>
                      <small class="text-danger">{{ error.attachment }}</small>
                    </b-form-group>

                    <!--begin: Wizard Actions -->
                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div>
                        <b-button type="submit" variant="primary"
                          >Simpan</b-button
                        >
                        <b-button
                          type="button"
                          class="ml-2"
                          variant="default"
                          @click="handleContent"
                        >
                          Batal
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ApiService from "@/core/services/api.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    postClasswork: Object,
    postClassworkAssignment: Object,
    classroom_id: String,
  },
  data() {
    return {
      timeProps: {
        useSeconds: true,
        ampmInTitle: true,
      },
      error: {
        classwork_id: "",
        assignment_id: "",
        author_id: "",
        start_date: "",
        due_date: "",
        point: "",
        title: "",
        instruction: "",
        attachment_list: "",
        topic_id: "",
        section_id: "",
      },
      formData: new FormData(),
      sections: [],
      topics: [],
      participants: [],
      participantsData: [],
      fixedParticipants: [],
      students: [],
      classwork_to: [0],
      // other
      date_start: new Date(),
      time_start: "00:00:00",
      date_end: new Date(),
      time_end: "00:00:00",
      // classwork assignment resutl & score
      postAssignment: {
        classwork_id: "",
        assignment_id: "",
        student_id: "",
        answer: "",
        is_correct: "",
        score: "",
        checked_by: "",
        file_uploaded: "",
        final_score: "",
        final_checked_by: "",
        is_finish: 0,
        competence_standard_id: 0
      },
      // other
      competence_standards: []
    };
  },
  methods: {
    testingGees(evt) {
      //("classworkto", this.classwork_to);
    },
    handleContent() {
      this.$emit("handleContent", "list");
    },
    selectTopic(evt) {
      //(evt);
      this.postClassworkAssignment.topic_id = evt.id;
    },
    async getSectionOption() {
      let response = await module.setTreeSelect(
        `api/classrooms/${this.classroom_id}/sections`
      );
      // //("section option", response)
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.sections = response.data;
        this.sections.unshift({
          label: "Pilih Seksi",
          id: "",
          isDisabled: true,
        });
      }
    },
    getStudentOption() {
      ApiService.get("api/classrooms/" + this.classroom_id + "/participants")
        .then((response) => {
          this.participants = response.data.data;
          this.participantsData = [];
          for (let a = 0; a < this.participants.length; a++) {
            this.fixedParticipants = response.data.data[a].children;
            if (response.data.data[a].role_id == 2) {
              for (let b = 0; b < this.fixedParticipants.length; b++) {
                this.participantsData.push(this.fixedParticipants[b]);
              }
            }
          }

          this.students = [
            {
              id: 0,
              label: "Semua siswa",
              children: this.participantsData.map((elem) => ({
                id: elem.participant_id,
                label: elem.user_name,
              })),
            },
          ];

          //("option student", this.students);
          //("participants data", this.participantsData);
        })
        .catch((error) => {
          //(error);
        });
    },
    async getCompetenceStandardOption() {
      let response = await module.setTreeSelectCompetenceStandard("api/competence-standards");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.competence_standards = response.data;
        this.competence_standards.unshift({
          label: "Pilih Standar Kompetensi",
          id: 0,
          isDisabled: true,
        });
      }
    },
    async getTopicOption() {
      ApiService.get(`api/topics/my-topics/${getUser().id}`)
        .then((response) => {
          this.topics = response.data.data;
          for(let a = 0; a < this.topics.length; a++){
            for(let b = 0; b < this.topics[a].children.length; b++){
              delete this.topics[a].children[b].children
            }
          }
          this.topics.unshift({
            label: "Pilih Topik",
            id: "",
            isDisabled: true,
          });
          //("topics ===================================", this.topics);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    upload(event) {
      if (event.target.files.length > 0) {
        this.formData = new FormData();
      }

      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("attachment[]", event.target.files[a]);
      }

      //("attachment", event.target.files);
    },
    async classworkAssignmentAdd() {
      SwalLoading.fire();
      // jika tugas ditujukan untuk semua siswa
      //("classwork_to", this.classwork_to);
      if (this.classwork_to[0] == 0) {
        this.postClasswork.classroom_id = parseInt(this.classroom_id);
        this.postClasswork.classwork_to = [];
        for (let a = 0; a < this.participantsData.length; a++) {
          this.postClasswork.classwork_to.push(
            this.participantsData[a].participant_id
          );
        }
        this.postClasswork.classwork_to = JSON.stringify(
          this.postClasswork.classwork_to
        );
        //("classwork", this.postClasswork);
        // Make Request
        let response = await module.submitWithoutAlert(
          this.postClasswork,
          "api/classrooms/" + this.classroom_id + "/classworks"
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
          //("this.error", this.error);
          //("response.data.errors", response.error.errors);
        } else {
          let user_data = JSON.parse(window.localStorage.getItem("user"));
          this.postClassworkAssignment.classwork_id = response.success;
          this.postClassworkAssignment.author_id = user_data.id;

          let start_date = new Date(this.date_start)
            .toISOString()
            .substr(0, 10);
          let start_time = new Date(this.date_start)
            .toTimeString()
            .substr(0, 8);

          let end_date = new Date(this.date_end).toISOString().substr(0, 10);
          let end_time = new Date(this.date_end).toTimeString().substr(0, 8);

          this.postClassworkAssignment.start_date =
            start_date + " " + start_time;
          this.postClassworkAssignment.due_date = end_date + " " + end_time;

          for (const [key, value] of Object.entries(
            this.postClassworkAssignment
          )) {
            this.formData.append(key, value);
          }

          //("response woyy", response);
          let assignment = await module.submitWithoutAlert(
            this.formData,
            `api/classrooms/${this.classroom_id}/classworks/${response.success}/assignment-classworks`
          );
          // classrooms/{classroomId}/classworks/{classworkId}/assignment-classworks

          // Check Response
          if (assignment.state == "error") {
            // Validation Error
            this.error = validation.setValidationError(
              this.error,
              assignment.error.errors
            );
          } else {
            // Success

            for (let x = 0; x < this.participantsData.length; x++) {
              this.postAssignment.classwork_id = parseInt(
                assignment.success.item.original.classwork_id
              );
              this.postAssignment.assignment_id = parseInt(
                assignment.success.item.original.assignment_id
              );
              this.postAssignment.student_id = this.participantsData[x].user_id;
              this.postAssignment.checked_by = getUser().id;
              this.postAssignment.final_checked_by = getUser().id;
              this.postAssignment.is_correct = 0;
              this.postAssignment.final_score = 0;
              this.postAssignment.is_correct = 0;

              let assignmentResult = await module.submitWithoutAlert(
                this.postAssignment,
                `api/classrooms/${this.classroom_id}/classworks/${assignment.success.item.original.classwork_id}/classwork-assignment-results`
              );

              if (x + 1 == this.participantsData.length) {
                SwalLoading.close();
                //("assignment in page", assignment);
                this.handleContent();
                this.$root.$emit("refreshClasswork");
                Swal.fire(
                  assignment.success.title,
                  assignment.success.message,
                  "success"
                );
              }
            }
          }
        }
      }
      // jika tugas ditujukan untuk beberapa siswa saja
      else {
        //("classwork_to", this.classwork_to);
        //("classwork_to[0]", this.classwork_to[0]);
        this.postClasswork.classroom_id = parseInt(this.classroom_id);
        this.postClasswork.classwork_to = JSON.stringify(this.classwork_to);
        //("classwork", this.postClasswork);
        //("material", this.postClasswork);
        // Make Request
        let response = await module.submit(
          this.postClasswork,
          "api/classrooms/" + this.classroom_id + "/classworks"
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
          //("this.error", this.error);
          //("response.data.errors", response.error.errors);
        } else {
          let user_data = JSON.parse(window.localStorage.getItem("user"));
          this.postClassworkAssignment.classwork_id = response.success;
          this.postClassworkAssignment.author_id = user_data.id;
          let start_date = new Date(this.date_start)
            .toISOString()
            .substr(0, 10);
          let start_time = new Date(this.date_start)
            .toTimeString()
            .substr(0, 8);

          let end_date = new Date(this.date_end).toISOString().substr(0, 10);
          let end_time = new Date(this.date_end).toTimeString().substr(0, 8);

          this.postClassworkAssignment.start_date =
            start_date + " " + start_time;
          this.postClassworkAssignment.due_date = end_date + " " + end_time;

          for (const [key, value] of Object.entries(
            this.postClassworkAssignment
          )) {
            this.formData.append(key, value);
          }

          //("response woyy", response);
          let assignment = await module.submitWithoutAlert(
            this.postClassworkAssignment,
            `api/classrooms/${this.classroom_id}/classworks/${response.success}/assignment-classworks`
          );

          // Check Response
          if (assignment.state == "error") {
            // Validation Error
            this.error = validation.setValidationError(
              this.error,
              assignment.error.errors
            );
          } else {
            // Success

            for (let x = 0; x < this.classwork_to.length; x++) {
              this.postAssignment.classwork_id = parseInt(
                assignment.success.item.original.classwork_id
              );
              this.postAssignment.assignment_id = parseInt(
                assignment.success.item.original.assignment_id
              );
              this.postAssignment.student_id = this.classwork_to[x];
              this.postAssignment.checked_by = getUser().id;
              this.postAssignment.final_checked_by = getUser().id;
              this.postAssignment.is_correct = 0;
              this.postAssignment.final_score = 0;
              this.postAssignment.is_correct = 0;

              let assignmentResult = await module.submitWithoutAlert(
                this.postAssignment,
                `api/classrooms/${this.classroom_id}/classworks/${assignment.success.item.original.classwork_id}/classwork-assignment-results`
              );

              if (x + 1 == this.classwork_to.length) {
                SwalLoading.close();
                //("assignment in page", assignment);
                this.handleContent();
                this.$root.$emit("refreshClasswork");
                this.$root.$emit("refreshClassworkAssignmentScore");
                Swal.fire(
                  assignment.success.title,
                  assignment.success.message,
                  "success"
                );
              }
            }
          }
        }
      }
    },
  },
  mounted() {
    this.getSectionOption();
    this.getTopicOption();
    this.getCompetenceStandardOption();
    this.getStudentOption();
  },
  watch: {},
};
</script>

<style>
</style>