<template>
  <div class="animate__animated animate__fadeIn">
    <div class="container">
      <div class="row justify-content-between mb-8">
        <div>
          <h3 class="d-block">{{ detail.title }}</h3>
          <span class="text-muted">{{ detail.start_date + ' s/d ' + detail.due_date }}</span>
        </div>
        <div>
          <table>
            <tr>
              <td class="pr-1">
                <span class="font-weight-bold">Nama Siswa</span>
              </td>
              <td class="pl-0 pr-0" width="10">
                <span class="font-weight-bold">:</span>
              </td>
              <td class="pl-2">
                <span>{{ studentName }}</span>
              </td>
            </tr>
            <tr>
              <td class="pr-1">
                <span class="font-weight-bold">Mulai Mengerjakan</span>
              </td>
              <td class="pl-0 pr-0" width="10">
                <span class="font-weight-bold">:</span>
              </td>
              <td class="pl-2">
                <span>{{ startWork }}</span>
              </td>
            </tr>
            <tr>
              <td class="pr-1">
                <span class="font-weight-bold">Selesai Mengerjakan</span>
              </td>
              <td class="pl-0 pr-0" width="10">
                <span class="font-weight-bold">:</span>
              </td>
              <td class="pl-2">
                <span>{{ finishWork }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <v-divider></v-divider>

    <div class="container" v-if="questionLoaded">
      <div class="row">
        <div class="col-md-12">
        <form @submit.stop.prevent="formOnsubmitUpdate()">
          <b-table responsive :items="detail.questions" :fields="fieldsQuestion">
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width:
                    field.key === 'number'
                      ? '1%'
                      : field.key === 'content'
                      ? '25%'
                      : field.key === 'answer'
                      ? '20%'
                      : field.key === 'answer'
                      ? '20%'
                      : field.key === 'mark'
                      ? '2%'
                      : '',
                }"
              />
            </template>

            <template #cell(content)="data">
              <!-- Single text -->
              <template v-if="data.item.question_type_id == 1">
                <span>{{ data.item.content }}</span>
              </template>
              <!-- Multiple text -->
              <template v-if="data.item.question_type_id == 2">
                <span>{{ data.item.content }}</span> <br />
                <b-form-group
                  :id="
                    'input-group-multiple-text' +
                    classwork_quiz_result_collection[data.index].identity_form
                  "
                >
                </b-form-group>
              </template>

              <!-- True Or False -->
              <template v-if="data.item.question_type_id == 3">
                <span>{{ data.item.content }}</span>
              </template>

              <!-- Single Choice -->
              <template v-if="data.item.question_type_id == 4">
                <span>{{ data.item.content }}</span>
              </template>

              <!-- Multiple Choice -->
              <template v-if="data.item.question_type_id == 5">
                <span>{{ data.item.content }}</span>
              </template>
              <!-- Completion -->
              <template v-if="data.item.question_type_id == 6">
                <span>{{ data.item.content }}</span>
              </template>
            </template>

            <template #cell(answer)="data">
              <!-- Single text || Multiple Text -->
              <template
                v-if="
                  data.item.question_type_id == 1 ||
                  data.item.question_type_id == 2
                "
              >
                {{ data.item.answer }}
              </template>

              <!-- True Or False -->
              <template v-if="data.item.question_type_id == 3">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    :id="
                      'radio-group-correct' +
                      classwork_quiz_result_collection[data.index].identity_form
                    "
                    v-model="data.item.answer"
                    :options="data.item.answer_choices_display_reference"
                    :aria-describedby="ariaDescribedby"
                    :name="
                      'radio-options-correct' +
                      classwork_quiz_result_collection[data.index].identity_form
                    "
                    disabled
                  ></b-form-radio-group>
                </b-form-group>
              </template>

              <!-- Single Choice -->
              <template v-if="data.item.question_type_id == 4">
                <div
                  class="d-flex align-items-center"
                  v-for="answer in data.item.answer_choices_display_reference"
                  :key="answer.value"
                >
                  <div>
                    <b-form-radio
                      :key="answer.value"
                      :name="
                        'some-radios-answer-correct' +
                        classwork_quiz_result_collection[data.index]
                          .identity_form
                      "
                      v-model="
                        data.item.answer
                      "
                      :value="answer.value"
                      :disabled="disable_form"
                      >{{ answer.text }}</b-form-radio
                    >
                  </div>
                </div>
              </template>

              <!-- Multiple Choice -->
              <template v-if="data.item.question_type_id == 5">
                <div
                  class="d-flex align-items-center"
                  v-for="(answer, index) in data.item
                    .answer_choices_display_reference"
                  :key="answer.value"
                >
                  <div>
                    <b-form-checkbox
                      :checked="answer.checked"
                      :id="
                        `checkbox-pick-` +
                        data.item.answer_choices_display[index].identity_form
                      "
                      :name="
                        `checkbox-pick-` +
                        data.item.answer_choices_display[index].identity_form
                      "
                      :disabled="disable_form"
                    >
                    </b-form-checkbox>
                    <!-- {{data.item.questions[data.index].answer_choices_display[index].identity_form}} -->
                  </div>
                  <div>
                    <span>{{ answer.text }}</span>
                  </div>
                </div>
              </template>

              <!-- Completion -->
              <template v-if="data.item.question_type_id == 6">
                <b-form-group
                  :id="
                    'input-group-answer-choice' +
                    classwork_quiz_result_collection[data.index].identity_form
                  "
                >
                  <table class="table-borderless">
                    <tr
                      v-for="(answer, index) in data.item
                        .answer_choices_display_reference"
                      :key="
                        answer.value +
                        classwork_quiz_result_collection[data.index]
                          .identity_form
                      "
                    >
                      <td>
                        <span>{{ index + 1 + ". " }}</span>
                      </td>
                      <td>
                        <span style="cursor: pointer">{{
                          " " + answer.text
                        }}</span>
                      </td>
                    </tr>
                  </table>
                </b-form-group>
              </template>
            </template>

            <template #cell(mark)="data">
              {{ detail.question_list_display[data.index].mark }}
            </template>

            <template #cell(student_answer)="data">
              <!-- Single text || Multiple Text -->
              <template
                v-if="
                  data.item.question_type_id == 1 ||
                  data.item.question_type_id == 2
                "
              >
                {{ available_answer[data.index].answer }}
              </template>
              <!-- True Or False -->
              <template v-if="data.item.question_type_id == 3">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    :id="
                      'radio-group-' +
                      classwork_quiz_result_collection[data.index].identity_form
                    "
                    v-model="
                      classwork_quiz_result_collection[data.index].answer
                    "
                    :options="data.item.answer_choices_display"
                    :aria-describedby="ariaDescribedby"
                    :name="
                      'radio-options-' +
                      classwork_quiz_result_collection[data.index].identity_form
                    "
                    :disabled="disable_form"
                  ></b-form-radio-group>
                </b-form-group>
              </template>
              <!-- Single Choice -->
              <template v-if="data.item.question_type_id == 4">
                <div
                  class="d-flex align-items-center"
                  v-for="answer in data.item.answer_choices_display"
                  :key="answer.value"
                >
                  <div>
                    <b-form-radio
                      :key="answer.value"
                      :name="
                        'some-radios-' +
                        classwork_quiz_result_collection[data.index]
                          .identity_form
                      "
                      v-model="
                        classwork_quiz_result_collection[data.index].answer
                      "
                      :value="answer.value"
                      :disabled="disable_form"
                      >{{ answer.text }}</b-form-radio
                    >
                  </div>
                </div>
              </template>

              <!-- Multiple Choice -->
              <template v-if="data.item.question_type_id == 5">
                <div
                  class="d-flex align-items-center"
                  v-for="(answer, index) in data.item.answer_choices_display"
                  :key="answer.value"
                >
                  <div>
                    <b-form-checkbox
                      :checked="answer.checked"
                      :id="
                        `checkbox-pick-` +
                        data.item.answer_choices_display[index].identity_form
                      "
                      :name="
                        `checkbox-pick-` +
                        data.item.answer_choices_display[index].identity_form
                      "
                      :disabled="disable_form"
                    >
                    </b-form-checkbox>
                    <!-- {{data.item.questions[data.index].answer_choices_display[index].identity_form}} -->
                  </div>
                  <div>
                    <span>{{ answer.text }}</span>
                  </div>
                </div>
              </template>

              <!-- Completion -->
              <template v-if="data.item.question_type_id == 6">
                <b-form-group
                  :id="
                    'input-group-answer-choice' +
                    classwork_quiz_result_collection[data.index].identity_form
                  "
                >
                  <table class="table-borderless">
                    <tr
                      v-for="(answer, index) in answer_collection_completion[
                        data.index
                      ]"
                      :key="
                        answer.value +
                        classwork_quiz_result_collection[data.index]
                          .identity_form
                      "
                    >
                      <td>
                        <span>{{ index + 1 + ". " }}</span>
                      </td>
                      <td>
                        <span style="cursor: pointer">{{
                          " " + answer.text
                        }}</span>
                      </td>
                    </tr>
                  </table>
                </b-form-group>
              </template>
            </template>

            <template #cell(is_correct)="data">
              <b-button
                type="button"
                size="sm"
                :variant="
                  classwork_quiz_result_collection[data.index].is_active ==
                    true &&
                  classwork_quiz_result_collection[data.index].is_correct == 1
                    ? 'primary'
                    : 'default'
                "
                @click="handleCorrect(data.index, 1)"
                ><i class="fas fa-check fa-sm px-0"></i
              ></b-button>
            </template>

            <template #cell(is_wrong)="data">
              <v-hover v-slot="{ hover }">
                <b-button
                  type="button"
                  size="sm"
                  :variant="
                    classwork_quiz_result_collection[data.index].is_active ==
                      true &&
                    classwork_quiz_result_collection[data.index].is_correct == 0
                      ? 'danger'
                      : 'default'
                  "
                  @click="handleCorrect(data.index, 0)"
                >
                  <template
                    v-if="
                      hover &&
                      classwork_quiz_result_collection[data.index].is_correct !=
                        0
                    "
                  >
                    <i class="fas fa-times fa-sm px-0 text-danger"></i>
                  </template>
                  <template v-else>
                    <i class="fas fa-times fa-sm px-0"></i>
                  </template>
                </b-button>
              </v-hover>
            </template>

            <template #cell(score)="data">
              <b-form-group
                :id="
                  'input-group-single-text' +
                  classwork_quiz_result_collection[data.index].identity_form
                "
                :label-for="
                  'input-single-text' +
                  classwork_quiz_result_collection[data.index].identity_form
                "
              >
                <b-form-input
                  :id="
                    'input-single-text' +
                    classwork_quiz_result_collection[data.index].identity_form
                  "
                  v-model="classwork_quiz_result_collection[data.index].score"
                  :disabled="
                    classwork_quiz_result_collection[data.index].is_active ==
                      false ||
                    classwork_quiz_result_collection[data.index].is_correct == 0
                      ? true
                      : false
                  "
                  placeholder="Skor"
                  @keyup="handleScore(data.index)"
                ></b-form-input>
              </b-form-group>
            </template>
          </b-table>
          <v-divider></v-divider>
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            class="ml-2"
            type="button"
            variant="default"
            @click="handleContent"
            >Tutup</b-button
          >
        </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from "@/view/components/general/Countdown.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import draggable from "vuedraggable";
export default {
  components: {
    Countdown,
    draggable,
  },
  props: {
    detail: Object,
    classroom_id: String,
    available_answer: Array,
    studentId: Number,
    studentName: String,
    startWork: String,
    finishWork: String,
    statusQuiz: Number
  },
  data() {
    return {
      fieldsQuestion: [
        {
          key: "number",
          label: "",
        },
        { key: "content", label: "Soal" },
        { key: "answer", label: "Acuan Jawaban" },
        { key: "mark", label: "Acuan Skor" },
        { key: "student_answer", label: "Jawaban Murid" },
        { key: "is_correct", label: "Benar" },
        { key: "is_wrong", label: "Salah" },
        { key: "score", label: "Skor" },
      ],
      // classwork quiz result and scores
      classwork_quiz_result_collection: [],
      classwork_quiz_result_form: {
        // quiz_result
        classwork_id: "",
        quiz_id: "",
        question_id: "",
        student_id: "",
        answer: "",
        is_correct: "",
        score: "",
        checked_by: "",
        // quiz_scores
        student_id: "",
        final_checked_by: "",
        // other
        identity_form: "",
        question_type_id: "",
        is_active: "",
      },
      // set answer for question type completion
      answer_collection_form: [],
      answer_collection_completion_form: {
        text: "",
        value: "",
        identity_form: "",
      },
      answer_collection_completion: [],
      answer_value: 0,
      // answer
      put: {
        answer: null,
        id: [],
        is_finish: 0,
      },
      id_form: {
        classwork_id: "",
        quiz_id: "",
        student_id: "",
      },
      // other
      questionLoaded: false,
      start_quiz: false,
      disable_form: true,
      multiple_choice_answer: [],
      set_answer_form_success: false,
      set_answer_form_success2: false,
    };
  },
  methods: {
    handleContent() {
      this.$emit("handleContent", "list");
      this.$root.$emit("refreshScoreContent", "list")
    },
    handleCorrect(index, correct) {
      //("datanya", this.classwork_quiz_result_collection[index]);
      if (correct === 1) {
        this.classwork_quiz_result_collection[index].is_active = true;
        this.classwork_quiz_result_collection[index].is_correct = 1;
        this.classwork_quiz_result_collection[index].score =
          this.detail.question_list_display[index].mark;
      } else if (correct === 0) {
        this.classwork_quiz_result_collection[index].is_active = true;
        this.classwork_quiz_result_collection[index].is_correct = 0;
        this.classwork_quiz_result_collection[index].score = 0;
      }
    },
    handleScore(index) {
      let default_score = parseInt(this.detail.question_list_display[index].mark);
      let input_score = parseInt(this.classwork_quiz_result_collection[index].score);
      //("input scroe", input_score)
      //("default scroe", default_score)
      if (input_score > default_score) {
        //("jalan")
        this.classwork_quiz_result_collection[index].score =
          this.detail.question_list_display[index].mark;
      }
    },
    scrollToTop(options) {
      // window.scrollTo(0, 0);
      let el = this.$el.getElementsByClassName("text-muted")[0];
      //("el", el);
      if (el) {
        el.scrollIntoView(options);
      }
    },
    scrollToBottom(options) {
      // window.scrollTo(0, 0);
      let el = this.$el.getElementsByClassName("btn-start-quiz")[0];
      //("el", el);
      if (el) {
        el.scrollIntoView(options);
      }
    },
    // set answer form
    async setAnswerForm() {
      // set form for all question types
      for (let a = 0; a < this.detail.questions.length; a++) {
        // keperluan ui/ux
        this.classwork_quiz_result_form.identity_form = a;
        this.classwork_quiz_result_form.is_active = false;
        //
        this.classwork_quiz_result_form.classwork_id = this.detail.classwork_id;
        this.classwork_quiz_result_form.quiz_id = this.detail.quiz_id;
        this.classwork_quiz_result_form.question_id =
          this.detail.questions[a].id;
        this.classwork_quiz_result_form.checked_by =
          this.detail.questions[a].created_by;
        this.classwork_quiz_result_form.student_id = this.studentId;
        this.classwork_quiz_result_form.final_checked_by =
          this.detail.questions[a].created_by;
        this.classwork_quiz_result_form.is_correct = 0;
        this.classwork_quiz_result_form.score = 0;
        this.classwork_quiz_result_form.question_type_id =
          this.detail.questions[a].question_type_id;
        if (
          this.detail.questions[a].question_type_id == 3 ||
          this.detail.questions[a].question_type_id == 4 ||
          this.detail.questions[a].question_type_id == 5
        ) {
          this.classwork_quiz_result_form.score = parseInt(
            this.detail.question_list_display[a].mark
          );
          // this.classwork_quiz_result_form.is_correct = 1
        }
        if (this.detail.questions[a].question_type_id == 5) {
          this.classwork_quiz_result_form.answer = [];
        }
        let clone = { ...this.classwork_quiz_result_form };
        this.classwork_quiz_result_collection.push(clone);
        if (a == this.detail.questions.length - 1) {
          this.set_answer_form_success = true;
          //("stop==========");
        }
      }

      // set identity form for multiple choice
      for (let b = 0; b < this.detail.questions.length; b++) {
        if (this.detail.questions[b].question_type_id == 5) {
          for (
            let c = 0;
            c < this.detail.questions[b].answer_choices_display.length;
            c++
          ) {
            this.detail.questions[b].answer_choices_display[c].identity_form =
              c +
              "," +
              b +
              this.detail.questions[b].answer_choices_display[c].text +
              this.detail.questions[b].answer_choices_display[c].value;
          }
        }
        // mengatur wadah untuk jawaban completion
        this.answer_collection_completion_form.identity_form = b;
        let clone_completion = { ...this.answer_collection_completion_form };
        this.answer_collection_form.push(clone_completion);

        let arr_answer_collection_completion = [];
        this.answer_collection_completion.push(
          arr_answer_collection_completion
        );

        // mengatur wadah untuk jawaban multiple choice
        let arr_multiple_choice_answer = [];
        this.multiple_choice_answer.push(arr_multiple_choice_answer);
        this.set_answer_form_success2 = true;
      }
      //("answer completion form", this.answer_collection_form);
      //("multiple choices answer", this.multiple_choice_answer);
      //("answer collection", this.answer_collection_completion);

      //("detail", this.detail);

      // mengambil jawaban dari database
      if (
        this.set_answer_form_success === true &&
        this.set_answer_form_success2 === true &&
        this.available_answer.length != 0
      ) {
        this.alreadyInput();
        // set unique value completion
        // this.answer_value = this.answer_collection_completion[d].length
        //("answer completion form", this.answer_collection_form);
        //("multiple choices answer", this.multiple_choice_answer);
      }

      // this.answer_value = this.answer_collection_completion.length

      this.questionLoaded = true;
    },
    // jawaban yang telah di input
    alreadyInput() {
      for (let d = 0; d < this.classwork_quiz_result_collection.length; d++) {
        if (
          this.classwork_quiz_result_collection[d].question_type_id == 1 ||
          this.classwork_quiz_result_collection[d].question_type_id == 2
        ) {
          this.classwork_quiz_result_collection[d].answer = this.available_answer[d].answer;
          this.classwork_quiz_result_collection[d].is_correct = this.available_answer[d].is_correct;
          this.classwork_quiz_result_collection[d].score = this.available_answer[d].score;
          if (this.classwork_quiz_result_collection[d].is_correct == 1) {
            this.classwork_quiz_result_collection[d].is_active = true;
          }else if(this.classwork_quiz_result_collection[d].is_correct == 0 &&
                  this.statusQuiz == 2)
          {
            this.classwork_quiz_result_collection[d].is_active = true;
          }
        } else if (
          this.classwork_quiz_result_collection[d].question_type_id == 3
        ) {
          this.classwork_quiz_result_collection[d].answer = this.available_answer[d].answer;
          this.classwork_quiz_result_collection[d].is_correct = this.available_answer[d].is_correct;
          if (this.classwork_quiz_result_collection[d].is_correct == 1) {
            this.classwork_quiz_result_collection[d].is_active = true;
          }
          else if(this.classwork_quiz_result_collection[d].is_correct == 0)
          {
            this.classwork_quiz_result_collection[d].is_active = true;
            this.classwork_quiz_result_collection[d].score = 0;
          }
        } else if (
          this.classwork_quiz_result_collection[d].question_type_id == 4
        ) {
          this.classwork_quiz_result_collection[d].answer = parseInt(this.available_answer[d].answer);
          this.classwork_quiz_result_collection[d].is_correct = this.available_answer[d].is_correct;
          if (this.classwork_quiz_result_collection[d].is_correct == 1) {
            this.classwork_quiz_result_collection[d].is_active = true;
          }
          else if(this.classwork_quiz_result_collection[d].is_correct == 0 )
          {
            this.classwork_quiz_result_collection[d].is_active = true;
            this.classwork_quiz_result_collection[d].score = 0;
          }
        } else if (
          this.classwork_quiz_result_collection[d].question_type_id == 5
        ) {
          let multiple_choices_answer = JSON.parse( this.available_answer[d].answer);
          for (let e = 0; e < multiple_choices_answer.length; e++) {
            this.chooseAnswerMultiple(multiple_choices_answer[e], d);
          }
          this.classwork_quiz_result_collection[d].is_correct = this.available_answer[d].is_correct;
          if (this.classwork_quiz_result_collection[d].is_correct == 1) {
            this.classwork_quiz_result_collection[d].is_active = true;
          }
          else if(this.classwork_quiz_result_collection[d].is_correct == 0)
          {
            this.classwork_quiz_result_collection[d].is_active = true;
            this.classwork_quiz_result_collection[d].score = 0;
          }
        } else if (
          this.classwork_quiz_result_collection[d].question_type_id == 6
        ) {
          // this.classwork_quiz_result_collection[d].answer = JSON.parse(this.available_answer[d].answer)
          let answer_completion = JSON.parse(this.available_answer[d].answer);
          //   //("answer_completion yang ini =============", answer_completion)
          for (let f = 0; f < answer_completion.length; f++) {
            this.answer_collection_completion_form.text = answer_completion[f].text;
            this.setAnswerCompletionChoice(d, d);
          }
          this.answer_collection_completion[d] = JSON.parse( this.available_answer[d].answer);
          this.classwork_quiz_result_collection[d].is_correct = this.available_answer[d].is_correct;
          this.classwork_quiz_result_collection[d].score = this.available_answer[d].score;
          if (this.classwork_quiz_result_collection[d].is_correct == 1) {
            this.classwork_quiz_result_collection[d].is_active = true;
          }
          else if(this.classwork_quiz_result_collection[d].is_correct == 0 &&
                  this.classwork_quiz_result_collection[d].score != 0)
          {
            this.classwork_quiz_result_collection[d].is_active = true;
          }
        }
      }
      this.start_quiz = true;
    },
    // set answer for question type multiple choice
    chooseAnswerMultiple(id, index) {
      let question = this.detail.questions[index];
      for (let a = 0; a < question.answer_choices_display.length; a++) {
        if (
          question.answer_choices_display[a].value == id &&
          question.answer_choices_display[a].checked == false
        ) {
          this.multiple_choice_answer[index].push(
            question.answer_choices_display[a].value
          );
          question.answer_choices_display[a].checked = true;
        } else if (
          question.answer_choices_display[a].value == id &&
          question.answer_choices_display[a].checked == true
        ) {
          for (let b = 0; b < this.multiple_choice_answer[index].length; b++) {
            if (this.multiple_choice_answer[index][b] == id) {
              this.multiple_choice_answer[index].splice(b, 1);
            }
          }
          question.answer_choices_display[a].checked = false;
        }
      }
      //("multiple choices answer", this.multiple_choice_answer);
    },
    // set answer for question type completion
    setAnswerCompletionChoice(identity_form, index) {
      for (let a = 0; a < this.answer_collection_form.length; a++) {
        if (this.answer_collection_form[a].identity_form == identity_form) {
          this.answer_value = Math.floor(Math.random() * 100) + 1;
          this.answer_collection_form[a].value = this.answer_value;
          let clone = { ...this.answer_collection_form[a] };
          this.answer_collection_completion[index].push(clone);
          this.answer_collection_form[a].text = "";
        }
      }
      for (let b = 0; b < this.answer_collection_completion.length; b++) {
        for (let c = 0; c < this.answer_collection_completion[b].length; c++) {
          this.answer_collection_completion[b][c].value = c;
        }
      }
      //("answer completion", this.answer_collection_completion);
    },
    removeAnswerChoice(id) {
      for (let b = 0; b < this.answer_collection_completion.length; b++) {
        for (let c = 0; c < this.answer_collection_completion[b].length; c++) {
          if (this.answer_collection_completion[b][c].value == id) {
            //("true", this.answer_collection_completion[b][c]);
            // this.answer_collection_completion[b].splice(c, 1)
            this.answer_collection_completion[b].splice(c, 1);
            this.answer_collection_completion[b].splice(
              this.answer_collection_completion.length
            );
            this.classwork_quiz_result_collection.splice(
              this.classwork_quiz_result_collection.length
            );
            // vm.items.splice(newLength)
            // vm.items.splice(indexOfItem, 1, newValue)
            // this.answer_collection_completion = []
          }
        }
      }
    },
    async getClassworkQuiz(classworkId, quizId) {
      let response = await module.paginate(
        `api/classrooms/${this.classroom_id}/classworks/${classworkId}/classwork-quiz-results/${quizId}/${this.studentId}`
      );
      //("classwork quiz =========================", response);
      this.put.id = response.data;
      //("put", this.put);
    },
    async formOnsubmitUpdate() {
      for (let a = 0; a < this.classwork_quiz_result_collection.length; a++) {
        this.classwork_quiz_result_collection[a].is_correct = parseInt(
          this.classwork_quiz_result_collection[a].is_correct
        );
        this.classwork_quiz_result_collection[a].score = parseInt(
          this.classwork_quiz_result_collection[a].score
        );
        // single choice
        if (this.classwork_quiz_result_collection[a].question_type_id == 4) {
          this.classwork_quiz_result_collection[a].answer =
            this.classwork_quiz_result_collection[a].answer.toString();
        }
        // multiple choice
        if (this.classwork_quiz_result_collection[a].question_type_id == 5) {
          this.classwork_quiz_result_collection[a].answer = JSON.stringify(
            this.multiple_choice_answer[a]
          );
        }
        // completion
        if (this.classwork_quiz_result_collection[a].question_type_id == 6) {
          this.classwork_quiz_result_collection[a].answer = JSON.stringify(
            this.answer_collection_completion[a]
          );
        }
      }
      this.put.is_finish = 2;
      // //("jawaban", this.classwork_quiz_result_collection);
      this.put.answer = this.classwork_quiz_result_collection;
      //("put", this.put);
      // Make Request
      // classrooms/{classroomId}/classworks/{classworkId}/classwork-quiz-results
      let response = await module.submitUpdate(
        this.put,
        `api/classrooms/${this.classroom_id}/classworks/${this.detail.classwork_id}/classwork-quiz-results/correction`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.handleContent();
        this.$root.$emit("refreshClassworkQuizScore");
        // this.getClassworkQuiz(response.success.item.original.classwork_id, response.success.item.original.classwork_id)
        // //("Response di sini", response)
      }
    },
  },
  mounted() {
    this.setAnswerForm();
    this.getClassworkQuiz(this.detail.classwork_id, this.detail.quiz_id);
  },
  watch: {
    detail: function (newVal, oldVal) {
      // watch it
      //("detail classwork changed: ", newVal, " | was: ", oldVal);
      this.setAnswerForm();
      this.getClassworkQuiz(this.detail.classworkId, this.detail.quiz_id);
    },
    available_answer: function (newVal, oldVal) {
      // watch it
      //("available answer changed: ", newVal, " | was: ", oldVal);
      // this.alreadyInput();
    },
    studentId: function (newVal, oldVal) {
      // watch it
      //("student Id changed: ", newVal, " | was: ", oldVal);
      // this.alreadyInput();
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 60px !important;
  height: 100px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper:hover {
  opacity: 0.8;
}

.card-attach-image:hover {
  opacity: 0.8;
  color: #1bc5bd !important;
}

.text-dark:hover {
  color: #1bc5bd !important;
}
</style>