<template>
  <div class="animate__animated animate__fadeIn">
    <!-- Filter -->
    <div class="row justify-content-end mt-3">
      <div class="col-md-3">
        <treeselect
          v-model="filter.topic_id"
          :multiple="false"
          placeholder="Pilih Topik"
          :options="topics"
          @input="filterByTopic"
        />
      </div>
      <div class="col-md-4">
        <b-input-group>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Saring Berdasar Judul"
            @keyup="filterByName"
          ></b-form-input>
          <template #append>
            <b-input-group-text squared class="bg-danger text-white rounded-0" style="cursor: pointer">
              <!-- <b-icon-search></b-icon-search> -->
              <span @click="filterReset">Reset</span>
            </b-input-group-text>
          </template>
        </b-input-group>
      </div>
      <div class="col-md-1 mr-8" v-if="btnAccess && purpose == 'master'">
        <b-button squared variant="primary" @click="handleContentAdd" v-if="btnAccess"
          >Tambah</b-button
        >
      </div>
    </div>
    <!-- table -->
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      class="mt-3"
      responsive
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width: field.key === 'action' ? '25%' : '',
          }"
        />
      </template>

      <template #cell(action)="data">
        <template v-if="purpose == 'master'">
        <b-button
          size="sm"
          class="mr-1 btn-info"
          v-b-tooltip.hover
          title="Detail"
          @click="handleContentDetail(data.item.id)"
          ><i class="fas fa-eye px-0"></i
        ></b-button>
        <b-button
          size="sm"
          class="mr-1 btn-success"
          v-b-tooltip.hover
          title="Edit"
          @click="handleContentUpdate(data.item.id)"
          v-if="btnAccess"
          ><i class="fas fa-edit px-0"></i
        ></b-button>
        <b-button
          size="sm"
          class="btn-danger"
          v-b-tooltip.hover
          title="Hapus"
          @click="materialDelete(data.item.id)"
          v-if="btnAccess"
          ><i class="fas fa-trash px-0"></i
        ></b-button>
        </template>
        <template v-if="purpose == 'modal-material'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="
              chooseMaterial({
                id: data.item.id,
                title: data.item.title
              })
            "
            >Pilih</b-button
          >
        </template>
      </template>
    </b-table>

    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import ApiService from "@/core/services/api.service.js";
export default {
  props:{
    purpose: String,
    userData: Object,
    course: Object
  },
  data() {
    return {
      items: [],
      perPage: 20,
      totalRows: 1,
      currentPage: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        { key: "title", label: "Judul", sortable: true },
        {
          key: "topic_name",
          label: "Topik",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap"
        },
      ],
      // filter
      filter: {
        name: '',
        topic_id: ''
      },
      topics: [],
      // access
      btnAccess: false
    };
  },
  methods: {
    chooseMaterial(value){
        this.$emit("chooseMaterial", value)
    },
    handleContentUpdate(id) {
      this.$emit("handleContentUpdate", id);
    },
    handleContentDetail(id) {
      this.$emit("handleContentDetail", id);
    },
    handleContentAdd() {
      this.$emit("handleContentAdd");
    },
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.getMaterial();
    },
    async getMaterial() {
      let filterParams = `&name=${this.filter.name}&topic_id=${this.filter.topic_id}`;
      let response = await module.paginate(
        `api/curriculums/materials/${this.course.course_id}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;

    // let response = await module.getMaterialCurriculum(`api/curriculums/materials/${this.course.course_id}`)
    // this.items = response.data.curriculum.materials

    //("material curric", response)
    },

    async getTopicOption() {
      ApiService.get(`api/topics`)
        .then((response) => {
          this.topics = response.data.data;
          for(let a = 0; a < this.topics.length; a++){
            for(let b = 0; b < this.topics[a].children.length; b++){
              delete this.topics[a].children[b].children
            }
          }
          this.topics.unshift({
            label: "Pilih Topik",
            id: "",
            isDisabled: true,
          });
          //("topics ===================================", this.topics);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },

    filterByName() {
      this.getMaterial();
    },
    async filterByTopic(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.topic_id = await "";
        this.getMaterial();
      } else {
        this.filter.topic_id = await evt;
        this.getMaterial();
        //("ok", this.filter.topic_id);
      }
    },

    filterReset() {
      this.filter.topic_id = ""
      this.filter.name = ""
      this.getMaterial()    
    },

    async materialDelete(id) {
      // Delete Data
      let result = await module.delete("api/materials/" + id);
      // If Deleted
      if (result) {
        this.getMaterials();
      }
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "7009") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    this.getMaterial();
    this.checkAccess();
    this.getTopicOption();
    this.$root.$on("refreshTableCurriculumMaterial", () => {
      // reset form on new (tambah data)
      this.getMaterial();
    });
  },
  watch: {
    course: function (newVal, oldVal) {
      //("course changed: ", newVal, " | was: ", oldVal);
      this.getMaterial()
    },
  },
};
</script>

<style>
</style>